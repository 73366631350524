import { Title } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { BoxContainer } from 'styles/global-style'

import CadastrarCondominios from './CadastrarCondominio/Container'
import ListarCondominios from './ListarCondominios/Container'

const AllCondominios = () => {
  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="lg">
        <Title color="neutral800" size="display03" className="mb-sm">
          Condomínios
        </Title>
        <CadastrarCondominios />
        <ListarCondominios />
      </BoxContainer>
    </>
  )
}

export default AllCondominios
