import React, { useState } from 'react'
import { useMutation } from 'react-query'

import { Box, Title, Button, Modal, showToast } from '@superlogica/design-system'
import {
  SendEmail2,
  Check1
} from '@superlogica/design-system/Icons/superlogica'
import { getStatus } from 'services/contratos/contrato/enviarParceiro'
import { enviarParceiro} from 'services/contratos/contrato/enviarParceiro'


const View = ({ data, idContrato }) => {
  const ContratoMutation = useMutation(getStatus)

    const [response, setResponse] = useState('')
    const [status, setStatus] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const openModalEditar = () => setIsOpen(true)
    const closeModalEditar = () => setIsOpen(false)

  async function enviar() {
    try {
      await enviarParceiro(idContrato)
      showToast({
        type: 'success',
        message: 'Contrato enviado com sucesso'
      })
      setTimeout(function(){
        window.location.reload();
   }, 2000); 
    } catch (error) {
      showToast({
        type: 'error',
        message: 'Erro ao enviar'
      })
    }
  }

   function getStatusCont() {
    ContratoMutation.mutate(idContrato, {
      onSuccess: (data) => {
        setResponse(data.message)
        setStatus(data.status)
        setIsOpen(true)
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        })
        setTimeout(function(){
          window.location.reload();
     }, 2000); 
      }
    })
  }

  return (
    
    <>
      {data.id_contratoStatus?.nome === "Enviar à cooperativa" ? 
      (
        <Button className={`mr-xxxs`} 
          onClick={enviar} >
          <SendEmail2 className="mr-xxxxs " width="12" height="12" fill="neutral600" />
          Enviar para parceiro
        </Button>
      ) : (<>         </>) }
      {data.id_cooperativa?.nome === "Bow-e Consorcio" && data.id_contratoStatus?.nome === "Encaminhado a Cooperativa"   ? 
      (
        <Button className={`mr-xxxs`} 
          onClick={getStatusCont} >
          <Check1 className="mr-xxxxs " width="12" height="12" fill="neutral600" />
          Verificar status de envio
        </Button>
      ) : (<>         </>) }

      <Modal
        isOpen={isOpen}
        onRequestClose={openModalEditar}
        width={600}
        label="Testando modal"
      > 
        <Box
          paddingType="square"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ borderBottom: 'none' }}
        >
        <Title size="body01" weight="bold" color="neutral800">
         {status}
        </Title>
          </Box>
            <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              {response}
            </Box>
            <Box
              paddingType="squish"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ display: 'flex', borderTop: 'none' }}
            >
              <Button className="ml-auto"
               onClick={() => {
                closeModalEditar()
              }}>
                Fechar
              </Button>
            </Box>
        </Modal>

      </>
      )
  }
export default View