import { api } from 'services/api'

export const getContratosExportar = async (status, dataInicio, dataFinal, cooperativa, distribuidora, administradora) => {
  let url = 'contratos/listarContratosExport'

  if (typeof status === 'string' && status !== ''  &&  dataInicio !== '' &&   dataFinal !== '' &&   cooperativa !== '' &&   distribuidora !== ''  &&   administradora !== '' ) {
   const  startDate = dataInicio.toLocaleDateString("fr-CA")
   const finDate = dataFinal.toLocaleDateString("fr-CA")
     url = `contratos/listarContratosExport?id_contratoStatus=${status}&data_inicio=${startDate}&data_fim=${finDate}&cooperativa=${cooperativa}&distribuidora=${distribuidora}&id_administradora=${administradora}`
  }

  const response = await api.get(url)
  return response.data.data
}
