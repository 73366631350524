import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Text } from '@superlogica/design-system'
import { ArrowRight1,   BusinessContractHandshakeSign, } from '@superlogica/design-system/Icons/superlogica'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { format } from 'date-fns'
import { BoxClienteIcon } from 'styles/global-style'

// import GerarRebate from '../GerarRebate/View'

const View = ({data}) => {
  let dateCriacao = new Date(data?.rebateData?.data.criadoEm)
  let dataFormatada = format(dateCriacao, 'dd/MM/yyyy')

  let dateAtualizacao = new Date(data?.rebateData?.data.atualizadoEm)
  let dataFormatada2 = format(dateAtualizacao, 'dd/MM/yyyy')

  let value = data?.rebateData?.data.valor

  let valorFormatado = value
  .toLocaleString('pt-br', { style: 'currency', currency: 'R$' })
  .replace('.', ',')
  return (
      <>
      <Box
        border
        borderColor="neutral100"
        bgColor="neutral0"
        paddingSize="lg"
        paddingType="squish"
        radius="xs"
      >
        <Box paddingSize="none" className="flex align-center mb-xs">
          <Text color="primary" className="mr-xxxxs" size="label02">
            <Link to={`../all-rebates`}>Rebate</Link>
          </Text>
          <ArrowRight1 className="mr-xxxxs" width="10" height="10" fill="neutral600" />
          <Text size="label02">{data?.rebateData?.data.id}</Text>
        </Box>

        <Box paddingSize="none" className="flex">
          <BoxClienteIcon
            paddingSize="none"
            bgColor="neutral50"
            radius="md"
            paddingType="square"
            className="flex align-center mr-xs"
          >
            <BusinessContractHandshakeSign
              width="23"
              height="23"
              fill="neutral600"
              className="mr-auto ml-auto"
            />
          </BoxClienteIcon>
          <Box paddingSize="none">
            <Box paddingSize="none" className="flex align-center mt-xxxs mb-xs">
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Administradora
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.rebateData?.data.administradora.razao_social}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Valor
                </Text>
                <Text color="neutral600" size="body02">
                  {valorFormatado}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Quantidade de faturas
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.rebateData?.data.quantidadeFaturas}
                </Text>
              </Box>
            </Box>
            <SeparationLine />
            <Box paddingSize="none" className="flex align-center mt-xxxs mb-xs">
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Data de criação
                </Text>
                <Text color="neutral600" size="body02">
                  {dataFormatada}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Status 
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.rebateData?.data.status}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Atualizado em
                </Text>
                <Text color="neutral600" size="body02">
                {dataFormatada2}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
        </Box>
      </Box>
      </>
  )
}

export default View
