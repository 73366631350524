import { Chip } from '@superlogica/design-system'
import { AlarmBell } from '@superlogica/design-system/Icons/superlogica'

function pickColor(statusName) {
  let color = 'neutral800'

  if (statusName.match(/gerad(o|a)/gi)) {
    color = 'neutral200'
  }

  if (statusName.match(/ativ(o|a)/gi)) {
    color = 'success02'
  }

  if (statusName.match(/cancelad(o|a)/gi)) {
    color = 'danger03'
  }

  if (statusName.match(/pendente/gi)) {
    color = 'warning02'
  }

  if (statusName.match(/termo/gi)) {
    color = 'success04'
  }

  return color
}

function StatusContrato({ contrato }) {
  return (
    <Chip
      label={contrato.id_contratoStatus.nome}
      color={pickColor(contrato.id_contratoStatus.nome)}
      leftIcon={<AlarmBell />}
    />
  )
}

export default StatusContrato
