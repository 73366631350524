import React, { useState } from 'react'
import { useMutation} from 'react-query'
import { useQueryClient } from 'react-query'

import { Button, Box, Title, showToast } from '@superlogica/design-system'
import { SendEmail2 } from '@superlogica/design-system/Icons/superlogica'
import Modal from '@superlogica/design-system/Modal'
import { gerar } from 'services/rebate/rebate'

const View = ({ admData }) => {
  const [isOpenGerar, setIsOpenGerar] = useState(false)
  // const [isOpen2, setIsOpen2] = useState(false)

  function openModalGerar() {
    setIsOpenGerar(true)
  }

  function closeModalGerar() {
    setIsOpenGerar(false)
  }
  const rebateAdmMutation = useMutation(gerar)
  const queryClient = useQueryClient()

  function refreshRebate() {
    queryClient.invalidateQueries(['rebates'])
  }

  function gerarRebate(id) {
    rebateAdmMutation.mutate(id, {
      onSuccess: () => {
        closeModalGerar()
        refreshRebate()
        // setIsOpen2(true)
        showToast({
          type: 'success',
          message: 'Rebate gerado com sucesso'
        })
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        })
      }
    })
  }

  return (
    <>
      <Button
        onClick={openModalGerar}
        appearance="solid"
        size="sm"
        className="mr-xxs"
        leftIcon={ <SendEmail2 />}
      >
        Gerar Rebate
      </Button>

      <Modal
        isOpen={isOpenGerar}
        height="80vh"
        onRequestClose={openModalGerar}
        width="20vw"
        label="Testando modal"
      >
        <Box
          paddingType="square"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ borderBottom: 'none' }}
        >
          <Title size="display03" weight="bold" color="neutral800" align="center">
            Tem certeza que deseja gerar o rebate dessa Agência?
          </Title>
        </Box>
        <Box className="flex align-center">
          <Button
            appearance="solid"
            color="primary"
            size="md"
            className="mr-xxs"
            onClick={() => {
              gerarRebate(admData.id)
            }}
          >                 
            Gerar
          </Button>
          <Button appearance="outline" color="primary" size="md" onClick={closeModalGerar}>
            Cancelar
          </Button>
        </Box>
        <Box
          paddingType="squish"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ display: 'flex', borderTop: 'none' }}
        ></Box>
      </Modal>


      {/* <Modal
        isOpen={isOpen2}
        height="80vh"
        width="20vw"
        label="Testando modal"
      >
        <Box
          paddingType="square"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ borderBottom: 'none' }}
        >
          <Title size="display03" weight="bold" color="neutral800" align="center">
            Tem certeza que deseja gerar o rebate dessa Agência?
          </Title>
        </Box>
        <Box className="flex align-center">
          <Button appearance="outline" color="primary" size="md" onClick={closeModalGerar}>
            Fechar
          </Button>
        </Box>
        <Box
          paddingType="squish"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ display: 'flex', borderTop: 'none' }}
        ></Box>
      </Modal> */}

    </>
  )
}

export default View
