import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Text, Title } from '@superlogica/design-system'
import {
  ArrowRight1,
  BusinessContractHandshakeSign
} from '@superlogica/design-system/Icons/superlogica'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { BoxClienteIcon } from 'styles/global-style'

import DadosCobranca from '../DadosCobranca/Container'
import DadosUnidade from '../DadosUnidade/Container'
import EditarStatusContratos from '../EditarStatusContrato/Container'
import EnviarParceiro from '../EnviarParceiro/Container'

const View = ({ data, uuId, status }) => {

  return (
    <>
      <Box
        border
        borderColor="neutral100"
        bgColor="neutral0"
        paddingSize="lg"
        paddingType="squish"
        radius="xs"
      >
        <Box paddingSize="none" className="flex align-center mb-xs">
          <Text color="primary" className="mr-xxxxs" size="label02">
            <Link to={`../all-contratos`}>Contrato</Link>
          </Text>
          <ArrowRight1 className="mr-xxxxs" width="10" height="10" fill="neutral600" />
          <Text size="label02">{data.hash_unidade}</Text>
          <EditarStatusContratos data={data} uuId={uuId} status={status} />
        </Box>

        <Box paddingSize="none" className="flex">
          <BoxClienteIcon
            paddingSize="none"
            bgColor="neutral50"
            radius="md"
            paddingType="square"
            className="flex align-center mr-xs"
          >
            <BusinessContractHandshakeSign
              width="23"
              height="23"
              fill="neutral600"
              className="mr-auto ml-auto"
            />
          </BoxClienteIcon>
          <Box paddingSize="none">
            <Title as="h3" size="display03">
              {data.id_cliente?.nome_completo}
            </Title>
            <Box paddingSize="none" className="flex align-center mt-xxxs mb-xs">
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  CPF
                </Text>
                <Text color="neutral600" size="body02">
                  {data.id_cliente?.cpf}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Telefone
                </Text>
                <Text color="neutral600" size="body02">
                  {data.id_cliente?.telefone}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Código
                </Text>
                <Text color="neutral600" size="body02">
                  {data.codigo_do_consumidor}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Condominio
                </Text>
                <Text color="neutral600" size="body02">
                  {data.id_condominio?.nome}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Licença
                </Text>
                <Text color="neutral600" size="body02">
                  {data.id_condominio.id_administradora?.licenca}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  E-mail
                </Text>
                <Text color="neutral600" size="body02">
                  {data.id_cliente?.email}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Rg
                </Text>
                <Text color="neutral600" size="body02">
                  {data.id_cliente?.rg}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Orgão emissor
                </Text>
                <Text color="neutral600" size="body02">
                  {data.id_cliente?.orgaoEmissor}
                </Text>
              </Box>
            </Box>
            <SeparationLine />
            <DadosCobranca textCobranca={data} />
            <SeparationLine />
            <DadosUnidade textUnidade={data} />
            <EnviarParceiro  data={data} uuId={uuId} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default View
