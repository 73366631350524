import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { getSingleRebate } from 'services/rebate/rebate'
import { BoxContainer } from 'styles/global-style'

import DadosRebate from './DadosRebate/Container'
import Faturas from './FaturasRebate/Container'

const SingleRebate = () => {

  const {id} = useParams()
  const {
    data: rebate,
    isLoading,
    isIdle
  } = useQuery(['rebateSingle', id], () => getSingleRebate(id))

  
  const faturaLoading = isLoading || isIdle
  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="lg">
          {faturaLoading ? 'Carregando rebate...' :  <DadosRebate rebateData={rebate} />}
        </BoxContainer>

        <BoxContainer paddingSize="lg">
        <Box paddingSize="none" className="mb-md">
        {faturaLoading ? 'Carregando rebate...' :   <Faturas faturasRebate={rebate.data.faturas} />}
        </Box>
      </BoxContainer>

      
    </>
  )
}

export default SingleRebate
