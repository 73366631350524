import React from 'react'

import { Box, Table, Text } from '@superlogica/design-system'
import { BoxTabTable, BoxTitleTable } from 'styles/global-style'

const View = ({ faturasCliente, columns, isError, isLoading, refetch }) => {
  return (
    <>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xxs"
        radius="md"
        className="flex align-center"
      >
        <BoxTitleTable paddingSize="none">
          <Text className="mr-xxxs">Faturas</Text>
          <Text color="neutral400" size="body02">
            {`${faturasCliente.length} itens`}
          </Text>
        </BoxTitleTable>
      </BoxTabTable>
      <Box border borderColor="neutral300" bgColor="neutral0" paddingSize="none" radius="xs">
        <Table
          data={faturasCliente}
          columns={columns}
          tableName="faturas"
          isError={isError}
          isLoading={isLoading}
          refetch={refetch}
        />
      </Box>
    </>
  )
}

export default View
