import { api } from 'services/api'

export const getSingleClientes = async (idCliente) => {
  if (idCliente) {
    let url = `clientes/${idCliente}`
    const response = await api.get(url)
    return response.data.data
  }
}

export const getSingleFaturasClientes = async (idCliente) => {
  if (idCliente) {
    let url = `contratos/listarContratosClientes/${idCliente}`
    const response = await api.get(url)

    let faturas = []

    if (response.data.data !== '') {
      response.data.data.forEach((value) => {
        if (value?.faturas.length > 0) {
          for (var i = 0; i < value?.faturas.length; i++) {
            faturas = faturas.concat(value?.faturas[i])
          }
        }
      })
      return faturas
    }
  }
}
