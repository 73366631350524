import { Text, Box } from '@superlogica/design-system'

const View = ({ textCobranca }) => {
  function adicionaZero(numero) {
    if (numero <= 9) return '0' + numero
    else return numero
  }

  function formataData(dataTratar) {
    let data = new Date()
    let dataTratada =
      adicionaZero(data.getDate(dataTratar).toString()) +
      '/' +
      adicionaZero(data.getMonth(dataTratar) + 1).toString() +
      '/' +
      data.getFullYear(dataTratar)
    return dataTratada
  }

  let dataFormatada = formataData(textCobranca?.data_assinatura_contrato)

  return (
    <>
      <Box paddingSize="none" className="flex align-center mt-xs mb-xs">
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Assinado em
          </Text>
          <Text color="neutral600" size="body02">
            {dataFormatada}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Cód. Consumidor
          </Text>
          <Text color="neutral600" size="body02">
            {textCobranca?.codigo_do_consumidor}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Distribuidora
          </Text>
          <Text color="neutral600" size="body02">
            {textCobranca?.id_distribuidora?.nome}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Cooperativa
          </Text>
          <Text color="neutral600" size="body02">
            {textCobranca?.id_cooperativa?.nome}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Valor da ultima conta
          </Text>
          <Text color="neutral600" size="body02">
            {textCobranca?.valorUltimaConta}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Consumo médio em KWH
          </Text>
          <Text color="neutral600" size="body02">
            {textCobranca?.consumoMedio}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Tipo Contrato
          </Text>
          <Text color="neutral600" size="body02">
            {textCobranca?.tipoContrato}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Numero de instalação
          </Text>
          <Text color="neutral600" size="body02">
            {textCobranca?.numeroInstalacao}
          </Text>
        </Box>
      </Box>
    </>
  )
}

export default View
