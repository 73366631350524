import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Box, Button, Text, Title, Modal } from '@superlogica/design-system'
import { ArrowRight1, Bin1, SingleNeutral } from '@superlogica/design-system/Icons/superlogica'
import { format } from 'date-fns'
import DadosCadastrais from 'pages/Clientes/Single/Editar/DadosCadastrais/Container'
import ExcluirCliente from 'pages/Clientes/Single/Excluir/Container'
import { BoxClienteIcon } from 'styles/global-style'

const View = ({ textData }) => {
  const [isOpen, setIsOpen] = useState(false)
  
  const dataNascimentoFormat =
    textData.data_nascimento != null
      ? format(new Date(textData?.data_nascimento), 'dd/MM/yyyy')
      : ''

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const [isOpenExcluir, setIsOpenExcluir] = useState(false)

  function openModalExcluir() {
    setIsOpenExcluir(true)
  }

  function closeModalExcluir() {
    setIsOpenExcluir(false)
  }
  return (
    <>
      <Box
        border
        borderColor="neutral100"
        bgColor="neutral0"
        paddingSize="lg"
        paddingType="squish"
        radius="xs"
      >
        <Box paddingSize="none" className="flex align-center mb-xs">
          <Text color="primary" className="mr-xxxxs" size="label02">
            <Link to={`../all-clientes`}>Cliente</Link>
          </Text>
          <ArrowRight1 className="mr-xxxxs" width="10" height="10" fill="neutral600" />
          <Text size="label02">{textData.nome_completo}</Text>
          <Box className="flex align-center mb-xs ml-auto">
            <Button
              onClick={openModalExcluir}
              appearance="solid"
              size="sm"
              className="mr-xxs"
              leftIcon={<Bin1 name="bin-1" />}
            >
              Excluir cliente
            </Button>

            <Modal
              isOpen={isOpenExcluir}
              height="80vh"
              onRequestClose={openModalExcluir}
              width="50vw"
              label="Testando modal"
            >
              <ExcluirCliente textData={textData} closeModalExcluir={closeModalExcluir} />
            </Modal>
              {textData && Object.keys(textData).length > 0 && ( // Verifica se textData não está vazio
              <Button
                onClick={openModal}
                appearance="outline"
                size="sm"
                className="ml-auto"
              >
                Editar cliente
              </Button>
            )}
          </Box>
          <Modal
            isOpen={isOpen}
            height="80vh"
            onRequestClose={openModal}
            width="60vw"
            label="Testando modal"
          >
            <Box
              paddingType="square"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ borderBottom: 'none' }}
            >
              <Title size="body01" weight="bold" color="neutral800">
                Editar Cliente
              </Title>
            </Box>

            <DadosCadastrais textData={textData} closeModal={closeModal} />
          </Modal>
        </Box>

        <Box paddingSize="none" className="flex align-center">
          <BoxClienteIcon
            paddingSize="none"
            bgColor="neutral50"
            radius="md"
            paddingType="square"
            className="flex align-center mr-xs"
          >
            <SingleNeutral
              width="23"
              height="23"
              fill="neutral600"
              className="mr-auto ml-auto"
            />
          </BoxClienteIcon>
          <Box paddingSize="none">
            <Title as="h3" size="display03">
              {textData.nome_completo}
            </Title>

            <Box paddingSize="none" className="flex align-center mr-xs mt-xxxxs">
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                CPF
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {textData.cpf}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Código
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {textData.id}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                E-mail
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {textData.email}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Telefone
              </Text>
              <Text color="neutral600" size="body02">
                {textData.telefone}
              </Text>
            </Box>
            <Box paddingSize="none" className="flex align-center mr-xs mt-xxxxs">
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Data Nascimento
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {dataNascimentoFormat}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Nacionalidade
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {textData.nacionalidade}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Estado Cívil
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {textData.estado_civil}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Profissão
              </Text>
              <Text color="neutral600" size="body02"  className="mr-xs">
                {textData.profissao}
              </Text>
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Rg
              </Text>
              <Text color="neutral600" size="body02"  className="mr-xs">
                {textData.rg}
              </Text>
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Orgão emissor
              </Text>
              <Text color="neutral600" size="body02"  className="mr-xs">
                {textData.orgaoEmissor}
              </Text>
              {textData.cpf_titular != null && (
                <Box paddingSize="none" className="flex align-center mr-xs mt-xxxxs">
                  <Text color="neutral600" size="body02" className="ml-xs mr-xxxs" weight="bold">
                    Cpf titular
                  </Text>
                  <Text color="neutral600" size="body02">
                    {textData.cpf_titular}
                  </Text>
                  </Box>
                
                
               
                )}

            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default View
