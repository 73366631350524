import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'


import { Search } from '@superlogica/design-system/Icons/superlogica'
import { getRebates } from 'services/rebate/rebate'
//import { useDebouncedCallback } from 'use-debounce'

import View from './View.jsx'

const Container = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [sort, setSort] = useState({ direction: 'none', accessor: '' })

  const { data, isLoading, isError, refetch } = useQuery(
    ['rebateList',currentPage, sort],
    () => getRebates(currentPage, sort)
  )
  
  const rebates = data?.data ?? []
  const itemsPerPage = data?.itensPorPagina
  const totalItems = data?.totalItens
  const rebates2 = rebates

  function sortTable(column) {
    const sortDirections = {
      none: 'ASC',
      ASC: 'DESC',
      DESC: 'none'
    }

    setSort({ direction: sortDirections[column.sortDirection], accessor: column.id })
  }

  const columnsRebates = useMemo(
    () => [
      {
        Header: 'Valor', // texto q será exibido no cabeçalho da tabela
        accessor: 'valor', // id das células dessa coluna
        align: 'left',
        width: '20%' ,
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'valor' ? sort.direction : 'none'//alinha o texto dentro da célula da tabela
      },
      {
        Header: 'Quantidade faturas',
        accessor: 'quantidadeFaturas',
        align: 'left',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'quantidadeFaturas' ? sort.direction : 'none'
      },
      {
        Header: 'Status',
        accessor: 'status',
        align: 'left',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'status' ? sort.direction : 'none'
      },
      {
        Header: 'Administradora',
        accessor: 'administradora.razao_social',
        align: 'left',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'administradora' ? sort.direction : 'none'
      },
      {
        Header: 'Data lançamento',
        accessor: 'criadoEm',
        align: 'left',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'criadoEm' ? sort.direction : 'none'
      },
      {
        Header: '',
        accessor: 'icon',
        align: 'center',
        width: '5%',
        Cell: ({ row }) => {
          const rebateId = rebates2[row.index].id
          return (
            <Link to={`single-rebate/${rebateId}`}>
              <Search height="16" width="16" />
            </Link>
          )
        }
      }
    ],
    [rebates2, sort]
  )

  const tableRebates = useMemo(() => rebates2, [rebates2])
  return (
    <View
      title="Lista Rebates"
      isLoading={isLoading}
      isError={isError}
      tableRebates={tableRebates}
      columns={columnsRebates}
      refetch={refetch}
      totalItems={totalItems}
      itemsPerPage={itemsPerPage}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      manualSort={true}
    />
  )
}

export default Container
