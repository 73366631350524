import React from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { getResources } from 'services/contratos/contratos'

import View from './View'

const Container = ({ data, id, clientes }) => {
  const { data: distribuidoras = [] } = useQuery('distribuidoras', () =>
    getResources('distribuidoras')
  )

  const { data: condominios = [] } = useQuery('condominios', () => getResources('condominios'))

  const { data: cooperativas = [] } = useQuery('cooperativas', () =>
    getResources('cooperativas')
  )

  const queryClient = useQueryClient()

  function refreshContrato() {
    queryClient.invalidateQueries(['contrato'])
  }

  return (
    <View
      title="Display Novo Contrato"
      queryDistribuidoras={distribuidoras}
      queryCondominios={condominios}
      queryCooperativas={cooperativas}
      queryClientes={clientes}
      refreshContrato={refreshContrato}
    />
  )
}
export default Container
