import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import {
  Box,
  Title,
  Button,
  Modal,
  showToast,
  Select
} from '@superlogica/design-system'
import { SendEmail2 } from '@superlogica/design-system/Icons/superlogica'
import { getCooperativas, reenviarTermos } from 'services/contratos/contrato/atualizarStatus'
import { getSingleContratos } from 'services/contratos/contrato/getSingleContratos'

const View = ({ idContrato }) => {
  
  const { register, handleSubmit, reset } = useForm()
  const openModalEditar = () => setIsOpen(true)
  const closeModalEditar = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)
  const {
    data = []
  } = useQuery('contratos', () => getSingleContratos(idContrato))
  const { data: cooperativa = [] } = useQuery('cooperativas', () =>getCooperativas())
  const optionsCooperativas = populateOptions(cooperativa)
  console.log(data)
  function populateOptions(data2) {
    return data2.map((item2) => ({
      name: item2.nome,
      value: item2.name
    }))
  }

  const singleContratoMutation = useMutation(reenviarTermos)
  function editar(data) {
    singleContratoMutation.mutate(data, {
      onSuccess: () => {
        showToast({
          type: 'success',
          message: 'Campo atualizado com sucesso'
        })
        closeModalEditar()
        reset({})
      }
    })
  }

  return (
    <>
      <Button
            appearance="solid"
            className="mr-xxs"
            leftIcon={<SendEmail2 />}
            onClick={openModalEditar}>
            Reenviar Termo
          </Button>
      <Modal
          isOpen={isOpen}
          onRequestClose={openModalEditar}
          width={600}
          label="Testando modal"
        >
          <form onSubmit={handleSubmit(editar)}> 

            <input type="hidden" value={idContrato} {...register('id_contrato')} />
            <Box
              paddingType="square"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ borderBottom: 'none' }}
            >
              <Title size="body01" weight="bold" color="neutral800">
              Alterar cooperativa e reenviar termos
              </Title>
            </Box>
            <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
            <Box>
              <Select
                size="sm"
                label="Selecione uma Cooperativa"
                name="cooperativas"
                {...register('cooperativa')}
                options={optionsCooperativas}
              />
      </Box>
          </Box>
            <Box
              paddingType="squish"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ display: 'flex', borderTop: 'none' }}
            >
              <Button
                className="mr-auto"
                color="primary"
                appearance="ghost"
                onClick={closeModalEditar}
              >
                Cancelar
              </Button>
              <Button className="ml-auto">Salvar</Button>
            </Box>

          </form>
        </Modal>
  </>
  )
}

export default View
