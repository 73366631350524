import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Text, Title } from '@superlogica/design-system'
import { ArrowRight1, SingleNeutral } from '@superlogica/design-system/Icons/superlogica'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { BoxClienteIcon } from 'styles/global-style'

import EditarAdministradora from '../EditarAdministradoras/Container'
import ExcluirAdministradora from '../ExcluirAdministradora/Container'
import GerarRebate from '../GerarRebate/View'
import GerarToken from '../GerarToken/View'

const View = ({ admData }) => {
  return (
    <>
      <Box
        border
        borderColor="neutral100"
        bgColor="neutral0"
        paddingSize="lg"
        paddingType="squish"
        radius="xs"
      >
        <Box paddingSize="none" className="flex align-center mb-xs">
          <Text color="primary" className="mr-xxxxs" size="label02">
            <Link to={`../all-administradoras`}>Agência</Link>
          </Text>
          <ArrowRight1 className="mr-xxxxs" width="10" height="10" fill="neutral600" />
          <Text size="label02">{admData.razao_social}</Text>
          <Box className="flex align-center mb-xs ml-auto">
            <ExcluirAdministradora admData={admData} />
            <EditarAdministradora admData={admData} />
          </Box>
        </Box>

        <Box paddingSize="none" className="flex align-center">
          <BoxClienteIcon
            paddingSize="none"
            bgColor="neutral50"
            radius="md"
            paddingType="square"
            className="flex align-center mr-xs"
          >
            <SingleNeutral
              width="23"
              height="23"
              fill="neutral600"
              className="mr-auto ml-auto"
            />
          </BoxClienteIcon>
          <Box paddingSize="none">
            <Title as="h3" size="display03">
              {admData.razao_social}
            </Title>
            <Box paddingSize="none" className="flex align-center mt-xxxxs mb-xs">
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Licença
              </Text>
              <Text color="neutral600" size="body02" data={admData} className="mr-xs">
                {admData.licenca}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                CNPJ
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {admData.cnpj}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Licença
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {admData.licenca}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                E-mail
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {admData.email}
              </Text>
            </Box>
            <SeparationLine />
            <Box paddingSize="none" className="flex align-center mt-xxxxs mb-xs">
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Telefone
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {admData.telefone}
              </Text>
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                E-mail
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {admData.email}
              </Text>
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Qtd Condomínios
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {admData.qtd_condominios}
              </Text>
            </Box>         
            <GerarRebate admData={admData} />  
            {
              admData.access_token === '000' || admData.access_token === null
              ? <GerarToken admData={admData} />   : ""
            } 
           
          </Box>
        </Box>
      </Box>
      
    </>
  )
}

export default View
