import { api } from 'services/api'

export const getContratos = async (valorPesquisa, id_contratoStatus, id_administradora, id_coop, page = 1,sort) => {
  const url = 'contratos'
  const valor = isNaN(valorPesquisa)
  const params = {
    page
  }

  if (typeof valorPesquisa === 'string' && valorPesquisa !== '') {
    if(valor === false){
      params['cpf'] = valorPesquisa
    }else{
      params['nome_completo'] = valorPesquisa
    }
  }
  else if(typeof id_contratoStatus === 'string' && id_contratoStatus !== ''){
    params['id_contratoStatus'] = id_contratoStatus
  }
  else if (typeof id_administradora === 'string'  && id_administradora !== ''  ) {
    params['id_administradora'] = id_administradora
 }
 else if (typeof id_coop === 'string'  && id_coop !== ''  ) {
  params['id_cooperativa'] = id_coop
}

 if (sort?.direction !== 'none' && sort?.accessor !== '') {
  if(sort.accessor === 'id_cliente.nome_completo'){
    params['column'] = 'nome_completo'
  }else if(sort.accessor === 'id_cliente.cpf'){
    params['column'] = 'cpf'
  }else if(sort.accessor === 'id_condominio.nome'){
    params['column'] = 'nome'
  }
  else{
    params['column'] = sort.accessor
  }
  params['order'] = sort.direction
}


 const response = await api.get(url, { params })

  return response.data
}


export const getContratosPj = async (valorPesquisa, id_contratoStatus, id_administradora, page = 1,sort) => {
  const url = 'contratos/get/contratosPj'
  const valor = isNaN(valorPesquisa)
  const params = {
    page
  }

  if (typeof valorPesquisa === 'string' && valorPesquisa !== '') {
    if(valor === false){
      params['cpf'] = valorPesquisa
    }else{
      params['nome_completo'] = valorPesquisa
    }
  }
  else if(typeof id_contratoStatus === 'string' && id_contratoStatus !== ''){
    params['id_contratoStatus'] = id_contratoStatus
  }
  else if (typeof id_administradora === 'string'  && id_administradora !== ''  ) {
    params['id_administradora'] = id_administradora
 }


 if (sort?.direction !== 'none' && sort?.accessor !== '') {
  if(sort.accessor === 'id_cliente.nome_completo'){
    params['column'] = 'nome_completo'
  }else if(sort.accessor === 'id_cliente.cpf'){
    params['column'] = 'cpf'
  }else if(sort.accessor === 'id_condominio.nome'){
    params['column'] = 'nome'
  }
  else{
    params['column'] = sort.accessor
  }
  params['order'] = sort.direction
}


 const response = await api.get(url, { params })

  return response.data
}

