import React from 'react'

import { Box, Text, Table, Input } from '@superlogica/design-system'
import { Search } from '@superlogica/design-system/Icons/superlogica'
import Pagination from 'components/Pagination/Pagination'
import { BoxTitleTable, BoxTabTable } from 'styles/global-style'



const View = ({ isLoading, isError, tableCondominios, columns, refetch ,totalItems, itemsPerPage,setCurrentPage,currentPage, setSearchContratoValue,manualSort}) => {

  return (
    <>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xs"
        radius="md"
        className="flex align-center"
      >
        <BoxTitleTable paddingSize="none">
          <Text className="mr-xxxs">Condomínios</Text>
          <Text color="neutral400" size="body02">
          {`Exibindo ${tableCondominios.length}`}
          </Text>
        </BoxTitleTable>
        <Box paddingSize="none" className="ml-auto flex align-center">
          <Input
            className="ml-xxxs"
            name="search"
            type="text"
            label="Buscar..."
            onChange={(e) => {
              setSearchContratoValue(e.target.value)
            }}
            rightIcon={<Search />}
          />
        </Box>
      </BoxTabTable>
      <Box border borderColor="neutral300" bgColor="neutral0" paddingSize="none" radius="xs">
        <Table
          loading={isLoading}
          error={isError}
          data={tableCondominios}
          columns={columns}
          tableName="condominios"
          manualSort={manualSort}
          retry={refetch}
        />
      </Box>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xxs"
        radius="md"
        className="flex align-center"
      >
        <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </BoxTabTable>
    </>
  )
}

export default View
