import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { Search } from '@superlogica/design-system/Icons/superlogica'
import { getCondominios } from 'services/condominios/condominios'
import { useDebouncedCallback } from 'use-debounce/lib'
//import { useDebouncedCallback } from 'use-debounce'

import View from './View.jsx'

const Container = () => {
  const [searchContrato, setSearchContrato] = useState()
  // const [searchCondQuery] = useState('condominios')
  const [currentPage, setCurrentPage] = useState(1)
  const [sort, setSort] = useState({ direction: 'none', accessor: '' })
  const {
    data = [],
    isLoading,
    isError,
    refetch
  } = useQuery(['condominiosLista', currentPage, searchContrato , sort], () => getCondominios(currentPage, searchContrato, sort))

  const condominios = data?.data ?? []
  const itemsPerPage = data?.itensPorPagina
  const totalItems = data?.totalItens
  const condominios2 = condominios


  const setSearchContratoValue = useDebouncedCallback((value) => {
    setSearchContrato(value)
  }, 300)



  function sortTable(column) {
    const sortDirections = {
      none: 'ASC',
      ASC: 'DESC',
      DESC: 'none'
    }

    setSort({ direction: sortDirections[column.sortDirection], accessor: column.id })
  }



  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsCondominios = useMemo(() => [
    {
      Header: 'Nome',
      accessor: 'nome',
      align: 'left',
      width: '20%',
      sortByOnClick: (column) => sortTable(column) ,
      sortDirection: sort.accessor === 'nome' ? sort.direction : 'none'
    },
    {
      Header: 'CNPJ',
      accessor: 'cnpj',
      align: 'left',
      sortByOnClick: (column) => sortTable(column) ,
      sortDirection: sort.accessor === 'cnpj' ? sort.direction : 'none'
    },
    {
      Header: 'Vencimento Boleto',
      accessor: 'vencimento_boleto',
      align: 'left',
      sortByOnClick: (column) => sortTable(column) ,
      sortDirection: sort.accessor === 'vencimento_boleto' ? sort.direction : 'none'
    },
    {
      Header: 'Administradora',
      accessor: 'id_administradora.licenca',
      align: 'left',
      sortByOnClick: (column) => sortTable(column) ,
      sortDirection: sort.accessor === 'id_administradora.licenca' ? sort.direction : 'none'
    },
    {
      Header: '',
      accessor: 'icon',
      align: 'center',
      width: '5%',
      Cell: ({ row }) => {
        const condoId = condominios2[row.index].id
        return (
          <Link to={`single-condominio/${condoId}`}>
            <Search height="16" width="16" />
          </Link>
        )
      }
    }
  ],
  [sort, condominios2]
  )
  

  const tableCondominios = useMemo(() => condominios2, [condominios2])

  return (
    <View
      title="Lista Condomínio"
      isLoading={isLoading}
      isError={isError}
      tableCondominios={tableCondominios}
      columns={columnsCondominios}
      refetch={refetch}
      data={data}
      totalItems={totalItems}
      itemsPerPage={itemsPerPage}
      setCurrentPage={setCurrentPage}
      setSearchContratoValue={setSearchContratoValue}
      currentPage={currentPage}
      manualSort={true}
    />
  )
}

export default Container
