import { api } from 'services/api'

export const getFaturas = async ( page =1, nome, status) => {
  const url = `faturas`
  const params = { 
    page
  }
  
  if (typeof nome === 'string' && nome !== '') {
    params['nome'] = nome
  }

  if (typeof status === 'string' && status !== '') {
    params['status'] = status
  }
  
  const response = await api.get(url, { params })

  return response.data
}

export const get_faturas = async () => {
  const url = `faturas`
 
  const response = await api.get(url)
  return response.data.data
}


export const getContratosCliente = async (idCliente) => {
  const response = await api.get(`clientes/listarContratos/${idCliente}`)
  return response.data.data
}

export const reenviarFaturas = async (idFatura) => {
  
  const url = 'faturas/reenviarFatura'
  const response = await api.get(`${url}/${idFatura}`)

  return response.data
}


export const importar = async (data) => {
  const url = 'faturas/convert-excel'

  const response = await api.patch(`${url}`, { data })

  return response.data
}

export const gerar = async (data) => {
  const url = 'faturas/gerarFaturasImport'

  const response = await api.post(`${url}`, { data })

  return response.data
}

export const consultar = async (idFatura) => {
  const url = 'faturas/get/status'

  const response = await api.get(`${url}/${idFatura}`)
  return response.data
}
