import React from 'react'
import { Row, Col } from 'react-awesome-styled-grid'

import { Title, Select, Input, Box } from '@superlogica/design-system'
import SeparationLine from 'components/SeparationLine/SeparationLine'

const View = ({ register }) => {
  const estadosUF = [
    { value: 'AC', name: 'AC' },
    { value: 'AL', name: 'AL' },
    { value: 'AP', name: 'AP' },
    { value: 'AM', name: 'AM' },
    { value: 'BA', name: 'BA' },
    { value: 'CE', name: 'CE' },
    { value: 'DF', name: 'DF' },
    { value: 'ES', name: 'ES' },
    { value: 'GO', name: 'GO' },
    { value: 'MA', name: 'MA' },
    { value: 'MS', name: 'MS' },
    { value: 'MT', name: 'MT' },
    { value: 'MG', name: 'MG' },
    { value: 'PA', name: 'PA' },
    { value: 'PB', name: 'PB' },
    { value: 'PR', name: 'PR' },
    { value: 'PE', name: 'PE' },
    { value: 'PI', name: 'PI' },
    { value: 'RJ', name: 'RJ' },
    { value: 'RN', name: 'RN' },
    { value: 'RS', name: 'RS' },
    { value: 'RO', name: 'RO' },
    { value: 'RR', name: 'RR' },
    { value: 'SC', name: 'SC' },
    { value: 'SP', name: 'SP' },
    { value: 'SE', name: 'SE' },
    { value: 'TO', name: 'TO' }
  ]
  return (
    <>
      <Box>
        <Title as="h4" size="display04" className="mb-xxxs">
          Dados da unidade
        </Title>
        <SeparationLine />
      </Box>
      <Box>
        <Row>
          <Col xs={12} md={4}>
            <Input label="Informe o Cep" className="mb-sm" type="text" {...register('cep')} />
          </Col>
          <Col xs={12} md={4}>
            <Input
              label="Hash da unidade"
              className="mb-sm"
              type="text"
              {...register('hash_unidade')}
            />
          </Col>
          <Col xs={12} md={4} className="mb-sm">
            <Input
              label="Código do consumidor"
              type="text"
              {...register('codigo_do_consumidor')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} className="mb-sm">
            <Input label="Endereço" type="text" {...register('endereco')} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} className="mb-sm">
            <Input label="Complemento" type="text" {...register('complemento')} />
          </Col>
          <Col xs={12} md={4} className="mb-sm">
            <Input label="Bairro" name="bairro" {...register('bairro')} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} className="mb-sm">
            <Input label="Cidade" type="text" {...register('cidade')} />
          </Col>
          <Col xs={12} md={4} className="mb-sm">
            <Select
              size="sm"
              name="UF"
              label="Estado"
              {...register('uf')}
              options={estadosUF}
            />
          </Col>
        </Row>
      </Box>
    </>
  )
}

export default View
