/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import {
  Box,
  Input,
  Title,
  Button,
  Modal,
  showToast,
  Select
} from '@superlogica/design-system'
import { editarEndereco } from 'services/contratos/contrato/atualizarStatus'
import { getSingleContratos } from 'services/contratos/contrato/getSingleContratos'

const View = ({ idContrato }) => {

  const [uf, setUf] = useState(idContrato.uf)

  const estadosUF = [
    { value: 'AC', name: 'AC' },
    { value: 'AL', name: 'AL' },
    { value: 'AP', name: 'AP' },
    { value: 'AM', name: 'AM' },
    { value: 'BA', name: 'BA' },
    { value: 'CE', name: 'CE' },
    { value: 'DF', name: 'DF' },
    { value: 'ES', name: 'ES' },
    { value: 'GO', name: 'GO' },
    { value: 'MA', name: 'MA' },
    { value: 'MS', name: 'MS' },
    { value: 'MT', name: 'MT' },
    { value: 'MG', name: 'MG' },
    { value: 'PA', name: 'PA' },
    { value: 'PB', name: 'PB' },
    { value: 'PR', name: 'PR' },
    { value: 'PE', name: 'PE' },
    { value: 'PI', name: 'PI' },
    { value: 'RJ', name: 'RJ' },
    { value: 'RN', name: 'RN' },
    { value: 'RS', name: 'RS' },
    { value: 'RO', name: 'RO' },
    { value: 'RR', name: 'RR' },
    { value: 'SC', name: 'SC' },
    { value: 'SP', name: 'SP' },
    { value: 'SE', name: 'SE' },
    { value: 'TO', name: 'TO' }
  ]

  function handleChange(e) {
    setUf(e.target.value)
  }



  const { register, handleSubmit, reset } = useForm()
  const openModalEditar = () => setIsOpen(true)
  const closeModalEditar = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)

  const { data: contrato = [] } = useQuery(
    'getContratoSingle',
    () => getSingleContratos(idContrato),
    { retry: false }
  )


  const singleContratoMutation = useMutation(editarEndereco)
  function editar(data) {
    
    singleContratoMutation.mutate(data, {
      onSuccess: () => {
        showToast({
          type: 'success',
          message: 'Campo atualizado com sucesso'
        })
        closeModalEditar()
        reset({})
        setTimeout(function(){
          window.location.reload();
     }, 2000); 
      }
    })
  }

  return (
    <>
      <Box paddingSize="none" className="mb-md">
        <Button onClick={openModalEditar} className="mr-xxxs"  >Editar endereço</Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={openModalEditar}
          width={600}
          label="Testando modal"
        >
           <form onSubmit={handleSubmit(editar)}>
            <input type="hidden" value={contrato?.uuId} {...register('id_contrato')} />
            <Box
              paddingType="square"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ borderBottom: 'none' }}
            >
              <Title size="body01" weight="bold" color="neutral800">
                Editar endereço
              </Title>
            </Box>
            <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              <Box>
                <Row>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Cep"   defaultValue={contrato.cep} name="cep" type="text" {...register('cep')} />
                  </Col>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Hash unidade"   defaultValue={contrato.hash_unidade} name="hash_unidade" type="text" {...register('hash_unidade')} />
                  </Col>
                </Row>
                <Row>
                <Col xs={12} md={6} className="mb-sm">
                  <Input label="Bairro"   defaultValue={contrato.bairro} name="bairro" type="text" {...register('bairro')} />
                  </Col>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Complemento"   defaultValue={contrato.complemento} name="complemento" type="text" {...register('complemento')} />
                  </Col>
                </Row>
                <Input label="Endereço"   defaultValue={contrato.endereco} name="endereco" type="text" {...register('endereco')} />
                <Select
                size="sm"
                label="Uf"
                className="mt-sm"
                name="uf"
                value={uf}
                {...register('UF')}
                onChange={handleChange}
                options={estadosUF}
              />
              </Box>
            </Box>
            <Box
              paddingType="squish"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ display: 'flex', borderTop: 'none' }}
            >
              <Button
                className="mr-auto"
                color="primary"
                appearance="ghost"
                onClick={closeModalEditar}
              >
                Cancelar
              </Button>
              <Button className="ml-auto">Salvar</Button>
            </Box>
          </form>
        </Modal>
      </Box>
    </>
  )
}

export default View