import { api } from 'services/api'

export const gerar = async (idAdministradora) => {
    const url = `rebate/adm/gerarRebate?id=${idAdministradora}`
  
    const response = await api.post(url)
  
    return response.data
  }


  export const getRebates = async (page = 1, sort, adm) => {
    const url = `rebate/adm/listar`
    const params = { 
      page
    }
    
    if(typeof adm === 'string' && adm !== ''){
      params['administradora'] = adm
    }

    if (sort?.direction !== 'none' && sort?.accessor !== '') {
      params['column'] = sort.accessor
      params['order'] = sort.direction
    }

    const response = await api.get(url, { params })
    return response.data
  }

  export const getSingleRebate = async (idRebate) => {
    const url = `rebate/adm/${idRebate}`
  
    const response = await api.get(url)
  
    return response.data
  }
