import { api } from 'services/api'

export const alterarStatus = async (data) => {
  const url = 'contratos/atualizarContratoStatus'

  const response = await api.patch(`${url}/${data.id}`, { data })

  return response.data.data
}

export const getStatus = async () => {
  const url = 'statusContratos'
  const response = await api.get(url)
  return response.data.data
}

export const getCooperativas = async () => {
  const url = 'cooperativas'
  const response = await api.get(url)
  return response.data.data
}

export const getDistribuidoras = async () => {
  const url = 'distribuidoras'
  const response = await api.get(url)
  return response.data.data
}

export const getAdministradoras = async () => {
  const url = 'administradoras'
  const response = await api.get(url)
  return response.data.data
}

export const reenviarTermos = async (data) => {
  const url = 'contratos/reenviarTermos'

  const response = await api.patch(`${url}/${data.id_contrato}`, { data })

  return response.data
}

export const atualizarHistorico = async (data) => {
  const url = 'contratos/setHistoricoContato'

  const response = await api.post(`${url}/${data.id_contrato}`, { data })

  return response.data
}

export const editarCampos = async (data) => {
  const url = 'contratos/update/consumo'

  const response = await api.patch(`${url}/${data.id_contrato}`, { data })

  return response.data.data
}

export const editarTipo = async (data) => {
  const url = 'contratos/update/tipoContrato'

  const response = await api.patch(`${url}/${data.id_contrato}`, { data })

  return response.data.data
}

export const upload = async (data) => {
  const url = 'contratos/uploadConta'

  const response = await api.post(`${url}/${data.id_contrato}`, { data })

  return response.data
}

export const gerarTermoService = async (data) => {
  const url = 'contratos/gerarTermo'

  const response = await api.post(`${url}/${data}`, { data })

  return response.data.data
}

export const ocr = async (data) => {
  const url = 'contratos/ocr'

  const response = await api.post(`${url}/${data}`, { data })

  return response.data
}

export const alterarStatusLote = async (data, allchecked) => {
  const url = 'contratos/atualizarContratoStatusLote'
  const response = await api.post(`${url}`, { data })

  return response.data.data
}

export const editarEndereco = async (data) => {
  const url = 'contratos/updateEndereco'

  const response = await api.patch(`${url}/${data.id_contrato}`, { data })

  return response.data.data
}
