import { api } from 'services/api'

export const getCondominios = async ( page = 1, nome, sort) => {
  const url = `condominios`
  const params = { 
    page
  }

  if (typeof nome === 'string' && nome !== '') {
    params['nome'] = nome
  }

  if (sort?.direction !== 'none' && sort?.accessor !== '') {
    if(sort.accessor === 'id_administradora.licenca'){
      params['column'] = 'licenca'
    }else{
      params['column'] = sort.accessor
    }
    params['order'] = sort.direction
  }


  const response = await api.get(url, { params })

  return response.data
}

export const getAllCondominios = async (value) => {
  const url = `condominios/listAllCondominios?nome=${value}`

  const response = await api.get(url)

  return response.data.data
}

export const cadastrarCondominio = async (data) => {
  const url = 'condominios';

  // Crie um objeto FormData e adicione o arquivo a ele
  const formData = new FormData();
  formData.append('rg_sindico', data.rg_sindico);
  formData.append('ata_condominio', data.ata_condominio);
  formData.append('cnpj', data.cnpj);
  formData.append('email', data.email);
  formData.append('id_administradora', data.id_administradora);
  formData.append('razao_social', data.razao_social);
  formData.append('id_condominio_erp', data.id_condominio_erp);
  formData.append('nome', data.nome);
  formData.append('qtd_unidades', data.qtd_unidades);
  formData.append('vencimento_boleto', data.vencimento_boleto);


  const response = await api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Configura o cabeçalho Content-Type
    },
  });

  return response.data;
};
export const getSingleCondominios = async (idCondo) => {
  if (idCondo) {
    let url = `condominios/${idCondo}`
    const response = await api.get(url)
    return response.data.data
  }
}

export const excluirCondominio = async (idCondo) => {
  const url = `condominios/${idCondo}`

  const response = await api.delete(url)

  return response.data
}

export const alterarCondominio = async (data) => {
  const url = `condominios/${data?.id}`
  delete data.id //remove o campo ID que não passa na validação dos campos no backoffice
  const response = await api.put(`${url}`, { data })

  return response.data
}

export const uploadRg = async (data) => {
  const url = `condominios/uploadRg/${data?.id}`
  const formData = new FormData();
  formData.append('rg_sindico', data.rg_sindico);


  const response = await api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Configura o cabeçalho Content-Type
    },
  });

  return response.data;
};

export const uploadAta = async (data) => {
  const url = `condominios/uploadAta/${data?.id}`
  const formData = new FormData();
  formData.append('ata_condominio', data.ata_condominio);


  const response = await api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Configura o cabeçalho Content-Type
    },
  });

  return response.data;
};
