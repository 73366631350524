import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { Box, Button, Modal, Title, showToast } from '@superlogica/design-system'
import Input from 'components/Input'
import { uploadRg } from 'services/condominios/condominios'

const View = ({ condoData }) => {
  const queryClient = useQueryClient()

  function refreshCondominio() {
    queryClient.invalidateQueries(['condominios'])
  }

  const {
    register,
    handleSubmit,
    reset,
  } = useForm()

  useEffect(() => {
    if (!condoData) {
      return
    }
    reset({
      ...condoData,
      licenca: condoData.id_administradora.licenca,
      id_administradora: condoData.id_administradora.id
    })
  }, [reset, condoData])

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)

  const editCondoMutation = useMutation(uploadRg)

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // Atualiza o estado
  };

  function editarCondo(condoData) {
    console.log(condoData)
    condoData.rg_sindico = selectedFile;
    editCondoMutation.mutate(condoData, {
      onSuccess: () => {
        refreshCondominio()
        showToast({
          type: 'success',
          message: 'Condominio alterado com sucesso'
        })
        closeModal()
        reset({})
      }
    })
  }

  return (
    <>
      <Box paddingSize="none" className="mb-md">
        <Button onClick={openModal} size="sm" className="mt-xs" style={{marginLeft: 100}} >
          Upload Rg Sindico
        </Button>
        <Modal isOpen={isOpen} onRequestClose={closeModal} width={800} label="Testando modal">
          <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
            <form onSubmit={handleSubmit(editarCondo)}>
              <Box
                paddingType="square"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ borderBottom: 'none' }}
              >
                <Title size="body01" weight="bold" color="neutral800">
                Upload Rg sindico
                </Title>
              </Box>
              <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              <input type="hidden" {...register('id')} />
                  <Input
                  label="RG do sindico"
                  type="file"
                  accept="*" 
                  onChange={handleFileChange}
                  className="mb-sm"
                  {...register('rg_sindico',{
                    onChange: (e) => handleFileChange(e)
                  })}
                />

                  {/* <Input
                  label="Ata do condominio"
                  type="file"
                  accept="*" 
                  onChange={handleFileChangeAta}
                  className="mb-sm"
                  {...register('ata_condominio',{
                    onChange: (e) => handleFileChangeAta(e)
                  })}
                /> */}
              </Box>
              <Box
                paddingType="squish"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ display: 'flex', borderTop: 'none' }}
              >
                <Button
                  className="mr-auto"
                  color="primary"
                  appearance="ghost"
                  onClick={closeModal}
                >
                  Cancelar
                </Button>

                <Button className="ml-auto" type="submit">
                  Salvar
                </Button>
              </Box>
            </form>
          </div>
        </Modal>
      </Box>
    </>
  )
}

export default View
