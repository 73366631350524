import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { getFatura } from 'services/faturas/fatura/cadastrarFatura'
import { BoxContainer } from 'styles/global-style'

import DadosFatura from './DadosFatura/Container'

const SingleFatura = () => {
  const { uuId } = useParams()

  const {
    data: fatura,
    isLoading,
    isIdle
  } = useQuery(['singleFatura', uuId], () => getFatura(uuId))

  const faturaLoading = isLoading || isIdle

  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="lg">
        {faturaLoading ? 'Carregando fatura...' : <DadosFatura uuId={uuId} data={fatura} />}
      </BoxContainer>
    </>
  )
}

export default SingleFatura
