import { Title } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { BoxContainer } from 'styles/global-style'

import ListaClientes from './ListaClientes'
import NovoCliente from './NovoCliente/Container'

const AllClientes = () => {
  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="lg">
        <Title color="neutral800" size="display03">
          Clientes
        </Title>
        <NovoCliente />
        <ListaClientes />
      </BoxContainer>
    </>
  )
}

export default AllClientes
