import { Redirect, Route } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { keycloak } = useKeycloak()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        keycloak?.authenticated && keycloak.hasRealmRole('backoffice-energia-user') ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: location
              }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
