import React from 'react'

import { Box, Table, Text } from '@superlogica/design-system'
import { BoxTabTable, BoxTitleTable } from 'styles/global-style'

const View = ({ isLoading, isError, historicos = [], columns, refetch }) => {
  return historicos.length > 0 ? (
    <>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xxs"
        radius="md"
        className="flex align-center"
      >
        <BoxTitleTable paddingSize="none">
          <Text className="mr-xxxs">Históricos</Text>
          <Text color="neutral400" size="body02">
            {`${historicos.length} itens`}
          </Text>
        </BoxTitleTable>
      </BoxTabTable>
      <Box border borderColor="neutral300" bgColor="neutral0" paddingSize="none" radius="xs">
        <Table
          data={historicos}
          columns={columns}
          tableName="faturas"
          refetch={refetch}
          isError={isError}
          isLoading={isLoading}
        />
      </Box>
    </>
  ) : (
    <Box
      border
      borderColor="neutral300"
      bgColor="neutral0"
      paddingType="squish"
      paddingSize="md"
      radius="xs"
    >
      <Text>{'Cliente não possui históricos'}</Text>
    </Box>
  )
}

export default View
