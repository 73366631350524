import { withTranslation } from 'react-i18next'

import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { BoxContainer } from 'styles/global-style'

import LanguageSwitch from './components/LanguageSwitch'

const HomeView = ({ t }) => {
  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="lg">
        <LanguageSwitch />
        <h1>{t('common:welcome.title')}</h1>
      </BoxContainer>
    </>
  )
}

export default withTranslation()(HomeView)
