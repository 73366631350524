import React from 'react'

import { Button, showToast } from '@superlogica/design-system'
import { gerarTermoService} from 'services/contratos/contrato/atualizarStatus'

const View = ({ data, idContrato }) => {

  async function gerarTermo() {
    try {
      await gerarTermoService(idContrato)
      showToast({
        type: 'success',
        message: 'Gerado com sucesso'
      })
      setTimeout(function(){
        window.location.reload();
   }, 2000); 
    } catch (error) {
      showToast({
        type: 'error',
        message: 'Erro ao gerar'
      })
    }
  }
  var termo = null
  var campos = true

  if(data['numeroInstalacao'] === ""
  ||data['numeroInstalacao'] == null
  || data['consumoMedio'] == null
  || data['valorUltimaConta'] == null){
      campos = false
  }
 
  if(data['termosCooperativa'] != null)
    {
      if(data['termosCooperativa'].length > 3 ){
        termo = data['termosCooperativa']
      }else{
        termo = null
      }
    }
  return (
    <>

  <Button className={`mr-xxxs ${termo === null ? 'disabled-button' : ''}`} 
      onClick={(e) => {
        e.preventDefault();
        if (termo !== null) {
          window.location.href = data['termosCooperativa'];
        }
      }}
      disabled={termo === null}>
      Visualizar termo
    </Button>
      
    <Button className={`mr-xxxs ${!campos ? 'disabled-button' : ''}`} 
            onClick={gerarTermo} 
            disabled={!campos}>
      Gerar termo
    </Button>
    </>
  
 
  )
}

export default View
