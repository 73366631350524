import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { getSingleCondominios } from 'services/condominios/condominios'
import { BoxContainer } from 'styles/global-style'

import DadosCondominios from './DadosCondominios/View'

const SingleCondominio = () => {
  const { id } = useParams()

  const { data: condominio } = useQuery(['condominios', id], () => getSingleCondominios(id), {
    retry: false
  })

  //   const { data = [] } = useQuery(['getContratos', id], () => getContratosLista(id), {
  //     retry: false
  //   })

  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="none">
        <DadosCondominios condoData={condominio} />
      </BoxContainer>

      <BoxContainer paddingSize="lg">
        <Box paddingSize="none" className="mb-md flex"></Box>
        <Box paddingSize="none" className="mb-md">
          {/* <ListaContratos data={data} /> */}
        </Box>
        <Box paddingSize="none" className="mb-md">
          {/* <ListaFaturas id={id} /> */}
        </Box>
      </BoxContainer>
    </>
  )
}

export default SingleCondominio
