import { api } from 'services/api'

export const getAdministradoras = async (razao, page = 1, sort) => {
  const url = `administradoras`
  const params = { 
    page
  }
  if (typeof razao === 'string' && razao !== '') {
    params['licenca'] = razao
  }

  if (sort?.direction !== 'none' && sort?.accessor !== '') {
    params['column'] = sort.accessor
    params['order'] = sort.direction
  }



  const response = await api.get(url, { params })

  
  return response.data
}

export const getAllAdministradoras = async (query) => {
  const url = `administradoras/listAllAdministradoras`
  const params = { 
    licenca: query
  }

  const response = await api.get(url, { params })

  return response.data.data
}


export const alterarAdminstradora = async (data) => {
  const url = `administradoras/${data?.id}`

  const response = await api.put(url, { data })

  console.log(response)
  return response.data
}

export const cadastrarAdministradora = async (data) => {
  const url = 'administradoras'
  const response = await api.post(url, { data })

  console.log(response)
  return response.data
}

export const getSingleAdministradoras = async (idAdm) => {
  if (idAdm) {
    let url = `administradoras/${idAdm}`
    const response = await api.get(url)
    return response.data.data
  }
}

export const excluirAdministradora = async (idAdministradora) => {
  const url = `administradoras/${idAdministradora}`

  const response = await api.delete(url)

  return response.data
}

export const alterarAdministradora = async (data) => {
  const url = `administradoras/${data?.id}`
  delete data.id //remove o campo ID que não passa na validação dos campos no backoffice
  console.log('alterar', url)
  const response = await api.put(`${url}`, { data })

  return response.data
}

export const getAdministradorasLicenca = async (licenca) => {
  let url = `administradoras?licenca=${licenca}`
  const response = await api.get(url)

  return response.data.data
}

export const gerarToken = async (licenca) => {
  const url = `administradoras/gerarToken?licenca=${licenca}`
  const response = await api.post(url)

  console.log(response)
  return response.data
}
