import React from 'react'
import { Route, Switch } from 'react-router-dom'

import AllAdministradoras from 'pages/Administradoras/AllAdministradoras'
import SingleAdministradora from 'pages/Administradoras/Single'
import AllClientes from 'pages/Clientes/AllClientes'
import SingleClientes from 'pages/Clientes/Single'
import AllCondominios from 'pages/Condominios/AllCondominios'
import SingleCondominio from 'pages/Condominios/Single'
import AllContratos from 'pages/Contratos/AllContratos'
import AllContratosPj from 'pages/Contratos/AllContratosPj'
import SingleContratos from 'pages/Contratos/Single'
import ErrorPage from 'pages/Error'
import Exportar from 'pages/Exportar'
import AllFaturas from 'pages/Faturas/AllFaturas'
import SingleFatura from 'pages/Faturas/Single'
import HomePage from 'pages/Home'
import LoginPage from 'pages/Login'
import AllRebates from 'pages/Rebates/AllRebates'
import SingleRebate from 'pages/Rebates/Single'

import PrivateRoute from './Auth/PrivateRoute'

const RouterConfig = () => {
  return (
    <div>
      <Switch>
        <PrivateRoute path="/all-clientes" component={AllClientes} />
        <PrivateRoute path="/all-contratos" component={AllContratos} />
        <PrivateRoute path="/all-contratos-pj" component={AllContratosPj} />
        <PrivateRoute path="/all-faturas" component={AllFaturas} />
        <PrivateRoute path="/all-administradoras" component={AllAdministradoras} />
        <PrivateRoute path="/all-condominios" component={AllCondominios} />
        <PrivateRoute path="/all-rebates" component={AllRebates} />
        <PrivateRoute path="/exportar" component={Exportar} />
        <PrivateRoute path="/single-cliente/:id" component={SingleClientes} />
        <PrivateRoute path="/single-rebate/:id" component={SingleRebate} />
        <PrivateRoute path="/single-contrato/:uuId" component={SingleContratos} />
        <PrivateRoute path="/single-fatura/:uuId" component={SingleFatura} />
        <PrivateRoute path="/single-administradora/:id" component={SingleAdministradora} />
        <PrivateRoute path="/single-condominio/:id" component={SingleCondominio} />
        <Route exact path="/" component={LoginPage} />
        <PrivateRoute path="/app" component={HomePage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </div>
  )
}

export default RouterConfig
