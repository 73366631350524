/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { Chip, Text } from '@superlogica/design-system'
import { Search } from '@superlogica/design-system/Icons/superlogica'
import { format } from 'date-fns'
import { getSingleFaturasClientes } from 'services/clientes/cliente/singleCliente'

import View from './View'

const Container = ({ id }) => {
  const {
    data: faturasCliente = [],
    isLoading,
    isError,
    refetch
  } = useQuery(['contratoSingleLista', id], () => getSingleFaturasClientes(id))

  const dicionario = {
    Pago: 'success02',
    Em_aberto: 'neutral200',
    Atrasado: 'danger03'
  }

  const columns = useMemo(() => [
    {
      Header: 'Fatura', // texto q será exibido no cabeçalho da tabela
      accessor: 'faturas.nosso_numero', // id das células dessa coluna
      align: 'left',
      Cell: ({ row }) => {
        return <Text>{faturasCliente[row.index]?.nosso_numero}</Text>
      }
    },
    {
      Header: 'Vencimento',
      accessor: 'faturas.vencimento',
      align: 'left',
      Cell: ({ row }) => {
        let date = new Date(faturasCliente[row.index]?.vencimento)
        let dataFormatada = format(date, 'dd/MM/yyyy')
        return <Text>{dataFormatada}</Text>
      }
    },
    {
      Header: 'Status',
      accessor: 'faturas.status_cobranca',
      align: 'left',
      Cell: ({ row }) => (
        <Chip
          label={faturasCliente[row.index]?.status_cobranca}
          color={dicionario[row.values?.status_cobranca]}
        />
      )
    },
    {
      Header: 'Valor',
      accessor: 'faturas.valor',
      align: 'left',
      Cell: ({ row }) => {
        let atual = faturasCliente[row.index]?.valor
        let formatada = atual
          .toLocaleString('pt-br', { style: 'currency', currency: 'R$' })
          .replace('.', ',')
        return <Text>{`R$ ${formatada}`}</Text>
      }
    },
    {
      Header: '',
      accessor: 'icon',
      align: 'center',
      Cell: ({ row }) => {
        const faturaId = faturasCliente[row.index].uuId
        return (
          <Link to={`../single-fatura/${faturaId}`}>
            <Search height="16" width="16" />
          </Link>
        )
      }
    }
  ])

  const tableSingleFaturaClientes = useMemo(() => faturasCliente, [faturasCliente])

  return (
    <View
      title="Lista Fatura Single"
      faturasCliente={tableSingleFaturaClientes}
      columns={columns}
      isError={isError}
      isLoading={isLoading}
      refetch={refetch}
    />
  )
}
export default Container
