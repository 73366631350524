import { useQuery, useQueryClient } from 'react-query'

import { getSingleClientes } from 'services/clientes/cliente/singleCliente'
import { getContratosLista } from 'services/contratos/getContratosLista'

import View from './View'

const Container = ({ data, clientes, uuId }) => {
  const idCliente = uuId

  const { data: cliente = [] } = useQuery(['getSingle', idCliente], () =>
    getSingleClientes(idCliente)
  )

  const { data: contrato = [] } = useQuery(['getContratoLista', idCliente], () =>
    getContratosLista(idCliente)
  )

  function populateOptions(contrato) {
    return contrato.map((item) => ({
      name: item.endereco,
      value: item.uuId
    }))
  }

  const optionsContratos = populateOptions(contrato)

  const queryClient = useQueryClient()

  function refreshSingleFatura() {
    queryClient.invalidateQueries(['contratoSingleLista'])
  }

  return (
    <View
      title="Display Nova Faturas"
      data={data}
      optionsContratos={optionsContratos}
      cliente={cliente}
      refreshSingleFatura={refreshSingleFatura}
    />
  )
}

export default Container
