import React from 'react'
import { Link } from 'react-router-dom'

import { Box } from '@superlogica/design-system'

const View = ({
  //  admData,
    quantidade , 
    rebateId}) => {

  // const openModalAdmin = () => setIsOpen(true)
  // const closeModalAdmin = () => setIsOpen(false)
  // const [isOpen, setIsOpen] = useState(false)
  
  return (
    <>
    <Box paddingSize="none" className="mb-md">
            <Link  to={`../single-rebate/${rebateId}`} appearance="outline" size="sm" className="ml-auto">
            {quantidade}
            </Link>
        
      </Box>
    </>
  )
}

export default View
