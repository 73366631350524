import React, {useState } from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { useQuery } from 'react-query'

import { Title } from '@superlogica/design-system'
import { Button } from '@superlogica/design-system'
import { BoxCounter } from 'components/BoxCounter/BoxCounter'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
//import SearchContratos from 'components/SearchContratos/Container'
import { getCount } from 'services/getCount'
import { BoxContainer } from 'styles/global-style'

import ListContratos from './ListaContratos/Container'
import NovoContrato from './NovoContrato/Container'


const AllContratos = () => {
  const {data = [] } = useQuery(['getCountContratos'], () => getCount('StatusContrato'))
  const [inputValue, setInputValue] = useState('')
  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer  paddingSize="lg">
        <Title color="neutral800" size="display03" className="mb-xs">
          Contratos
        </Title>
        <NovoContrato />
        <>
        <Row style={{ marginBottom: 16 }}>
        <Col xs={12} md={1.5}>
            <Button style={{ backgroundColor: '#fff', width:'100%', border: '1px solid #828584'}} onClick={(e) => {
               setInputValue('1') 
            }}   className="mr-xxxs">
              <BoxCounter valor={data['Adesão incompleta']} categoria="Adesão incompleta" color="green700" />
            </Button>
          </Col>
          <Col xs={12} md={1.5}>
            <Button style={{ backgroundColor: '#fff', width:'100%', border: '1px solid #828584'}} onClick={(e) => {
               setInputValue('6') 
            }}   className="mr-xxxs">
              <BoxCounter valor={data['Em validação']} categoria="Em validação" color="green700" />
            </Button>
          </Col>
          <Col xs={12} md={1.5}>
           <Button   style={{ backgroundColor: '#fff', width:'100%', border: '1px solid #828584'}}  onClick={(e) => {
               setInputValue('8') 
            }}   className="mr-xxxs">
              <BoxCounter valor={data['Aguardando cliente']} categoria="Aguardando cliente" color="yellow700" />
            </Button>
          </Col>
          <Col xs={12} md={1.5}>
             <Button   style={{ backgroundColor: '#fff', width:'100%', border: '1px solid #828584'}}  onClick={(e) => {
               setInputValue('9') 
            }}   className="mr-xxxs">
              <BoxCounter valor={data['Enviar à cooperativa']} categoria="Enviar à cooperativa" color="red700" />
            </Button>
            
          </Col>
          <Col xs={12} md={1.5}>
          <Button  style={{ backgroundColor: '#fff', width:'100%', border: '1px solid #828584'}} onClick={(e) => {
               setInputValue('10') 
            }}   className="mr-xxxs">
              <BoxCounter valor={data['Encaminhado a Cooperativa']} categoria="Encaminhado a Cooperativa" color="red700" />
            </Button>
          </Col>
          <Col xs={12} md={1.5}>
          <Button  style={{ backgroundColor: '#fff', width:'100%', border: '1px solid #828584'}} onClick={(e) => {
               setInputValue('3') 
            }}   className="mr-xxxs">
              <BoxCounter valor={data['Ativo']} categoria="Ativo" color="red700" />
            </Button>
          </Col>
          <Col xs={12} md={1.5}>
          <Button  style={{ backgroundColor: '#fff', width:'100%', border: '1px solid #828584'}} onClick={(e) => {
               setInputValue('2') 
            }}   className="mr-xxxs">
              <BoxCounter valor={data['Cancelado']} categoria="Cancelado" color="red700" />
            </Button>
          </Col>
          <Col xs={12} md={1.5}>
          <Button  style={{ backgroundColor: '#fff', width:'100%', border: '1px solid #828584'}} onClick={(e) => {
               setInputValue('12') 
            }}   className="mr-xxxs">
              <BoxCounter valor={data['Reprovado']} categoria="Reprovado" color="red700" />
            </Button>
          </Col>
        </Row>
    </>
        {/* <SearchContratos /> */}
        <ListContratos vari={inputValue} />
      </BoxContainer>
    </>
  )
}

export default AllContratos