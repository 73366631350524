import { Row, Col } from 'react-awesome-styled-grid'

import { Input, Title, Box, Select } from '@superlogica/design-system'
import SeparationLine from 'components/SeparationLine/SeparationLine'

const View = ({ register }) => {
  const pagamentos = [
    { value: 'Cartao', name: 'Cartão' },
    { value: 'Boleto', name: 'Boleto' },
    { value: 'Boletao', name: 'Boletão' }
  ]

  return (
    <>
      <Box>
        <Title as="h4" size="display04" className="mb-xxxs">
          Dados da cobrança
        </Title>
        <SeparationLine />
      </Box>
      <Row>
        <Col xs={12} md={6} style={{ marginBottom: 16 }}>
          <Input label="Vencimento da Cobrança" {...register('vencimento_boleto')} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-sm">
          <Select
            size="sm"
            label="Método de pagamento"
            {...register('meio_pagamento')}
            options={pagamentos}
          />
        </Col>
      </Row>
    </>
  )
}

export default View
