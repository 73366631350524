/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Input,
  Title,
  Button,
  Modal,
  showToast
} from '@superlogica/design-system'
import { getSingleClientes } from 'services/clientes/cliente/singleCliente'
import { editarCampos } from 'services/contratos/contrato/atualizarStatus'
import { getSingleContratos } from 'services/contratos/contrato/getSingleContratos'
import * as yup from 'yup'

const View = ({ idCliente, idContrato }) => {

  const schemaContrato = yup
  .object({
    consumoMedio: yup.number('Apenas numeros').required(),
  })
  .required('O campo é obrigatório.')

  const {
    register,
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(schemaContrato)
  })
  const openModalEditar = () => setIsOpen(true)
  const closeModalEditar = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)

  const { data: cliente = [] } = useQuery('getSingle', () => getSingleClientes(idCliente), {
    retry: false
  })

  const { data: contrato = [] } = useQuery(
    'getContratoSingle',
    () => getSingleContratos(idContrato),
    { retry: false }
  )

  const singleContratoMutation = useMutation(editarCampos)
  function editar(data) {
    singleContratoMutation.mutate(data, {
      onSuccess: () => {
        showToast({
          type: 'success',
          message: 'Campos atualizados com sucesso'
        })
        closeModalEditar()
        reset({})
        setTimeout(function(){
          window.location.reload();
     }, 2000); 
      }     
    })
  }
  
  return (
    <>
      <Box paddingSize="none" className="mb-md">
        <Button onClick={openModalEditar}  className="mr-xxxs ml-xxxs">Editar novos Campos</Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={openModalEditar}
          width={600}
          label="Testando modal"
        >
          <form onSubmit={handleSubmit(editar)}>
            <input type="hidden" value={contrato?.uuId} {...register('id_contrato')} />
            <Box
              paddingType="square"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ borderBottom: 'none' }}
            >
              <Title size="body01" weight="bold" color="neutral800">
                Editar Contrato
              </Title>
            </Box>
            <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              <Box>
                <Input type="text" className="mb-sm" label={cliente?.nome_completo} disabled />
                <Row>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Valor da ultima conta"   defaultValue={contrato.valorUltimaConta} name="valorUltimaConta" type="text" {...register('valorUltimaConta')} />
                  </Col>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Consumo médio KWH"   defaultValue={contrato.consumoMedio} name="consumoMedio" type="text" {...register('consumoMedio')} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mb-sm">
                  <Input label="Numero de instalação"   defaultValue={contrato.numeroInstalacao} name="numeroInstalacao" type="text" {...register('numeroInstalacao')} />
                  </Col>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Código do consumidor"   defaultValue={contrato.codigo_do_consumidor} name="codigo_do_consumidor" type="text" {...register('codigo_do_consumidor')} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} className="mb-sm">
                  <Input label="Tipo de conexão"   defaultValue={contrato.tipo_conexao} name="tipo_conexao" type="text" {...register('tipo_conexao')} />
                  </Col>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Tipo tarifa"   defaultValue={contrato.tipo_tarifa} name="tipo_tarifa" type="text" {...register('tipo_tarifa')} />
                  </Col>
                </Row>
                <Input label="Porcentagem de desconto"   defaultValue={contrato.porcentagemDesconto} name="porcentagemDesconto" type="text" {...register('porcentagemDesconto')} />
              </Box>
            </Box>
            <Box
              paddingType="squish"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ display: 'flex', borderTop: 'none' }}
            >
              <Button
                className="mr-auto"
                color="primary"
                appearance="ghost"
                onClick={closeModalEditar}
              >
                Cancelar
              </Button>
              <Button className="ml-auto">Salvar</Button>
            </Box>
          </form>
        </Modal>
      </Box>
    </>
  )
}

export default View
