/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'

import { Text } from '@superlogica/design-system'
import { format } from 'date-fns'

import DadosFaturas from '../DadosFaturas/Container'
import View from './View'

const Container = ({ historicos, adm }) => {

  const columns = useMemo(() => [
    {
      Header: 'Data',
      accessor: 'rebateAdms.criadoEm',
      align: 'left',
      Cell: ({ row }) => {
        let date = new Date(historicos[row.index]?.criadoEm)
        let dataFormatada = format(date, 'dd/MM/yyyy')
        return <Text>{dataFormatada}</Text>
      }
    },
    {
      Header: 'Valor',
      accessor: 'rebateAdms.valor',
      align: 'left',
      Cell: ({ row }) => {
        return <Text>{historicos[row.index]?.valor}</Text>
      }
    },
    {
      Header: 'Status',
      accessor: 'rebateAdms.status',
      align: 'left',
      Cell: ({ row }) => {
        return <Text>{historicos[row.index]?.status}</Text>
      }
    },
    {
      Header: 'Quantidade faturas',
      accessor: 'rebateAdms.quantidadeFaturas',
      align: 'left',
      Cell: ({ row }) => {
        return <DadosFaturas quantidade={historicos[row.index]?.quantidadeFaturas} rebateId={historicos[row.index]?.id} admData={adm} /> 
      }
    }
    
  ])

  const tableSingleHistorico = useMemo(() => historicos, [historicos])

  return (
    <View title="Lista Historico Single" historicos={tableSingleHistorico} columns={columns} />
    
  )
}

export default Container
