import { Text, Box } from '@superlogica/design-system'
import StatusContrato from 'pages/Contratos/components/StatusContrato'

const View = ({ textUnidade }) => {
  return (
    <>
      <Box paddingSize="none" className="flex align-center mt-xs mb-xxs">
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Endereço
          </Text>
          <Text color="neutral600" size="body02">
            {textUnidade?.endereco}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Complemento
          </Text>
          <Text color="neutral600" size="body02">
            {textUnidade?.complemento}
          </Text>
        </Box>
        <Box paddingSize="none" className="flex align-center mr-xs">
          <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
            Bairro
          </Text>
          <Text color="neutral600" size="body02">
            {textUnidade?.bairro}
          </Text>
        </Box>
        <Box>
          {/* <Chip
            className="mr-xxxs"
            label={textUnidade?.id_contratoStatus?.nome}
            color={dicionario[textUnidade?.id_contratoStatus?.nome]}
          /> */}
          <StatusContrato contrato={textUnidade} />
        </Box>
        {textUnidade.id_contratoStatus.nome === "Reprovado" || textUnidade.id_contratoStatus.nome ===  "Cancelado"  ?   
            <Box paddingSize="none" className="flex align-center mr-xs">
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Motivo:
              </Text>
              <Text color="neutral600" size="body02">
                {textUnidade?.status_descricao}
              </Text>
            </Box>  : ""}
            {textUnidade.id_contratoStatus.nome === "Cancelado"  ?   
            <Box paddingSize="none" className="flex align-center mr-xs">
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Descrição:
              </Text>
              <Text color="neutral600" size="body02">
                {textUnidade?.status_descricao_cancelamento}
              </Text>
            </Box>  : ""}
      </Box>
    </>
  )
}

export default View
