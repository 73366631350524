import React from 'react'
import { useQueryClient } from 'react-query'

import View from './View.jsx'

const Container = () => {
  const queryClient = useQueryClient()

  function refreshAdministradora() {
    queryClient.invalidateQueries(['adminLista'])
  }

  return <View title="Cadastrar Agência" refreshAdministradora={refreshAdministradora} />
}

export default Container
