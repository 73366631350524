import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation} from 'react-query'

import { Text, Box, Button,showToast, } from '@superlogica/design-system'
import { format } from 'date-fns'
import { atualizarHistorico } from 'services/contratos/contrato/atualizarStatus'


import View from './View'

const Container = ({ uuId, historicos }) => {

  const { register, handleSubmit, reset } = useForm()

  const historicoContratoMutation = useMutation(atualizarHistorico)

  function historicoContrato(data) {
    const item ={
      id_contrato: uuId,
      descricao: data.descricao
    }
    historicoContratoMutation.mutate(item, {
      onSuccess: () => {
        showToast({
          type: 'success',
          message: 'Histórico criado com sucesso'
        })
        reset({})
        setTimeout(function(){
          window.location.reload();
     }, 1000); 
      }
    })
  }

  const columns = useMemo(() => [
    {
      Header: 'Data',
      accessor: 'contratosHistoricos.data_criacao',
      align: 'left',
      Cell: ({ row }) => {
        let date = new Date(historicos[row.index]?.data_criacao)
        let dataFormatada = format(date, 'dd/MM/yyyy')
        return <Text>{dataFormatada}</Text>
      }
    },
    {
      Header: 'Descrição',
      accessor: 'contratosHistoricos.descricao',
      align: 'left',
      Cell: ({ row }) => {
        return <Text>{historicos[row.index]?.descricao}</Text>
      }
    }
  ], [historicos])

  const tableSingleHistorico = useMemo(() => historicos, [historicos])

  return (
    <>
     <div style={{ display: 'flex', borderTop: 'none', alignItems:'flex-start'}} >
      <div style={{ flex: '60%'}} >
        <View title="Lista Historico contato Single" historicos={tableSingleHistorico} columns={columns} />
      </div>
      <Box  
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="lg"
        paddingType="squish"
        radius="xs" className="ml-sm">
        <form onSubmit={handleSubmit(historicoContrato)}>
          <Text className="mb-xs" size="body01" >Inserir observação</Text>
          <textarea   
            {...register('descricao', {
              required: 'O campo está vazio',
            })} 
            style={{border: '2px solid #c7c7c7'}}
            rows={5} cols={50}/>
          <Box
            paddingType="squish"
            style={{ display: 'flex', borderTop: 'none' }}
          >
            <Button className="ml-auto">Salvar</Button>
          </Box>
        </form>
      </Box>
     
    </div>

    

    </>
  
  )
}

export default Container
