import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { Box, Button, Modal, Title, showToast } from '@superlogica/design-system'
import Input from 'components/Input'
import { alterarCondominio } from 'services/condominios/condominios'

const View = ({ condoData }) => {
  const queryClient = useQueryClient()

  function refreshCondominio() {
    queryClient.invalidateQueries(['condominios'])
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    if (!condoData) {
      return
    }
    reset({
      ...condoData,
      licenca: condoData.id_administradora.licenca,
      id_administradora: condoData.id_administradora.id
    })
  }, [reset, condoData])

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)

  const editCondoMutation = useMutation(alterarCondominio)

  function editarCondo(condoData) {
    editCondoMutation.mutate(condoData, {
      onSuccess: () => {
        refreshCondominio()
        showToast({
          type: 'success',
          message: 'Condominio alterado com sucesso'
        })
        closeModal()
        reset({})
      }
    })
  }

  return (
    <>
      <Box paddingSize="none" className="mb-md">
        <Button onClick={openModal} appearance="outline" size="sm" className="ml-auto">
          Editar
        </Button>
        <Modal isOpen={isOpen} onRequestClose={closeModal} width={800} label="Testando modal">
          <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
            <form onSubmit={handleSubmit(editarCondo)}>
              <Box
                paddingType="square"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ borderBottom: 'none' }}
              >
                <Title size="body01" weight="bold" color="neutral800">
                  Editar Administradora
                </Title>
              </Box>
              <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
                <input type="hidden" {...register('id')} />
                <input type="hidden" {...register('id_administradora')} />
                <Input
                  label="Nome administradora"
                  className="mb-sm"
                  {...register('licenca')}
                  disabled
                  errors={errors}
                />
                <Input
                  label="Informe o Nome do Condominio"
                  className="mb-sm"
                  {...register('nome', {
                    required: 'a nome é obrigatório',
                    maxLength: { value: 50, message: 'valor maximo 10' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe o cnpj"
                  className="mb-sm"
                  mask="cnpj"
                  {...register('cnpj', {
                    required: 'O cnpj é de preenchimento obrigatório',
                    maxLength: { value: 18, message: 'valor maximo 18' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu Vencimento do Boleto do Condomínio"
                  className="mb-sm"
                  mask="phone"
                  {...register('vencimento_boleto', {
                    required: 'O Vencimento do Boleto do Condomínio é obrigatório',
                    maxLength: { value: 2, message: 'valor maximo 2' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe o ID do ERP do condomínio "
                  className="mb-sm"
                  {...register('id_condominio_erp', {
                    required: 'A ID é obrigatório',
                    maxLength: { value: 4, message: 'valor maximo 4' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Quantidade de unidades"
                  className="mb-sm"
                  {...register('qtd_unidades', {
                    maxLength: { value: 4, message: 'valor maximo 4' }
                  })}
                  errors={errors}
                />
                 <Input
                  label="Email"
                  className="mb-sm"
                  {...register('email', {
                    required: 'O email é obrigatório'
                  })}
                  errors={errors}
                />

<Input
                  label="Razão social"
                  className="mb-sm"
                  {...register('razao_social', {
                    required: 'A razão social é obrigatória',
                    maxLength: { value: 40, message: 'valor maximo 40' }
                  })}
                  errors={errors}
                />
                  {condoData['rg_sindico'] ? (
                    <Button
                    className="mr-xxxs"
                    onClick={(e) => {
                      e.preventDefault()
                      window.location.href = condoData['rg_sindico']
                    }}
                  >
                    Visualizar Rg do sindico
                    </Button> ) : (
                    <>
                    </>
                  )}
                  {condoData['ata_condominio'] ? (
                    <Button
                    className="mr-xxxs"
                    onClick={(e) => {
                      e.preventDefault()
                      window.location.href = condoData['ata_condominio']
                    }}
                  >
                    Visualizar Ata
                    </Button> ) : (
                    <>
                    </>
                  )}
              </Box>
              <Box
                paddingType="squish"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ display: 'flex', borderTop: 'none' }}
              >
                <Button
                  className="mr-auto"
                  color="primary"
                  appearance="ghost"
                  onClick={closeModal}
                >
                  Cancelar
                </Button>

                <Button className="ml-auto" type="submit">
                  Salvar
                </Button>
              </Box>
            </form>
          </div>
        </Modal>
      </Box>
    </>
  )
}

export default View
