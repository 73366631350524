import React, { useState } from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import { Box, Title, Button, Modal, showToast, Input } from '@superlogica/design-system'
import { upload } from 'services/contratos/contrato/atualizarStatus'
import { editarCampos } from 'services/contratos/contrato/atualizarStatus'
import { ocr } from 'services/contratos/contrato/atualizarStatus'
import { getSingleContratos } from 'services/contratos/contrato/getSingleContratos'

const View = ({ idContrato }) => {
  const { register, handleSubmit, reset } = useForm()
  const openModalEditar = () => setIsOpen(true)
  const closeModalEditar = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)
  const [uploadC, setUploadC] = useState('')
  const [response, setResponse] = useState('')
  const [disable, setDisable] = useState(false)
  const [disable2, setDisable2] = useState(false)
  const [disable3, setDisable3] = useState(false)
  const { data = [] } = useQuery('contratos', () => getSingleContratos(idContrato))

  const singleContratoMutation = useMutation(upload)
  const singleContratoMutationDados = useMutation(editarCampos)
  const dadosContratoMutation = useMutation(ocr)

  

  function editar(data) {
    setDisable3(true)
    const dataa = {
      id_contrato: data.id_contrato,
      ultima_conta_concessionaria: uploadC
    }
    singleContratoMutation.mutate(dataa, {
      onSuccess: (data) => {
        showToast({
          type: 'success',
          message: data.message
        })
        reset({})
        setDisable3(false)
        setResponse(data.resp)
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        })
        setDisable3(false)
      }
    })
  }

  function dadosOcr() {
    setDisable(true)
    dadosContratoMutation.mutate(idContrato, {
      onSuccess: (data) => {
        reset({})
        setResponse(data.resp)
        setDisable(false)
        setIsOpen(true)
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        })
        setDisable(false)
      }
    })
  }

  function atualizarCampos(data) {
    setDisable2(true)
    const data2 = {
      id_contrato: data.id_contrato,
      valorUltimaConta: response?.valor,
      numeroInstalacao: response?.instalacao,
      consumoMedio: response?.consumo,
      codigo_do_consumidor: response?.codCliente,
      tipo_tarifa: response?.modalidade_tarifaria,
      tipo_conexao: response?.tipo
    }
    singleContratoMutationDados.mutate(data2, {
      onSuccess: () => {
        showToast({
          type: 'success',
          message: 'Dados atualizados com sucesso!'
        })
        closeModalEditar()
        reset({})
        setDisable2(false)
        setTimeout(function () {
          window.location.reload()
        }, 1000)
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        })
        setDisable2(false)
      }
    })
  }

  function handleChange(e) {
    const files = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')

      setUploadC(base64String)
      // Logs wL2dvYWwgbW9yZ...
    }
    reader.readAsDataURL(files)
  }
  return (
    <>
      <Button className="mr-xxxs" onClick={openModalEditar}>
            Fazer upload
          </Button>
      {data['ultima_conta_concessionaria'] ? (
        <>
          <Button
            className="mr-xxxs"
            onClick={(e) => {
              e.preventDefault()
              window.location.href = data['ultima_conta_concessionaria']
            }}
          >
            Visualizar ultima conta
          </Button>
          <Button disabled={disable} className="mr-xxxs" onClick={dadosOcr}>
            {disable && <span>Carregando</span>}
            {!disable && <span> Ler dados da conta</span>}
          </Button>
        </>
      ) : (
        <>
        </>
      )}
      <Modal
        isOpen={isOpen}
        onRequestClose={openModalEditar}
        width={600}
        label="Testando modal"
      >
        {response === '' ? (
          <>
            <form onSubmit={handleSubmit(editar)}>
              <input type="hidden" value={idContrato} {...register('id_contrato')} />
              <Box
                paddingType="square"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ borderBottom: 'none' }}
              >
                <Title size="body01" weight="bold" color="neutral800">
                  Fazer upload
                </Title>
              </Box>
              <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
                <Input
                  type="file"
                  label="Ultima conta"
                  name="ultima_conta_concessionaria"
                  {...register('ultima_conta_concessionaria', {
                    onChange: (e) => handleChange(e)
                  })}
                />
              </Box>
              <Box
                paddingType="squish"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ display: 'flex', borderTop: 'none' }}
              >
                <Button
                  className="mr-auto"
                  color="primary"
                  appearance="ghost"
                  onClick={() => {
                    closeModalEditar()
                  }}
                >
                  Cancelar
                </Button>
                <Button disabled={disable3} className="ml-auto">
                  {disable && <span>Carregando</span>}
                  {!disable && <span>Salvar</span>}
                </Button>
              </Box>
            </form>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(atualizarCampos)}>
              <input type="hidden" value={idContrato} {...register('id_contrato')} />
              <Box
                paddingType="square"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ borderBottom: 'none' }}
              >
                <Title size="body01" weight="bold" color="neutral800">
                  O ocr obeteve as seguintes informações
                </Title>
              </Box>
              <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              <Box>
                <Row>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Valor da ultima conta"   defaultValue={response?.valor} name="valorUltimaConta"  {...register('valorUltimaConta')} />
                  </Col>
                  <Col xs={12} md={6} className="mb-sm">
                    <Input label="Consumo médio KWH"  type="number" defaultValue={response?.consumo} name="consumoMedio"  {...register('consumoMedio')} />
                  </Col>
                </Row>
                <Row>
                <Col xs={12} md={6} className="mb-sm">
                  <Input label="Numero de instalação"   defaultValue={response?.instalacao} name="numeroInstalacao" type="text" {...register('numeroInstalacao')} />
                  </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Input label="Código do cliente"   defaultValue={response?.codCliente} name="codCliente" type="text" {...register('codCliente')} />
                </Col>
                </Row>
                <Row>
                <Col xs={12} md={6} className="mb-sm">
                  <Input label="Modalidade Tarifária"   defaultValue={response?.modalidade_tarifaria} name="tipoTarifa" type="text" {...register('tipo_tarifa')} />
                  </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Input label="Tipo de Conexão"   defaultValue={response?.tipo} name="tipoConexao" type="text" {...register('tipo_conexao')} />
                </Col>
                </Row>
              </Box>
            </Box>
              <Box
                paddingType="squish"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ display: 'flex', borderTop: 'none' }}
              >
                <Button
                  className="mr-auto"
                  color="primary"
                  appearance="ghost"
                  onClick={() => {
                    closeModalEditar()
                    window.location.reload()
                  }}
                >
                  Cancelar
                </Button>
                <Button disabled={disable2} className="ml-auto">
                  {disable && <span>Carregando</span>}
                  {!disable && <span> Salvar</span>}
                </Button>
              </Box>
            </form>
          </>
        )}
      </Modal>
    </>
  )
}

export default View
