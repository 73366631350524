import React, { useState } from 'react'
import { useMutation} from 'react-query'

import { Button, Box, Title, showToast } from '@superlogica/design-system'
import { LoginKey } from '@superlogica/design-system/Icons/superlogica'
import Modal from '@superlogica/design-system/Modal'
import { gerarToken } from 'services/administradoras/administradoras'

const View = ({ admData }) => {
  const [isOpenGerar, setIsOpenGerar] = useState(false)
console.log(admData)
  function openModalGerar() {
    setIsOpenGerar(true)
  }

  function closeModalGerar() {
    setIsOpenGerar(false)
  }
  const tokenAdmMutation = useMutation(gerarToken)

  function gerarTokenAdm(licenca) {
    tokenAdmMutation.mutate(licenca, {
      onSuccess: () => {
        closeModalGerar()
        showToast({
          type: 'success',
          message: 'Token gerado com sucesso'
        })
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        })
      }
    })
  }

  return (
    <>
      <Button
        onClick={openModalGerar}
        appearance="solid"
        size="sm"
        className="mr-xxs"
        leftIcon={ <LoginKey />}
      >
        Gerar Token
      </Button>

      <Modal
        isOpen={isOpenGerar}
        height="80vh"
        onRequestClose={openModalGerar}
        width="20vw"
        label="Testando modal"
      >
        <Box
          paddingType="square"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ borderBottom: 'none' }}
        >
          <Title size="display03" weight="bold" color="neutral800" align="center">
            Tem certeza que deseja gerar o token dessa Agência?
          </Title>
        </Box>
        <Box className="flex align-center">
          <Button
            appearance="solid"
            color="primary"
            size="md"
            className="mr-xxs"
            onClick={() => {
              gerarTokenAdm(admData.licenca)
            }}
          >                 
            Gerar
          </Button>
          <Button appearance="outline" color="primary" size="md" onClick={closeModalGerar}>
            Cancelar
          </Button>
        </Box>
        <Box
          paddingType="squish"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ display: 'flex', borderTop: 'none' }}
        ></Box>
      </Modal>
    </>
  )
}

export default View
