import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Button, Box, Title, showToast, Modal } from '@superlogica/design-system'
import { Bin1 } from '@superlogica/design-system/Icons/superlogica'
import { excluirCondominio } from 'services/condominios/condominios'

const View = ({ condoData }) => {
  const [isOpenExcluir, setIsOpenExcluir] = useState(false)
  const contratos = condoData.contratos
  function openModalExcluir() {
    setIsOpenExcluir(true)
  }

  function closeModalExcluir() {
    setIsOpenExcluir(false)
  }
  const excluirCondoMutation = useMutation(excluirCondominio)
  const queryClient = useQueryClient()
  let history = useHistory()

  function refreshAdmList() {
    queryClient.invalidateQueries(['administradoras'])
  }

  function deleteCondo(id) {
    excluirCondoMutation.mutate(id, {
      onSuccess: () => {
        refreshAdmList()
        showToast({
          type: 'success',
          message: 'Condominio excluido com sucesso'
        })
        history.push('/all-condominios')
      }
    })
  }

  return (
    <>
   { contratos.length < 1 ? 
      <Button
        onClick={openModalExcluir}
        appearance="solid"
        size="sm"
        className="mr-xxs"
        leftIcon={<Bin1 name="bin-1" />}
      >
        Excluir Condominio
      </Button>
    :"" }
      <Modal
        isOpen={isOpenExcluir}
        height="80vh"
        onRequestClose={openModalExcluir}
        width="20vw"
        label="Testando modal"
      >
        <Box
          paddingType="square"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ borderBottom: 'none' }}
        >
          <Title size="display03" weight="bold" color="neutral800" align="center">
            Tem certeza que deseja excluir esse condominio?
          </Title>
        </Box>
        <Box className="flex align-center">
          <Button
            appearance="solid"
            color="primary"
            size="md"
            className="mr-xxs"
            onClick={() => {
              deleteCondo(condoData.id)
            }}
          >
            Excluir
          </Button>
          <Button appearance="outline" color="primary" size="md" onClick={closeModalExcluir}>
            Cancelar
          </Button>
        </Box>
        <Box
          paddingType="squish"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ display: 'flex', borderTop: 'none' }}
        ></Box>
      </Modal>
    </>
  )
}

export default View
