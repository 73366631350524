import { Chip } from '@superlogica/design-system'

function pickColor(statusName) {
  let color = 'neutral800'

  if (statusName.match(/gerad(o|a)/gi)) {
    color = 'neutral200'
  }

  if (statusName.match(/pag(o|a)/gi)) {
    color = 'success02'
  }

  if (statusName.match(/atrasad(o|a)/gi)) {
    color = 'danger03'
  }

  if (statusName.match(/cancel(o|a)/gi)) {
    color = 'warning02'
  }


  return color
}

function StatusCobranca({ fatura }) {

  return <Chip label={fatura.status_cobranca} color={pickColor(fatura.status_cobranca)} />
}

export default StatusCobranca
