
import View from './View'

const Container = ({ data, uuId }) => {
  const idContrato = uuId
  return (
    <View
      title="Gerar termo"
      idContrato={idContrato}
      data={data}
    />
  )
}

export default Container
