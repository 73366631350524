import { api } from 'services/api'

export const getClientes = async (nome, page = 1, sort) => {
  const url = 'clientes'

  const params = {
    page
  }
  if (typeof nome === 'string' && nome !== '') {
    params['nome_completo'] = nome
  }

  if (sort?.direction !== 'none' && sort?.accessor !== '') {
    params['column'] = sort.accessor
    params['order'] = sort.direction
  }

  const response = await api.get(url, { params })

  return response.data
}

export const getClientesParams = async (param, value) => {
  let url = `clientes/listAllClientes?${param}=${value}`

  const response = await api.get(url)

  return response.data.data
}
