import { colors, spacing } from '@superlogica/foundation/tokens'
import styled from 'styled-components'

const Line = styled('div')`
  width: 100%;
  height: 2px;
  background: ${colors.neutral100};
  margin-top: ${spacing.vertical.xs};
  margin-bottom: ${spacing.vertical.xs};
`
export default Line
