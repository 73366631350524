import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Button, Modal, Title, Box } from '@superlogica/design-system';
import { showToast } from '@superlogica/design-system';
import { importar } from 'services/faturas/faturas';
import { gerar } from 'services/faturas/faturas';
import './styles.css';

const View = ({ refreshFatura }) => {
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const [uploadC, setUploadC] = useState('');
  const [isOpenInputModal, setIsOpenInputModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const gerarContratoMutation = useMutation(importar);
  const gerarFaturastoMutation = useMutation(gerar);

  function gerarFatura(data) {
    const dataa = {
      arquivo: uploadC,
    };

    gerarContratoMutation.mutate(dataa, {
      onSuccess: (data) => {
        showToast({
          type: 'success',
          message: data.message,
        });
        reset({});
        // Define os dados do CSV retornado pela rota
        setCsvData(data.text); // Substitua "csvData" pelo nome correto da chave no JSON retornado
        setIsOpenInputModal(false); // Fecha a modal de upload
        setIsOpen(true)
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message,
        });
      },
    });
  }


  function gerarFaturas(data) {
    const index = {
      faturas: csvData,
    };
    gerarFaturastoMutation.mutate(index, {
      onSuccess: (data) => {
        showToast({
          type: 'success',
          message: data.message,
        });
        reset({});
        setIsOpen(false)
        refreshFatura()
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message,
        });
      },
    });
  }

  function handleChange(e) {
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // Use a regex to remove data URL part
      const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');

      setUploadC(base64String);
    };
    reader.readAsDataURL(files);
  }

  return (
    <div>
      <Button className="ml-xxs" onClick={() => setIsOpenInputModal(true)}>Importar Faturas</Button>

      <Modal
        isOpen={isOpenInputModal}
        onRequestClose={() => setIsOpenInputModal(false)}
        width={600}
        label="Testando modal"
      
      >
        <Box>
        <form onSubmit={handleSubmit(gerarFatura)}>
        <Title size="body01" weight="bold" color="neutral800">
              Fazer upload
            </Title>
          <Box>
          <input
            type="file"
            label="Importar"
            name="import"
            {...register('arquivo', {
              onChange: (e) => handleChange(e),
            })}
          />
          </Box>
          <div style={{ display: 'flex' }}>
            <Button
              className="mr-auto"
              color="primary"
              appearance="ghost"
              onClick={() => setIsOpenInputModal(false)}
            >
              Cancelar
            </Button>
            <Button className="ml-auto" type="submit" color="primary">
              Importar
            </Button>
          </div>
        </form>
        </Box>
      </Modal>

      {csvData.length > 0 && (
        <Modal
          isOpen={isOpen}
          width={1300}
          label="Dados do CSV"
          className="custom-modal"
        >
          <form onSubmit={handleSubmit(gerarFaturas)}>
          <div className="custom-table-container">
     
           <Box>
           <h2>Dados do CSV:</h2>
           </Box>
            <div className="custom-table">
              <table>
                <thead>
                  <tr>
                    <th>Nome do Consumidor</th>
                    <th>Referência</th>
                    <th>Documento</th>
                    <th>Número de Instalação</th>
                    <th>Valor Distribuidora</th>
                    <th>Valor Assinatura </th>
                    <th>Valor Total</th>
                    <th>Data de Vencimento</th>
                    <th>Consumo Total(kWh)</th>
                    <th>Consumo (kWh) HP</th>
                    <th>Energia Compensada (kWh)</th>
                    <th>Economia</th>
                  </tr>
                </thead>
                <tbody className="custom-tbody">
                  {csvData.map((item, index) => (
                    <tr key={index}>
                      <td>{item['Nome do Consumidor']}</td>
                      <td>{item['Referência']}</td>
                      <td>{item['Documento']}</td>
                      <td>{item['Número de Instalação']}</td>
                      <td>{item['Valor Distribuidora']}</td>
                      <td>{item['Valor Assinatura']}</td>
                      <td>{item['Valor Distribuidora + Assinatura']}</td>
                      <td>{item['Data de Vencimento']}</td>
                      <td>{item['Consumo (kWh)']}</td>
                      <td>{item['Consumo (kWh) HP']}</td>
                      <td>{item['Energia Compensada (kWh)']}</td>
                      <td>{item['Economia']}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
          </div>
          <div style={{ display: 'flex', backgroundColor: '#fff', padding: '15px' , paddingBottom: '10px'}}>
            <Button
              className="mr-auto"
              color="primary"
              appearance="ghost"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </Button>
            <Button className="ml-auto" type="submit" color="primary">
              Gerar faturas
            </Button>
          </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default View;
