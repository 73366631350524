import React from 'react'

const Pagination = ({ totalItems, itemsPerPage, setCurrentPage, currentPage }) => {
  const pages = Math.ceil(totalItems / itemsPerPage)

  let leftSide = currentPage - 2
  if (leftSide <= 1) leftSide = 1

  let rightSide = currentPage + 2
  if (rightSide > pages) rightSide = pages

  const nextPage = () => {
    if (currentPage < pages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  let items = []
  for (let number = leftSide; number <= rightSide; number++) {
    items.push(
      <button
        key={number}
        className={number === currentPage ? 'round-effect active' : 'round-effect'}
        onClick={() => {
          setCurrentPage(number)
        }}
      >
        {number}
      </button>
    )
  }

  return (
    <div className="flex-container">
      <div className="paginate-ctn">
        <div className="round-effect" onClick={prevPage}>
          &lsaquo;
        </div>

        <div>{items}</div>
        <div className="round-effect" onClick={nextPage}>
          &rsaquo;
        </div>
      </div>
    </div>
  )
}

export default Pagination
