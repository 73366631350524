import axios from 'axios'
import keycloakClient from 'navigation/Auth/Keycloak'

import config from '../config.js'

console.log(config.baseUrl)

export const api = axios.create({
  baseURL: config.baseUrl
})

api.interceptors.request.use((config) => {
  const token = keycloakClient.token
  const authorizationHeader = { Authorization: `Bearer ${token}` }
  config.headers = { ...config.headers, ...authorizationHeader }

  return config
})
