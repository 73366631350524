import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Chip } from '@superlogica/design-system'
import { AlarmBell, Search } from '@superlogica/design-system/Icons/superlogica'

import View from './View'

const Container = ({ data }) => {
  const exemplo = {
    Ativo: 'success02',
    Pendente: 'warning02',
    Cancelado: 'danger03'
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      Header: 'Código', // texto q será exibido no cabeçalho da tabela
      accessor: 'codigo_do_consumidor', // id das células dessa coluna
      align: 'left' //alinha o texto dentro da célula da tabela
    },
    {
      Header: 'Documento',
      accessor: 'id_cliente.cpf',
      align: 'left'
    },
    {
      Header: 'Cooperativa',
      accessor: 'id_cooperativa.nome',
      align: 'left'
    },
    {
      Header: 'Status',
      accessor: 'id_contratoStatus.nome',
      align: 'left',
      Cell: ({ row }) => (
        <Chip
          label={row.values['id_contratoStatus.nome']}
          color={exemplo[row.values['id_contratoStatus.nome']]}
          leftIcon={<AlarmBell />}
        />
      )
    },
    {
      Header: '',
      accessor: 'icon',
      align: 'center',
      Cell: ({ row }) => {
        const contratoId = data[row.index].uuId
        return (
          <Link to={`../single-contrato/${contratoId}`}>
            <Search height="16" width="16" />
          </Link>
        )
      }
    }
  ])

  const tableDataContrato = useMemo(() => data, [data])

  return (
    <View
      title="View Lista Contratos Cliente"
      tableDataContrato={tableDataContrato}
      columns={columns}
    />
  )
}

export default Container
