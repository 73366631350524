import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Box, Button, Modal, Select, showToast, Title } from '@superlogica/design-system'
import { alterarStatus} from 'services/faturas/fatura/atualizarStatus'


const View = ({ uuId,data }) => {
  const { register, handleSubmit, reset } = useForm()
  const [selectValue, setSelectValue] = useState('2', 'Ativo')
  const [isOpen, setIsOpen] = useState(false)
  const openModalStatus = () => setIsOpen(true)
  const closeModalStatus = () => setIsOpen(false)

  const options = [
    { value: 'Cancelada', name: 'Cancelada' },
    { value: 'Fatura enviada', name: 'Fatura enviada' },
    { value: 'Fatura Paga', name: 'Fatura Paga' }
  ]

  function handleChange(e) {
    setSelectValue(e.target.value, e.target.name)
    }

  const atualizarStatusMutation = useMutation(alterarStatus)
  function atualizarStatus(data) {
    atualizarStatusMutation.mutate(data, {
      onSuccess: () => {
        showToast({
          type: 'success',
          message: 'Status alterado com sucesso'
        })
        closeModalStatus()
        reset({})
      }
    })
  }

  return (
    <>
      <Button appearance="outline" size="sm" className="ml-auto" onClick={openModalStatus}>
        Editar status
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModalStatus}
        width={800}
      >
        <form onSubmit={handleSubmit(atualizarStatus)}>
          <input type="hidden" name="id" value={uuId} {...register('id')} />
          <Box
            paddingType="square"
            paddingSize="sm"
            border="xs"
            borderColor="neutral100"
            style={{ borderBottom: 'none' }}
          >
            <Title size="body01" weight="bold" color="neutral800">
              Editar Status da fatura
            </Title>
          </Box>
          <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              <Select
                size="sm"
                label="Selecione uma opção de status"
                name="Teste"
                value={selectValue}
                {...register('statusFatura')}
                onChange={handleChange}
                options={options}
              />
          </Box>
         
          <Box
            paddingType="squish"
            paddingSize="sm"
            border="xs"
            borderColor="neutral100"
            style={{ display: 'flex', borderTop: 'none' }}
          >
            <Button
              className="mr-auto"
              color="primary"
              appearance="ghost"
              onClick={closeModalStatus}
            >
              Cancelar
            </Button>

            <Button className="ml-auto">Salvar</Button>
          </Box>
        </form>
      </Modal>
    </>
  )
}

export default View
