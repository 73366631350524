import React, { useMemo } from 'react'

import View from './View'

const Container = ({ data, uuId, status }) => {
  const textContrato = useMemo(() => data, [data])

  return <View title="Dados Cliente Contrato" data={textContrato} uuId={uuId} status={status}/>
}

export default Container
