import React, {useState, useEffect } from 'react'
import { Col, Row } from 'react-awesome-styled-grid'
import { CSVLink } from 'react-csv'
import { useQuery } from 'react-query'

import { Box, Text } from '@superlogica/design-system'
import { Button, Modal, Title, Select, Datepicker } from '@superlogica/design-system'
import { DownloadBottom } from '@superlogica/design-system/Icons/superlogica'
import moment from 'moment';
import { getStatus } from 'services/contratos/contrato/atualizarStatus'
import { getCooperativas } from 'services/contratos/contrato/atualizarStatus'
import { getAdministradoras } from 'services/contratos/contrato/atualizarStatus'
import { getDistribuidoras } from 'services/contratos/contrato/atualizarStatus'
import { getContratosExportar } from 'services/contratos/getContratosExportar'
import { BoxMenu } from 'styles/global-style'


const ReactCSV = () => {
  const { data: status = [] } = useQuery(['editarStatus'], () => getStatus())
  const { data: cooperativa = [] } = useQuery('cooperativas', () =>getCooperativas())
  const { data: distribuidoras = [] } = useQuery(['distribuidoras'], () => getDistribuidoras())
  const { data: administradoras = [] } = useQuery(['administradoras'], () => getAdministradoras())

  const [selectValue, setSelectValue] = useState('0')
  const [selectValueCoop, setSelectValueCoop] = useState('0')
  const [selectValueDis, setSelectValueDis] = useState('0')
  const [selectValueAdm, setSelectValueAdm] = useState('0')
  const [startDate, setStartDate] = useState(new Date("2022-05-01T03:24:00"))
  const [finalDate, setFinalDate] = useState(new Date())  
  const [disable, setDisable] = useState(false)  
  const [disable2, setDisable2] = useState(true)  

  const dataInicial = startDate
  const dataFinal = finalDate

  const [generateReport, setGenerateReport] = useState(false);
  const [reportData, setReportData] = useState([]);

 // Função para gerar o relatório
 const generateReportData = async () => {
  const data = await getContratosExportar(
    selectValue,
    dataInicial,
    dataFinal,
    selectValueCoop,
    selectValueDis,
    selectValueAdm
  );
  setReportData(data);
  setGenerateReport(false); // Reset para evitar chamadas subsequentes
};

useEffect(() => {
  if (generateReport) {
    generateReportData();
  }
});

  const optionsStatus = populateOptionsStatus(status)
  const optionsCoop = populateOptionsCoop(cooperativa)
  const optionsDis = populateOptionsDis(distribuidoras)
  const optionsAdm = populateOptionsAdm(administradoras)

  function populateOptionsStatus(status) {
    const array1 = status.map((item) => ({
       name: item.nome,
       value: item.id
     }))
 
     const array2 = [].concat(array1);
 
     array2.push({name: "Selecione um status", value: 0});
     return   array2.sort((a,b) => a.value - b.value)
  }

  function populateOptionsCoop(cooperativa) {
  const array1 = cooperativa.map((item) => ({
      name: item.nome,
      value: item.id
    }))

    const array2 = [].concat(array1);

    array2.push({name: "Selecione uma cooperativa", value: 0});
    return   array2.sort((a,b) => a.value - b.value)
    }

       
  function populateOptionsDis(distribuidoras) {
    const array1 = distribuidoras.map((item) => ({
      name: item.nome,
      value: item.id
    }))

    const array2 = [].concat(array1);

    array2.push({name: "Selecione uma distribuidora", value: 0});
    return   array2.sort((a,b) => a.value - b.value)
  }


         
  function populateOptionsAdm(administradoras) {
    const array1 = administradoras.map((item) => ({
      name: item.licenca,
      value: item.id
    }))

    const array2 = [].concat(array1);

    array2.push({name: "Selecione uma administradora", value: 0});
    return   array2.sort((a,b) => a.value - b.value)
  }


  function handleGenerateReport() {
    setGenerateReport(true);
    setDisable(true);
    setDisable2(false)
    // O useQuery será ativado devido à atualização do estado generateReport
  }

function handleChange(e) {
    setSelectValue(e.target.value);
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 8000);

  }

  function handleChangeCop(e) {
    setSelectValueCoop(e.target.value);
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 8000);

  }

  function handleChangeDis(e) {
    setSelectValueDis(e.target.value);
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 8000);

  }

  function handleChangeAdm(e) {
    setSelectValueAdm(e.target.value);
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 8000);

  }



  var dataEncaminharCoop = "";
  var dataEnviarCoop = "";
  var dataAguardando = "";
  var dataAdesao = "";
  
  const listaExportar2 = reportData.map(row => ({...row, 
    data_atualizacao_contrato: moment(row.data_atualizacao_contrato).format("YYYY-MM-DD"),
    datanasc: row.id_cliente.data_nascimento ? moment(row.id_cliente.data_nascimento).format("YYYY-MM-DD") : "",
    array: row.contratoHistoricos.map(role => (role.descricao === "Status do Contrato atualizado para:Encaminhado a Cooperativa!" ? dataEncaminharCoop = role.data : "")
    ),
    array2: row.contratoHistoricos.map(role => (role.descricao === "Status do Contrato atualizado para:Enviar à cooperativa!" ? dataEnviarCoop = role.data : "")
    ),
    array3: row.contratoHistoricos.map(role => (role.descricao === "Status do Contrato atualizado para:Aguardando cliente!" ? dataAguardando = role.data : "")
    ),
    array4: row.contratoHistoricos.map(role => (role.descricao === "Status do Contrato atualizado para:Adesão incompleta!" ? dataAdesao = role.data : "")
    ),
    dataEncaminhar: dataEncaminharCoop,
    dataEnviar: dataEnviarCoop,
    dataAguardando: dataAguardando,
    dataAdesao:dataAdesao
  }))

  const [isOpen, setIsOpen] = useState(false)
  const headers = [
    { label: 'Id contrato', key: 'id' },
    { label: 'Nome completo', key: 'id_cliente.nome_completo' },
    { label: 'CPF', key: 'id_cliente.cpf' },
    { label: 'Email', key: 'id_cliente.email' },
    { label: 'RG', key: 'id_cliente.rg' },
    { label: 'Orgão emissor', key: 'id_cliente.orgaoEmissor' },
    { label: 'Data de nascimento', key: 'datanasc' },
    { label: 'Estado Civil', key: 'id_cliente.estado_civil' },
    { label: 'Profissão', key: 'id_cliente.profissao' },
    { label: 'Nacionalidade', key: 'id_cliente.nacionalidade' },
    { label: 'Telefone', key: 'id_cliente.telefone' },
    { label: 'Endereço', key: 'endereco' },
    { label: 'Complemento', key: 'complemento' },
    { label: 'UF', key: 'uf' },
    { label: 'Cidade', key: 'cidade' },
    { label: 'Bairro', key: 'bairro' },
    { label: 'Cep', key: 'cep' },
    { label: 'Tipo contrato', key: 'tipoContrato' },
    { label: 'Tipo tarifa', key: 'tipo_tarifa' },
    { label: 'Tipo conexão', key: 'tipo_conexao' },
    { label: 'Codigo do consumidor', key: 'codigo_do_consumidor' },
    { label: 'Consumo médio', key: 'consumoMedio' },
    { label: 'Numero de instalação', key: 'numeroInstalacao' },
    { label: 'Valor da utima conta', key: 'valorUltimaConta' },
    { label: 'Hash Unidade', key: 'hash_unidade' },
    { label: 'Forma de pagamento', key: 'meio_pagamento' },
    { label: 'Vencimento do boleto', key: 'vencimento_boleto' },
    { label: 'Valor da utima conta (clientes)', key: 'id_cliente.valor_ultima_conta' },
    { label: '% desconto', key: 'porcentagemDesconto' },
    { label: 'Condominio', key: 'id_condominio.nome' },
    { label: 'CNPJ Condominio', key: 'id_condominio.cnpj' },
    { label: 'Administradora', key: 'id_condominio.id_administradora.licenca' },
    { label: 'Distribuidora', key: 'id_distribuidora.nome' },
    { label: 'Timestamp do cadastro', key: 'id_cliente.criado_em' },
    { label: 'IP do cliente', key: 'ip_cliente' },
    { label: 'Aceite de termos Esfera', key: 'termoEsfera' },
    { label: 'Data da assinatura do contrato', key: 'data_assinatura_contrato' },
    { label: 'Data de atualização do contrato', key: 'data_atualizacao_contrato' },
    { label: 'Data encaminhado a cooperativa', key: 'dataEncaminhar' },
    { label: 'Data Enviar a cooperativa', key: 'dataEnviar' },
    { label: 'Data Aguardando cliente', key: 'dataAguardando' },
    { label: 'Data Adesão incompleta', key: 'dataAdesao' },
    { label: 'Termo', key: 'termosCooperativa' },
    { label: 'Conta', key: 'ultima_conta_concessionaria' },
];

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
  return   (
    <Box paddingSize="none" className=" ">
    <BoxMenu>
      <button
        style={{ backgroundColor: 'transparent', width: '100%', color: '#fff', border: 'none' }}
        onClick={openModal}
      >
          <DownloadBottom width="24" height="24" fill="neutral0" className="mb-xxxxs" />
          <Text className="mr-xxxs ml-xxxs" size="body02"> Exportar</Text>
          </button>
      </BoxMenu>
      <Modal isOpen={isOpen} onRequestClose={openModal} width={600} label="Testando modal">
        <Box
          paddingType="square"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ borderBottom: 'none' }}
        >
          <Title size="body01" weight="bold" color="neutral800">
            Exportar Clientes
          </Title>
        </Box>
        <div style={{ maxHeight: '90vh' }}>
          <div>
            <Row className="mx-sm mt-md">
              <Col xs={12} md={6} className="mb-sm">
                <Datepicker
                  className="dt_inicial"
                  label="Data inicial"
                  name="data_inicial"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    setDisable(true)
                    setTimeout(() => {
                      setDisable(false);
                    }, 5000);
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mb-sm">
                <Datepicker
                  label="Data final"
                  name="data_final"
                  selected={finalDate}
                  onChange={(date) => {
                    setFinalDate(date)
                    setDisable(true)
                    setTimeout(() => {
                      setDisable(false);
                    }, 5000);
                  }}
                />
              </Col>
            </Row>
            <Row className="mx-sm">
              <Col xs={12} md={6} className="">
              <Select
                  size="sm"
                  label="Cooperativa"
                  className="mb-xs"
                  name="Cooperativa"
                  value={selectValueCoop}
                  onChange={handleChangeCop}
                  options={optionsCoop}
                />
              </Col>
              <Col xs={12} md={6} className="">
              <Select
                  size="sm"
                  label="Status do contrato"
                  className="mb-xs"
                  name="Selecionae um status"
                  value={selectValue}
                  onChange={handleChange}
                  options={optionsStatus}
                />
              </Col>
            </Row>
            <Row className="mx-sm ">
              <Col xs={12} md={6} className="mb-xs">
              <Select
                  size="sm"
                  label="Distribuidora"
                  className="mb-xs"
                  name="Distribuidora"
                  value={selectValueDis}
                  onChange={handleChangeDis}
                  options={optionsDis}
                 />     
              </Col>
              <Col xs={12} md={6} className="mb-xs">
                <Select
                  size="sm"
                  label="Administradora"
                  className="mb-xs"
                  name="S"
                  value={selectValueAdm}
                  onChange={handleChangeAdm}
                  options={optionsAdm}
                />
              </Col>
            </Row>    
          </div>
          <Box
            paddingType="squish"
            paddingSize="sm"
            border="xs"
            borderColor="neutral100"
            style={{ display: 'flex', borderTop: 'none' }}
          >
                <Button
            className="mr-auto"
            color="primary"
            appearance="ghost"
            onClick={closeModal}
          >
            Cancelar
          </Button>
          {disable2 ? 
            <Button
              className="button mr-alto align-middle px-xs"
              style={{
                backgroundColor: 'blue',
                color: 'white',
                borderRadius: '4px',
                alignSelf: 'center',
                padding: '11px',
                marginRight:'5px'
              }}
              onClick={handleGenerateReport} // Ativar a geração do relatório
            >
              <span>Gerar relatório</span>
          </Button>
          : ""
          }
  
          {disable2 && <span></span>}
          {!disable2 &&    
          <CSVLink
              headers={headers}
              disabled = {disable2}
              className="button ml-alto align-middle px-xs "
              style={{
                backgroundColor: 'blue',
                color: 'white',
                opacity: disable2 ? 0.5 : 1,
                borderRadius: '4px',
                alignSelf: 'center',
                padding: '8px'
              }}
              data={listaExportar2}
            >
              {disable ? <span>Carregando</span> :  <span>Exportar</span>}
             </CSVLink>}
          </Box>
        </div>
      
      </Modal>
    </Box>
  )
}

export default ReactCSV