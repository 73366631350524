import React, { useState } from 'react'
import { Col, Row } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { Box, Button, Modal, Select, showToast, Title, Text,Input, Table } from '@superlogica/design-system'
import { Search } from '@superlogica/design-system/Icons/superlogica'
import Pagination from 'components/Pagination/Pagination'
import { alterarStatusLote} from 'services/contratos/contrato/atualizarStatus'
import { BoxTabTable, BoxTitleTable } from 'styles/global-style'



const View = ({ tableData, isError, isLoading, refetch, columns, setSearchContratoValue
, setSearchContratoValue3, status, adms, totalItems, itemsPerPage,setCurrentPage,currentPage,manualSort,allchecked
  }) => {

  const { register, handleSubmit, reset } = useForm()
  const optionsAdms = populateOptionsAdm(adms)
  const [selectAdm, setSelectAdm] = useState()
  const [selectValue, setSelectValue] = useState('2', 'Ativo')
  const [selectValue2, setSelectValue2] = useState('Potência Mínima')
  const [isVisible, setisVisible] = useState(false)
  const [isVisible2, setisVisible2] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const openModalStatus = () => setIsOpen(true)
  const closeModalStatus = () => setIsOpen(false)

  function populateOptionsAdm(adms) { 
    return  adms.map((item) => ({
      name: item.razao_social,
      value: item.id
    }))
  }
  function pesquisar() {
    setSearchContratoValue3(selectAdm)
  }
  
  const optionsStatus = populateOptionsStatus(status)
  function populateOptionsStatus(status) {
    return status.map((item) => ({
      name: item.nome,
      value: item.id
    }))
  }
  function handleChange(e) {
    setSelectValue(e.target.value, e.target.name)
    if(e.target.value === "12" ){
      setisVisible(true)  
    }else{
      setisVisible(false)  
    }

    if(e.target.value === "2"){
      setisVisible2(true)
    }else{
      setisVisible2(false)  
    }


  }

  function handleChange2(e) {
    setSelectValue2(e.target.value)
  }
  const atualizarStatusMutation = useMutation(alterarStatusLote)
  function atualizarStatus(data) {
    data.id = allchecked
    atualizarStatusMutation.mutate(data ,{
      onSuccess: () => {
        showToast({
          type: 'success',
          message: 'Status alterado com sucesso'
        })
        setTimeout(function(){
          window.location.reload();
     }, 2000); 
        closeModalStatus()
        reset({})
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: "Nenhum contrato foi selecionado"
        })
      }
    })
  }

  const optionsMotivo = [
    { value: 'Potência Mínima', name: 'Potência Mínima' },
    { value: 'Já possui contrato de GD', name: 'Já possui contrato de GD' },
    { value: 'Sem conta anexada e ou Termos não aceito', name: 'Sem conta anexada e ou Termos não aceito' },
    { value: 'Cliente com subsídio', name: 'Cliente com subsídio' },
    { value: 'Inadimplente', name: 'Inadimplente' },
    { value: 'Titularidade divergente', name: 'Titularidade divergente' }
  ]

  const optionsMotivoCanc = [
    { value: 'Insatisfação com o prazo de entrega do benefício', name: 'Insatisfação com o prazo de entrega do benefício' },
    { value: 'Contratei outra Empresa GD ', name: 'Contratei outra Empresa GD ' },
    { value: 'Mudança de residência', name: 'Mudança de residência' },
    { value: 'Insatisfação com o atendimento ao cliente ', name: 'Insatisfação com o atendimento ao cliente ' },
    { value: 'Expectativas não atendidas', name: 'Expectativas não atendidas' },
    { value: 'Outro motivo', name: 'Outro motivo' },
    { value: 'Desistência', name: 'Desistência' }
  ]


  return (
    <>
      <Box paddingSize="none" className="">
      <Text className="mr-xxxs mb-xs">Filtros</Text>
        <Row className="">
          <Col xs={12} md={3} className="mb-sm">
            <Input
              className=""
              name="search"
              type="text"
              label="Digite o nome ou CPF"
              onChange={(e) => {
                setSearchContratoValue(e.target.value)
              }}
              rightIcon={<Search name="search" />}
            />
          </Col>
          <Col xs={12} md={3} className="mb-sm">
            <Select
              label="Administradoras"
              className=""
              name="selec adm"
              onChange={(e) => {
                setSelectAdm(e.target.value)
              }}
              options={optionsAdms}
            />
          </Col>
          <Box paddingSize="none" className="mb-xs flex align-center">
            <Button
              className="ml-xs"
              color="primary"
              onClick={pesquisar}>
                Pesquisar
            </Button>
          </Box>
        </Row>
      </Box>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xs"
        radius="md"
        className="flex align-center"
      >
        <BoxTitleTable paddingSize="none">
          <Text className="mr-xxxs">Contratos</Text>
          <Text color="neutral400" size="body02">
          {`${tableData.length} itens`}
          </Text>
        </BoxTitleTable>
        <Box paddingSize="none" className="ml-auto flex align-center">
          <Button onClick={openModalStatus} className="mr-xxxs">
            Atualizar status contratos
          </Button>
        </Box>
      </BoxTabTable>
      <Box border borderColor="neutral300" bgColor="neutral0" paddingSize="none" radius="xs">
        <Table
          loading={isLoading}
          error={isError}
          data={tableData}
          columns={columns}
          tableName="contratos"
          retry={refetch}
          manualSort={manualSort}
        />
      </Box>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xxs"
        radius="md"
        className="flex align-center"
      >
        <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </BoxTabTable>
      <Box paddingSize="none" className="mb-md">
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModalStatus}
        width={800}
        label="Testando modal"
      >
        <form onSubmit={handleSubmit(atualizarStatus)}>
          <Box
            paddingType="square"
            paddingSize="sm"
            border="xs"
            borderColor="neutral100"
            style={{ borderBottom: 'none' }}
          >
            <Title size="body01" weight="bold" color="neutral800">
              Editar Status do Contrato
            </Title>
          </Box>
          <Box  borderColor="neutral100">
            <Box>
              <Select
                className="mb-xs"
                size="sm"
                label="Selecione uma opção de status"
                name="Teste"
                value={selectValue}
                {...register('id_contratoStatus')}
                onChange={handleChange}
                options={optionsStatus}
              />
            </Box>

            {isVisible ? 
            <Box>
              <Select
                size="sm"
                label="Selecione uma opção do motivo"
                name="Teste"
                value={selectValue2}
                {...register('status_descricao')}
                onChange={handleChange2}
                options={optionsMotivo}
              />
            </Box> : ""}

            {isVisible2 ? 
            <Box>
                <Select
                size="sm"
                label="Selecione uma opção do motivo"
                name="Teste"
                className="mb-md"
                value={selectValue2}
                {...register('status_descricao')}
                onChange={handleChange2}
                options={optionsMotivoCanc}
              />
              <Input
                size="sm"
                label="Descreva"
                {...register('status_descricao_cancelamento')}
              />
            </Box> : ""}

          </Box>
            <Box>
              <Text size="body01"  className="ml-auto">
             Você está modificando o status de {allchecked.length} contratos.
            </Text>
            </Box>
         
          <Box
            paddingType="squish"
            paddingSize="sm"
            border="xs"
            borderColor="neutral100"
            style={{ display: 'flex', borderTop: 'none' }}
          >
            <Button
              className="mr-auto"
              color="primary"
              appearance="ghost"
              onClick={closeModalStatus}
            >
              Cancelar
            </Button>

            <Button className="ml-auto">Salvar</Button>
          </Box>
        </form>
      </Modal>
      </Box>
    </>
  )
}

export default View
