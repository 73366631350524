import { useCallback } from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Redirect, useLocation } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'
import { Button } from '@superlogica/design-system'

import './style.css'

const LoginView = () => {
  const location = useLocation()
  const currentLocationState = location.state || {
    from: { pathname: '/app' }
  }

  const { keycloak } = useKeycloak()

  const login = useCallback(() => {
    keycloak?.login()
  }, [keycloak])

  if (keycloak?.authenticated && keycloak.hasRealmRole('backoffice-energia-user'))
    return <Redirect to={currentLocationState?.from} />

  return (
    <Container>
      <Row>
        <Col xs={3} sm={5} lg={4} offset={{ xs: 1, sm: 1, lg: 4 }} className="box-login">
          <Row>
            <Col xs={12} sm={12} lg={12}>
              <div className="img-top-login">
                <img src="../superlogica-logo.png" alt="logo-suprtlogica" />
              </div>
            </Col>
          </Row>
          <Row className="mt-lg">
            <Col xs={12} sm={12} lg={12}>
              <Button
                onClick={login}
                color="primary"
                appearance="solid"
                marginBottom="lg"
                id="saveChanges"
                role="button"
                size="md"
                fluid="true"
              >
                Login
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginView
