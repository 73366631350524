import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';

import { get_faturas } from 'services/faturas/faturas';

const ListaExportacaoCSV = () => {
 const [data, setData] = useState([]);
 const [disable, setDisable] = useState(true)  
 const { data: faturasData } = useQuery('faturas', () => get_faturas());


 useEffect(() => {
   if (faturasData) {
     setData(faturasData);
     setTimeout(() => {
      setDisable(false);
    }, 8000);
   }
 }, [faturasData]);


    const headers = [
      { label: 'Nome completo', key: 'id_contrato.id_cliente.nome_completo' },
      { label: 'CPF', key: 'id_contrato.id_cliente.cpf' },
      { label: 'Email', key: 'id_contrato.id_cliente.email' },
      { label: 'Valor', key: 'valor' },
      { label: 'vencimento', key: 'vencimento' },
      { label: 'status_cobranca', key: 'status_cobranca' },
      { label: 'link_boleto', key: 'link_boleto' },
      { label: 'data_geracao_fatura', key: 'data_geracao_fatura' },
      { label: 'desconto', key: 'desconto' },
      { label: 'mes_referencia', key: 'mes_referencia' },
      { label: 'Endereço', key: 'id_contrato.endereco' },
      { label: 'Complemento', key: 'id_contrato.complemento' },
      { label: 'UF', key: 'id_contrato.uf' },
      { label: 'Cidade', key: 'id_contrato.cidade' },
      { label: 'Bairro', key: 'id_contrato.bairro' },
      { label: 'Cep', key: 'id_contrato.cep' },
      { label: 'Codigo do consumidor', key: 'id_contrato.codigo_do_consumidor' },
      { label: 'Numero de instalação', key: 'id_contrato.numeroInstalacao' },
      { label: 'Condominio', key: 'id_contrato.id_condominio.nome' },
      { label: 'Administradora', key: 'id_contrato.id_condominio.id_administradora.licenca' },
      { label: 'Distribuidora', key: 'id_contrato.id_distribuidora.nome' },
  ];
  return (
    disable ? "" :
    <div style={{ marginTop: '8px'}}>
        
     <CSVLink
              headers={headers}
              disabled = {disable}
              className="button  "
              style={{
                backgroundColor: 'blue',
                color: 'white',
                opacity: disable ? 0.5 : 1,
                borderRadius: '4px',
                padding: '12px',
                marginLeft: '15px',
              }}
              data={data}
            >
              Exportar
           
             </CSVLink>
             
    </div> 
  );
};

export default ListaExportacaoCSV;