//import { valueToCurrency } from '../utils/currency'

const masks = {
  phone: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2')
      .substr(0, 15)
  },
  lowercase: (value) => value.toLowerCase(),
  cpf: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
      .substr(0, 14)
  },
  cnpj: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  },
  money: (value) => {
    return value
      .replace('.', ',')
      .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  },
  real: (value) => {
    return '$' + value.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '')
  },
  rg: (value) => {
    return value.replace(/\D/g, '').replace(/(\d{7})(\d{1})/, '$1-$2')
  }
}

export default masks
