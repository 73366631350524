import React from 'react'

import { Title } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { BoxContainer } from 'styles/global-style'

import ListContratos from './ListaContratos/Container'
import  NovoContrato from './NovoContrato/View'


const AllContratos = () => {
  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer  paddingSize="lg">
        <Title color="neutral800" size="display03" className="mb-xs">
          Contratos PJ
        </Title>
        <NovoContrato />
          

        {/* <SearchContratos /> */}
        <ListContratos 
        // vari={inputValue}
         />
      </BoxContainer>
    </>
  )
}

export default AllContratos