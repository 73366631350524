import React, { forwardRef, useState, useRef, useEffect } from 'react'

import { Input, Text } from '@superlogica/design-system'
import { useDebouncedCallback } from 'use-debounce'

import { OptionsBox, Option } from './styles'

const Autocomplete = forwardRef(
  (
    {
      label,
      value,
      items,
      onChange,
      onFocus,
      onBlur,
      fields,
      getItems,
      onOptionClicked,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState(value || '')
    const debouncedGetItems = useDebouncedCallback((value) => {
      getItems(value)
    }, 300)
    const itemRef = useRef()

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (itemRef.current && !itemRef.current.contains(event.target)) {
          setIsOpen(!isOpen)
        }
      }
      if (isOpen) {
        window.addEventListener('mousedown', handleClickOutside)
      }
      return () => {
        window.removeEventListener('mousedown', handleClickOutside)
      }
    }, [isOpen])

    return (
      <>
        <Input
          {...rest}
          ref={ref}
          label={label}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
            debouncedGetItems(e.target.value)
            onChange?.(e)
          }}
          onFocus={(e) => {
            setIsOpen(true)
            onFocus?.(e)
          }}
          onBlur={(e) => {
            onBlur?.(e)
          }}
        />
        {items && (
          <OptionsBox isOpen={isOpen} ref={itemRef}>
            {items.map((item) => {
              return (
                <Option
                  onClick={() => {
                    onOptionClicked(item)
                    setInputValue(`${item[fields.id]} - ${item[fields.name]}`)
                    setIsOpen(false)
                  }}
                  key={item[fields.id]}
                >
                  <Text size="body02">
                    {item[fields.id]} - {item[fields.name]}
                  </Text>
                </Option>
              )
            })}
          </OptionsBox>
        )}
      </>
    )
  }
)

export default Autocomplete
