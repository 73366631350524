import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Button, Box, Title, showToast } from '@superlogica/design-system'
import { excluirCliente } from 'services/clientes/cliente/excluirCliente'

const View = ({ textData, closeModalExcluir }) => {
  const excluirClienteMutation = useMutation(excluirCliente)
  const queryClient = useQueryClient()
  let history = useHistory()

  function refreshClientesList() {
    queryClient.invalidateQueries(['clientes'])
  }

  function deleteCliente(id) {
    excluirClienteMutation.mutate(id, {
      onSuccess: () => {
        refreshClientesList()
        showToast({
          type: 'success',
          message: 'Cliente excluido com sucesso'
        })
        history.push('/all-clientes')
      }
    })
  }

  return (
    <>
      <Box
        paddingType="square"
        paddingSize="sm"
        border="xs"
        borderColor="neutral100"
        style={{ borderBottom: 'none' }}
      >
        <Title size="display03" weight="bold" color="neutral800" align="center">
          Tem certeza que deseja excluir esse cliente?
        </Title>
      </Box>
      <Box className="flex align-center justify-center">
        <Button
          appearance="solid"
          color="primary"
          size="md"
          className="mr-xxs"
          onClick={() => {
            deleteCliente(textData.id)
          }}
        >
          Excluir
        </Button>
        <Button appearance="outline" color="primary" size="md" onClick={closeModalExcluir}>
          Cancelar
        </Button>
      </Box>
      <Box
        paddingType="squish"
        paddingSize="sm"
        border="xs"
        borderColor="neutral100"
        style={{ display: 'flex', borderTop: 'none' }}
      ></Box>
    </>
  )
}

export default View
