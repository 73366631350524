import { api } from 'services/api'

export const cadastrarFatura = async (data, token) => {
  const url = 'faturas/gerarFaturamento'

  const response = await api.post(url, { data })

  return response.data
}

export const getFatura = async (idFatura) => {
  const url = `faturas/${idFatura}`

  const response = await api.get(url)

  return response.data.data
}
