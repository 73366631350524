import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Modal, Title, showToast } from '@superlogica/design-system'
import Input from 'components/Input'
import { alterarAdministradora } from 'services/administradoras/administradoras'
import * as yup from 'yup'

const View = ({ admData }) => {
  const schemaAdm = yup
    .object({
      razao_social: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      cnpj: yup
        .string('Caracteres inválidos!')
        .required('O campo é obrigatório.')
        .length(18, 'Máximo de 18 caracteres'),
      numero_agencia: yup
        .number('Apenas Números')
        .positive()
        .integer()
        .required('O campo é obrigatório.'),
      telefone: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      licenca: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      app_token: yup
         .string('Caracteres inválidos!'),
        // .required('O campo é obrigatório.')
        // .length(36, 'Máximo de 36 caracteres'),
      access_token: yup
        .string('Caracteres inválidos!'),
        // .required('O campo é obrigatório.')
        // .length(36, 'Máximo de 36 caracteres'),
      email: yup.string().email('E-mail inválido.').required('O campo é obrigatório')
    })
    .required('O campo é obrigatório.')

  const queryClient = useQueryClient()

  function refreshAdministradora() {
    queryClient.invalidateQueries(['administradoras'])
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schemaAdm)
  })

  const openModalAdmin = () => setIsOpen(true)
  const closeModalAdmin = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)

  const editAdministradoraMutation = useMutation(alterarAdministradora)

  function editarAdm(admData) {    
    if(admData.access_token === ""){
      admData.access_token = '000'
    }

    if(admData.app_token === ""){
      admData.app_token = '000'
    }
    

    editAdministradoraMutation.mutate(admData, {
      onSuccess: () => {
        refreshAdministradora()
        showToast({
          type: 'success',
          message: 'Agência alterada com sucesso'
        })
        closeModalAdmin()
        reset({})
      }
    })
  }

  return (
    <>
      <Box paddingSize="none" className="mb-md">
        <Button onClick={openModalAdmin} appearance="outline" size="sm" className="ml-auto">
          Editar
        </Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModalAdmin}
          width={800}
          label="Testando modal"
        >
          <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
            <form onSubmit={handleSubmit(editarAdm)}>
              <Box
                paddingType="square"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ borderBottom: 'none' }}
              >
                <Title size="body01" weight="bold" color="neutral800">
                  Editar Administradora
                </Title>
              </Box>
              <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
                <input type="hidden" value={admData.id} {...register('id')} />
                <Input
                  label="Informe a razão social"
                  className="mb-sm"
                  defaultValue={admData.razao_social}
                  {...register('razao_social', {
                    required: 'a razão social é obrigatória',
                    maxLength: { value: 25, message: 'valor maximo 25' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu e-mail"
                  className="mb-sm"
                  defaultValue={admData.email}
                  {...register('email', {
                    required: 'O email é obrigatório',
                    maxLength: { value: 30, message: 'valor maximo 30' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe o cnpj"
                  className="mb-sm"
                  mask="cnpj"
                  defaultValue={admData.cnpj}
                  {...register('cnpj', {
                    required: 'O cnpj é de preenchimento obrigatório',
                    maxLength: { value: 18, message: 'valor maximo 18' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu telefone"
                  className="mb-sm"
                  mask="phone"
                  defaultValue={admData.telefone}
                  {...register('telefone', {
                    required: 'O número do telefone é obrigatório',
                    maxLength: { value: 15, message: 'valor maximo 15' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe número da agência"
                  className="mb-sm"
                  defaultValue={admData.numero_agencia}
                  {...register('numero_agencia', {
                    required: 'Número da agência é obrigatório',
                    maxLength: { value: 10, message: 'valor maximo 10' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe sua licença"
                  className="mb-sm"
                  defaultValue={admData.licenca}
                  {...register('licenca', {
                    required: 'A licença é obrigatória',
                    maxLength: { value: 20, message: 'valor maximo 20' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu app_token"
                  className="mb-sm"
                  defaultValue={admData.app_token}
                  {...register('app_token', {
                    required: true,
                    maxLength: { value: 36, message: 'valor maximo 36' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu access_token"
                  className="mb-sm"
                  defaultValue={admData.access_token}
                  {...register('access_token', {
                    required: true,
                    maxLength: { value: 36, message: 'valor maximo 36' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Quantidade de Condomínios"
                  className="mb-sm"
                  defaultValue={admData.qtd_condominios}
                  {...register('qtd_condominios', {
                    required: true,
                    maxLength: { value: 36, message: 'valor maximo 36' }
                  })}
                  errors={errors}
                />
              </Box>
              <Box
                paddingType="squish"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ display: 'flex', borderTop: 'none' }}
              >
                <Button
                  className="mr-auto"
                  color="primary"
                  appearance="ghost"
                  onClick={closeModalAdmin}
                >
                  Cancelar
                </Button>

                <Button className="ml-auto">Salvar</Button>
              </Box>
            </form>
          </div>
        </Modal>
      </Box>
    </>
  )
}

export default View
