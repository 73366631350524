
const Exportar = () => {
  return (
    <>
   
  </>
  )
}

export default Exportar
