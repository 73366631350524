import { useQueryClient } from 'react-query'

import View from './View'

const Container = ({ status, uuId, data }) => {
  const queryClient = useQueryClient()

  function refreshStatus() {
    queryClient.invalidateQueries(['contratoSingle'])
  }

  return (
    <View
      title="Editar Status Contrato"
      data={data}
      status={status}
      uuId={uuId}
      refreshStatus={refreshStatus}
    />
  )
}

export default Container
