/* eslint-disable no-unused-vars */
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import DadosCliente from 'pages/Contratos/Single/DadosCliente/Container'
import { getStatus } from 'services/contratos/contrato/atualizarStatus'
import { getSingleContratos } from 'services/contratos/contrato/getSingleContratos'
import { BoxContainer } from 'styles/global-style'

import AlterarCoop from './../../Contratos/Single/AlterarCooperativa/Container'
import VisualizarConta from './../../Contratos/Single/ContaCliente/Container'
import NovoContrato from './../../Contratos/Single/NovoContrato/Container'
import CadastrarFatura from './CadastrarFatura/Container'
import EditarContratoCampos from './EditarContratoCampos/Container'
import EditarContratoTipo from './EditarContratoTipo/Container'
import GerarTermo from './GerarTermo/Container'
import ListaFaturas from './ListaFaturas/Container'
import ListaHistorico from './ListaHistorico/Container'
import ListaHistoricoContato from './ListaHistoricoContato/Container'

const SingleContrato = () => {
  const { uuId } = useParams()

  const { data = [], isLoading } = useQuery(
    ['contratoSingle', uuId],
    () => getSingleContratos(uuId),
    { retry: false }
  )

  const { data: status = [] } = useQuery(['editarStatus'], () => getStatus())


  if (isLoading) {
    return 'Carregando...'
  }

  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="none">
        <DadosCliente uuId={uuId} data={data} status={status} />
      </BoxContainer>

      <BoxContainer paddingSize="lg">
        <Box paddingSize="none" className="mb-md flex">
          <NovoContrato data={data} />
          <AlterarCoop uuId={uuId}/>
          <VisualizarConta uuId={uuId}/>
          <CadastrarFatura data={data} uuId={uuId} />
          <EditarContratoCampos data={data} uuId={uuId} />
          <EditarContratoTipo  data={data} uuId={uuId} />
          <GerarTermo  data={data}  uuId={uuId} />
        </Box>
        <Box paddingSize="none" className="mb-md">
          <ListaFaturas data={data.faturas} uuId={uuId} />
        </Box>
        <Box paddingSize="none" className="mb-md">
          <ListaHistorico historicos={data.contratoHistoricos} uuId={uuId} />
        </Box>
        <Box paddingSize="none" className="mb-md">
          <ListaHistoricoContato historicos={data.contratoHistoricosContatos} uuId={uuId} />
        </Box>
      </BoxContainer>
      
    </>
  )
}

export default SingleContrato
