/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'

import { Text } from '@superlogica/design-system'
import { format } from 'date-fns'

import View from './View'

const Container = ({ faturasRebate }) => {
 console.log(faturasRebate)
  const columns = useMemo(() => [
    {
      Header: 'Id',
      accessor: 'faturas.id',
      align: 'left',
      Cell: ({ row }) => {
        return <Text>{faturasRebate[row.index]?.id}</Text>
      }
    },
    {
      Header: 'Vencimento',
      accessor: 'faturas.vencimento',
      align: 'left',
      Cell: ({ row }) => {
        let date = new Date(faturasRebate[row.index]?.vencimento)
        let dataFormatada = format(date, 'dd/MM/yyyy')
        return <Text>{dataFormatada}</Text>
      }
    },

    {
      Header: 'Valor',
      accessor: 'rebateAdms.valor',
      align: 'left',
      Cell: ({ row }) => {
        return <Text>{faturasRebate[row.index]?.valor}</Text>
      }
    },
    {
      Header: 'Cpf',
      accessor: 'rebateAdms.status',
      align: 'left',
      Cell: ({ row }) => {
        return <Text>{faturasRebate[row.index]?.id_contrato.id_cliente.cpf}</Text>
      }
    }
  ])

  const tableFaturasRebate = useMemo(() => faturasRebate, [faturasRebate])

  return (
    <View title="Lista faturas" faturasRebate={tableFaturasRebate} columns={columns} />
  )
}

export default Container
