import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { getSingleAdministradoras } from 'services/administradoras/administradoras'
import { BoxContainer } from 'styles/global-style'

import DadosAdministradora from './DadosAdministradora/View'
import ListaHistorico from './HIstoricaoRebates/Container'

const SingleAdministradora = () => {
  const { id } = useParams()

  const { data: administradora = [] } = useQuery(
    ['administradoras', id],
    () => getSingleAdministradoras(id),
    {
      retry: false
    }
  )
  console.log(administradora)

  //   const { data = [] } = useQuery(['getContratos', id], () => getContratosLista(id), {
  //     retry: false
  //   })

  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="none">
        <DadosAdministradora admData={administradora} />
      </BoxContainer>

      <BoxContainer paddingSize="lg">
        <Box paddingSize="none" className="mb-md flex">
          {/* <NovoContrato data={data} uuId={id} clientes={clientes} />
          <CadastrarFatura data={data} uuId={id} clientes={clientes} /> */}
        </Box>
        <Box paddingSize="none" className="mb-md">
          {/* <ListaContratos data={data} /> */}
        </Box>
        <Box paddingSize="none" className="mb-md">
          {/* <ListaFaturas id={id} /> */}
        </Box>
        <Box paddingSize="none" className="mb-md">
          <ListaHistorico historicos={administradora.rebateAdms} adm={administradora.id}  />
        </Box>
      </BoxContainer>
    </>
  )
}

export default SingleAdministradora
