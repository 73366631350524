import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation} from 'react-query'

import { Box, Button, Modal, showToast, Title } from '@superlogica/design-system'
import { cadastrarContrato } from 'services/contratos/contrato/cadastrarContrato'

import DadosCobranca from '../CadastrarContrato/DadosCobranca/Container'
import DadosContrato from '../CadastrarContrato/DadosContrato/Container'
import DadosUnidade from '../CadastrarContrato/DadosUnidade/Container'

const View = ({
  queryDistribuidoras,
  queryCondominios,
  queryCooperativas,
  queryClientes,
  refreshContrato
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModalContrato = () => setIsOpen(true)
  const closeModalContrato = () => setIsOpen(false)

  const { register, handleSubmit, reset, setValue } = useForm()

  const singleContratoMutation = useMutation(cadastrarContrato)
  function singleContratos(data) {

    singleContratoMutation.mutate(data, {
      onSuccess: () => {
        refreshContrato()
        showToast({
          type: 'success',
          message: 'Contrato gerado com sucesso'
        })
        closeModalContrato()
        reset({})
      }
    })
  }

  return (
    <Box paddingSize="none" className="mb-md">
      <Button onClick={openModalContrato} className="mr-xxxs">
        Novo contrato
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModalContrato}
        width={800}
        label="Testando modal"
      >
        <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
          <form onSubmit={handleSubmit(singleContratos)}>
            <Box
              paddingType="square"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ borderBottom: 'none' }}
            >
              <Title size="body01" weight="bold" color="neutral800">
                Novo Contrato
              </Title>
            </Box>
            <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              <DadosContrato
                queryDistribuidoras={queryDistribuidoras}
                queryCondominios={queryCondominios}
                queryCooperativas={queryCooperativas}
                queryClientes={queryClientes}
                register={register}
                setValue={setValue}
              />
              <DadosUnidade register={register} setValue={setValue} />
              <DadosCobranca register={register} setValue={setValue} />
            </Box>
            <Box
              paddingType="squish"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ display: 'flex', borderTop: 'none' }}
            >
              <Button
                className="mr-auto"
                color="primary"
                appearance="ghost"
                onClick={closeModalContrato}
              >
                Cancelar
              </Button>

              <Button className="ml-auto">Salvar</Button>
            </Box>
          </form>
        </div>
      </Modal>
    </Box>
  )
}

export default View
