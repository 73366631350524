import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Text, Title } from '@superlogica/design-system'
import { ArrowRight1, SingleNeutral } from '@superlogica/design-system/Icons/superlogica'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { BoxClienteIcon } from 'styles/global-style'

import EditarCondominio from '../EditarCondominios/Container'
import ExcluirCondominio from '../ExcluirCondominios/Container'
import Upload from '../Upload/Container'
import UploadAta from '../UploadAta/Container'

const View = ({ condoData }) => {
  if (!condoData) {
    return 'Carregando...'
  }

  return (
    <>
      <Box
        border
        borderColor="neutral100"
        bgColor="neutral0"
        paddingSize="lg"
        paddingType="squish"
        radius="xs"
      >
        <Box paddingSize="none" className="flex align-center mb-xs">
          <Text color="primary" className="mr-xxxxs" size="label02">
            <Link to={`../all-condominios`}>Condomínios</Link>
          </Text>
          <ArrowRight1 className="mr-xxxxs" width="10" height="10" fill="neutral600" />
          <Text size="label02">{condoData.nome}</Text>
          <Box className="flex align-center mb-xs ml-auto">
            <ExcluirCondominio condoData={condoData} />
            <EditarCondominio condoData={condoData} />
          </Box>
        </Box>

        <Box paddingSize="none" className="flex align-center">
          <BoxClienteIcon
            paddingSize="none"
            bgColor="neutral50"
            radius="md"
            paddingType="square"
            className="flex align-center mr-xs"
          >
            <SingleNeutral
              width="23"
              height="23"
              fill="neutral600"
              className="mr-auto ml-auto"
            />
          </BoxClienteIcon>
          <Box paddingSize="none">
            <Title as="h3" size="display03">
              {condoData.nome}
            </Title>
            <Box paddingSize="none" className="flex align-center mt-xxxxs mb-xs">
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                CNPJ
              </Text>
              <Text color="neutral600" size="body02" data={condoData} className="mr-xs">
                {condoData.cnpj}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Dia do vencimento condomínio:
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {condoData.vencimento_boleto}
              </Text>

              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Id ERP
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {condoData.id_condominio_erp}
              </Text>
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Qtd Unidades
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {condoData.qtd_unidades}
              </Text>
            </Box>
            <SeparationLine />
            <Box paddingSize="none" className="flex align-center mt-xxxxs mb-xs">
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                Licença
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {condoData.id_administradora.licenca}
              </Text>
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                CNPJ Administradora
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {condoData.id_administradora.cnpj}
              </Text>
              <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                E-mail Administradora
              </Text>
              <Text color="neutral600" size="body02" className="mr-xs">
                {condoData.id_administradora.email}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <div style={{ display: 'flex', borderTop: 'none', alignItems:'flex-start'}} >
        <Upload condoData={condoData}></Upload>
        <UploadAta condoData={condoData}></UploadAta>
      </div>
    </>
  )
}

export default View
