import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Text } from '@superlogica/design-system'
import {
  MultipleMan,
  BusinessContractHandshakeSign,
  StreamlineIconAccountingInvoiceMail40x401,
  BuildingModern2,
  MoneyWallet1,
  Building1
} from '@superlogica/design-system/Icons/superlogica'
import Exportar from 'pages/Exportar/components/ReactCSV'
import { SidebarContainer, ItemMenu, BoxMenu, BoxMenuLogo } from 'styles/global-style'
import './style.css';

const View = () => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const handleSubMenuToggle = () => {
    setShowSubMenu(!showSubMenu);
  };

  return (
    <>
      <SidebarContainer>
        <ul>
          <ItemMenu>
            <Link to="/">
              <BoxMenuLogo>
                <img src="../sup_logo_topo.png" alt="logo superlogica topo" />
              </BoxMenuLogo>
            </Link>
          </ItemMenu>
          <ItemMenu>
            <Link to="/all-clientes">
              <BoxMenu>
                <MultipleMan width="24" height="24" fill="neutral0" className="mb-xxxxs" />
                <Text color="neutral0" align="center" size="body02">
                  Clientes
                </Text>
              </BoxMenu>
            </Link>
          </ItemMenu>
          <ItemMenu className="itemMenu" onClick={handleSubMenuToggle}>
            <BoxMenu>
              <BusinessContractHandshakeSign
                width="24"
                height="24"
                fill="neutral0"
                className="mb-xxxxs"
              />
              <Text color="neutral0" align="center" size="body02">
                Contratos
              </Text>
            </BoxMenu>
            {showSubMenu && (
              <ul  className="submenu">
                <li className="submenuItem1">
                  <Link to="/all-contratos">
                    <Text  align="center" style={{ fontSize: "14px", color: "black"}}>
                      Contratos PF
                    </Text>
                  </Link>
                </li>
                <li className="submenuItem2">
                  <Link to="/all-contratos-pj">
                    <Text align="center" style={{ fontSize: "14px", color: "black"}}>
                      Contratos PJ
                    </Text>
                  </Link>
                </li>
              </ul>
            )}
          </ItemMenu>
          <ItemMenu>
            <Link to="/all-faturas">
              <BoxMenu>
                <StreamlineIconAccountingInvoiceMail40x401
                  width="24"
                  height="24"
                  fill="neutral0"
                  className="mb-xxxxs"
                />
                <Text color="neutral0" align="center" size="body02">
                  Faturas
                </Text>
              </BoxMenu>
            </Link>
          </ItemMenu>
          <ItemMenu>
            <Link to="/all-administradoras">
              <BoxMenu>
                <BuildingModern2 width="24" height="24" fill="neutral0" className="mb-xxxxs" />
                <Text color="neutral0" align="center" size="body02">
                  Agências
                </Text>
              </BoxMenu>
            </Link>
          </ItemMenu>
          <ItemMenu>
            <Link to="/all-condominios">
              <BoxMenu>
                <Building1 width="24" height="24" fill="neutral0" className="mb-xxxxs" />
                <Text color="neutral0" align="center" size="body02">
                  Condomínios
                </Text>
              </BoxMenu>
            </Link>
          </ItemMenu>
          <ItemMenu>
            <Link to="/all-rebates">
              <BoxMenu>
                <MoneyWallet1 width="24" height="24" fill="neutral0" className="mb-xxxxs" />
                <Text color="neutral0" align="center" size="body02">
                  Rebates
                </Text>
              </BoxMenu>
            </Link>
          </ItemMenu>
          <ItemMenu>
            <Exportar/>
          </ItemMenu>
        </ul>
      </SidebarContainer>
    </>
  )
}
export default View
