import keycloak from 'keycloak-js'

// const keycloak = keycloak({
//   url: 'https://sso-staging.superlogica.com/auth',
//   realm: 'superlogica',
//   clientId: 'energia-front'
// })

const keycloakClient = keycloak('/keycloak.json')

export default keycloakClient
