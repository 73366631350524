import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useMutation } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Modal,
  Title,
  showToast,
  Select,
  Datepicker
} from '@superlogica/design-system'
import Input from 'components/Input'
import { format } from 'date-fns'
import { cadastrarCliente } from 'services/clientes/cliente/cadastrarCliente'
import * as yup from 'yup'

const View = () => {
  const schemaCliente = yup
    .object({
      nome_completo: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      email: yup.string().email('E-mail inválido.').required('campo é obrigatório'),
      telefone: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      cpf: yup
        .string('Caracteres inválidos!')
        .required('O campo é obrigatório.')
        .length(14, 'Máximo de 14 caracteres'),
      rg: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      valor_ultima_conta: yup.number('Apenas numeros').min(7.5).required(),
      nacionalidade: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      profissao: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      estado_civil: yup.string('Caracteres inválidos!').required('O campo é obrigatório.')
    })
    .required('O campo é obrigatório.')

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schemaCliente)
  })
  const [isOpen, setIsOpen] = useState(false)
  const queryClient = useQueryClient()
  const [isDisabled, setIsDisabled] = useState(true)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function refreshClientesList() {
    queryClient.invalidateQueries(['clientesLista'])
  }

  const [estadoCivil, setEstadoCivil] = useState('Casado(a)')
  const [orgaoEmissor, setOrgaoEmissor] = useState('SSP/SP')
  const [tipoCliente, setTipoCliente] = useState('Titular')
  const [nascimento, setStartDate] = useState(new Date())

  const getDateInicial = (dateInicial) => {
    setStartDate(dateInicial)
  }

  useEffect(() => {
    setValue('data_nascimento', nascimento)
  }, [nascimento, setValue])

  const optionsEstadoCivil = [
    { value: 'Solteiro(a)', name: 'Solteiro(a)' },
    { value: 'Casado(a)', name: 'Casado(a)' },
    { value: 'Viúvo(a)', name: 'Viúvo(a)' },
    { value: 'Separado(a)', name: 'Separado(a)' },
    { value: 'Divorciado(a)', name: 'Divorciado(a)' },
    { value: 'União Estável', name: 'União Estável' }
  ]


  const optionsOrgao = [
    { value: 'SSP/AC', name: 'SSP/AC' },
    { value: 'II/AL', name: 'II/AL' },
    { value: 'II/AP', name: 'II/AP' },
    { value: 'SSP/AM', name: 'SSP/AM' },
    { value: 'SSP/BA ', name: 'SSP/BA' },
    { value: 'SSPDS/CE', name: 'SSPDS/CE' },
    { value: 'SSPDS/CE', name: 'SSPDS/CE' },
    { value: 'SSP/DF', name: 'SSP/DF' },
    { value: 'SEJUS/ES', name: 'SEJUS/ES' },
    { value: 'SSP/GO', name: 'SSP/GO' },
    { value: 'SSP/MA', name: 'SSP/MA' },
    { value: 'SESP/MT', name: 'SESP/MT' },
    { value: 'SEJUSP/MS', name: 'SEJUSP/MS' },
    { value: 'SSP/MG ', name: 'SSP/MG' },
    { value: 'SEGUP/PA', name: 'SEGUP/PA' },
    { value: 'SSP/PB', name: 'SSP/PB' },
    { value: 'SESP/PR', name: 'SESP/PR' },
    { value: 'SDS/PE', name: 'SDS/PE' },
    { value: 'SSP/PI', name: 'SSP/PI' },
    { value: 'DGPC/RJ', name: 'DGPC/RJ' },
    { value: 'SESED/RN', name: 'SESED/RN' },
    { value: 'SSP/RS', name: 'SSP/RS' },
    { value: 'SESDEC/RO', name: 'SESDEC/RO' },
    { value: 'SEJUC/RR', name: 'SEJUC/RR' },
    { value: 'SSP/SC', name: 'SSP/SC' },
    { value: 'SSP/SP', name: 'SSP/SP' },
    { value: 'SSP/SE', name: 'SSP/SE' },
    { value: 'SSP/TO', name: 'SSP/TO' }

  ]
  

  const optionsTipoCliente = [
    { value: 'titular', name: 'Titular' },
    { value: 'nao-titular-erp', name: 'Não Titular ERP' },
    { value: 'sindico', name: 'Síndico' }
  ]

  function handleChange(e) {
    setEstadoCivil(e.target.value)
  }

  function handleChange2(e) {
    setOrgaoEmissor(e.target.value)
  }

  function handleChangeTipoCliente(e) {
    setTipoCliente(e.target.value)
    if(e.target.value === 'nao-titular-erp'){
      setIsDisabled(false)
    }else{
      setIsDisabled(true)
    }

  }

  const createClienteMutation = useMutation(cadastrarCliente)

  function submit(data) {
    let dateNascimento = format(data.data_nascimento, 'dd/MM/yyyy')
    data.data_nascimento = dateNascimento

    createClienteMutation.mutate(
      {
        data: data
      },
      {
        onSuccess: () => {
          refreshClientesList()
          showToast({
            type: 'success',
            message: 'Cliente criado com sucesso'
          })
          closeModal()
          reset({})
        }
      }
    )
  }

  return (
    <Box paddingSize="none" className="mt-sm mb-md">
      <Button onClick={openModal}>Novo cliente</Button>
      <Modal isOpen={isOpen} onRequestClose={openModal} width={600} label="Testando modal">
        <Box
          paddingType="square"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ borderBottom: 'none' }}
        >
          <Title size="body01" weight="bold" color="neutral800">
            Novo Cliente
          </Title>
        </Box>

        <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
          <form onSubmit={handleSubmit(submit)}>
            <Box>
              <Input
                label="Informe seu nome"
                className="mb-sm"
                {...register('nome_completo', {
                  required: 'O nome completo é obrigatório',
                  maxLength: {
                    value: 100,
                    message:
                      'Nome excede o número de caracteres permitidos. Tamanho máximo 100'
                  }
                })}
                errors={errors}
              />
              <Input
                label="Informe seu e-mail"
                type="email"
                className="mb-sm"
                {...register('email', {
                  required: 'O email é obrigatório',
                  maxLength: {
                    value: 50,
                    message:
                      'Email excede o número de caracteres permitidos. Tamanho máximo 50 '
                  }
                })}
                errors={errors}
              />

              <Row>
                <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="Informe seu cpf"
                    mask="cpf"
                    {...register('cpf', {
                      required: 'O cpf é de preenchimento obrigatório',
                      maxLength: { value: 15, message: 'valor maximo 15' }
                    })}
                    errors={errors}
                    maxlength={11}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="Informe seu telefone"
                    mask="phone"
                    {...register('telefone', {
                      required: 'O número do telefone é obrigatório',
                      maxLength: { value: 15, message: 'valor maximo 15' }
                    })}
                    errors={errors}
                  />
                </Col>
               
              </Row>
              <Row>
              <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="Informe seu RG"
                    mask="rg"
                    {...register('rg', {
                      required: 'O rg é obrigatório',
                      maxLength: { value: 12, message: 'valor maximo 12' }
                    })}
                    errors={errors}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Select
                    size="sm"
                    label="Orgão emissor"
                    name="orgaoEmissor"
                    value={orgaoEmissor}
                    {...register('orgaoEmissor')}
                    onChange={handleChange2}
                    options={optionsOrgao}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="Informe sua nacionalidade"
                    {...register('nacionalidade', {
                      required: true,
                      maxLength: { value: 20, message: 'valor maximo 20' }
                    })}
                    errors={errors}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="Informe sua profissão"
                    {...register('profissao', {
                      required: true,
                      maxLength: { value: 25, message: 'valor maximo 25' }
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
              <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="Informe valor ultima conta"
                    {...register('valor_ultima_conta', {
                      required: 'Valor da última conta é obrigatório',
                      maxLength: { value: 10, message: 'valor maximo 10' }
                    })}
                    errors={errors}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Datepicker
                    name="data_nascimento"
                    label="Data de nascimento"
                    shouldCloseOnSelect={true}
                    startDate={nascimento}
                    selected={nascimento}
                    {...register('data_nascimento')}
                    onChange={(e) => {
                      getDateInicial(e)
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </Row>
              <Select
                    size="sm"
                    label="Estado Cívil"
                    className="mb-sm"
                    name="estado_civil"
                    value={estadoCivil}
                    {...register('estado_civil')}
                    onChange={handleChange}
                    options={optionsEstadoCivil}
                  />
              <Select
                size="sm"
                label="Tipo Cliente"
                className="mb-sm"
                name="tipo"
                value={tipoCliente}
                {...register('tipo')}
                onChange={handleChangeTipoCliente}
                options={optionsTipoCliente}
              />
               {!isDisabled && (
                  <Input
                    label="Cpf Titular"
                    mask="cpf"
                    {...register('cpf_titular', {
                      required: 'O cpf do titular é de preenchimento obrigatório',
                      maxLength: { value: 15, message: 'valor maximo 15' }
                    })}
                    errors={errors}
                    maxlength={11}
                  />
                )}
            </Box>
            <Box
              paddingType="squish"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ display: 'flex', borderTop: 'none' }}
            >
              <Button
                className="mr-auto"
                color="primary"
                appearance="ghost"
                onClick={closeModal}
              >
                Cancelar
              </Button>

              <Button className="ml-auto">Salvar</Button>
            </Box>
          </form>
        </div>
      </Modal>
    </Box>
  )
}

export default View
