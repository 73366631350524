import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { Search } from '@superlogica/design-system/Icons/superlogica'
import { getAdministradoras } from 'services/administradoras/administradoras.js'
import { useDebouncedCallback } from 'use-debounce/lib'
//import { useDebouncedCallback } from 'use-debounce'

import View from './View.jsx'

const Container = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [sort, setSort] = useState({ direction: 'none', accessor: '' })
  const [searchCAdm, setSearchCAdm] = useState()
  

  const { data, isLoading, isError, refetch } = useQuery(
    ['adminLista',searchCAdm ,currentPage, sort],
    () => getAdministradoras(searchCAdm, currentPage, sort)
  )
  
  const administradoras = data?.data ?? []
  const itemsPerPage = data?.itensPorPagina
  const totalItems = data?.totalItens
  const administradoras2 = administradoras


  const setSearchCAdmValue = useDebouncedCallback((value) => {
    setSearchCAdm(value)
  }, 300)

  function sortTable(column) {
    const sortDirections = {
      none: 'ASC',
      ASC: 'DESC',
      DESC: 'none'
    }

    setSort({ direction: sortDirections[column.sortDirection], accessor: column.id })
  }

  const columnsAdministradoras = useMemo(
    () => [
      {
        Header: 'Razão Social', // texto q será exibido no cabeçalho da tabela
        accessor: 'razao_social', // id das células dessa coluna
        align: 'left',
        width: '20%' ,
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'razao_social' ? sort.direction : 'none'//alinha o texto dentro da célula da tabela
      },
      {
        Header: 'Documento',
        accessor: 'cnpj',
        align: 'left',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'cnpj' ? sort.direction : 'none'
      },
      {
        Header: 'Licença',
        accessor: 'licenca',
        align: 'left',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'licenca' ? sort.direction : 'none'
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        align: 'left',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'left' ? sort.direction : 'none'
      },
      {
        Header: 'Telefone',
        accessor: 'telefone',
        align: 'left',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'telefone' ? sort.direction : 'none'
      },
      {
        Header: '',
        accessor: 'icon',
        align: 'center',
        width: '5%',
        Cell: ({ row }) => {
          const admId = row.original.id
          return (
            <Link to={`single-administradora/${admId}`}>
              <Search height="16" width="16" />
            </Link>
          )
        }
      }
    ],
    [sort]
  )

  const tableAdministradoras = useMemo(() => administradoras2, [administradoras2])
  return (
    <View
      title="Lista Administradoras"
      isLoading={isLoading}
      isError={isError}
      setSearchCAdmValue={setSearchCAdmValue}
      tableAdministradoras={tableAdministradoras}
      columns={columnsAdministradoras}
      refetch={refetch}
      totalItems={totalItems}
      itemsPerPage={itemsPerPage}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      manualSort={true}
    />
  )
}

export default Container
