import { ThemeProvider } from '@superlogica/design-system'

import GlobalStyle from './GlobalStyle'

const Layout = ({ children }) => (
  <ThemeProvider theme="superlogica">
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Layout
