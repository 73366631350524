import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Modal, showToast, Title } from '@superlogica/design-system'
import { cadastrarContrato } from 'services/contratos/contrato/cadastrarContrato'
import * as yup from 'yup'

import DadosCobranca from '../Cadastrar/DadosCobranca/Container'
import DadosContrato from '../Cadastrar/DadosContrato/Container'
import DadosUnidade from '../Cadastrar/DadosUnidade/Container'
const View = ({
  queryDistribuidoras,
  queryCondominios,
  queryCooperativas,
  queryClientes,
  refreshContrato
}) => {
  const schemaContrato = yup
    .object({
      hash_unidade: yup.string().required('O campo é obrigatório.')
    })
    .required('O campo é obrigatório.')

  const [isOpen, setIsOpen] = useState(false)

  const [disable, setDisable] = useState(false)  

  function onClick(e) {
    setDisable(true)
    setTimeout(() => {
      setDisable(false);
    }, 5000);

  }


  const openModalContrato = () => setIsOpen(true)
  const closeModalContrato = () => setIsOpen(false)

  const { register, handleSubmit, reset, setValue, errors } = useForm({
    resolver: yupResolver(schemaContrato)
  })

  const gerarContratoMutation = useMutation(cadastrarContrato)
  function gerarContratos(data) {
    gerarContratoMutation.mutate(data, {
      onSuccess: () => {
        refreshContrato()
        showToast({
          type: 'success',
          message: 'Contrato gerado com sucesso'
        })
        closeModalContrato()
        reset({})
      }
    })
  }

  return (
    <Box paddingSize="none" className="mb-md">
      <Button onClick={openModalContrato} className="mr-xxxs">
        Novo contrato
      </Button>

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModalContrato}
        width={800}
        label="Testando modal"
      >
        <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
          <form onSubmit={handleSubmit(gerarContratos)}>
            <Box
              paddingType="square"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ borderBottom: 'none' }}
            >
              <Title size="body01" weight="bold" color="neutral800">
                Novo Contrato
              </Title>
            </Box>
            <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              <DadosContrato
                queryDistribuidoras={queryDistribuidoras}
                queryCondominios={queryCondominios}
                queryCooperativas={queryCooperativas}
                register={register}
                setValue={setValue}
                errors={errors}
              />
              <DadosUnidade register={register} errors={errors} />
              <DadosCobranca register={register} errors={errors} />
            </Box>
            <Box
              paddingType="squish"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ display: 'flex', borderTop: 'none' }}
            >
              <Button
                className="mr-auto"
                color="primary"
                appearance="ghost"
                onClick={closeModalContrato}
              >
                Cancelar
              </Button>

              <Button className="ml-auto"  style={{
                opacity: disable ? 0.5 : 1
              }}
              onClick={onClick} 
              >
              {disable && <span>Carregando</span>}
              {!disable && <span>Salvar</span>}
              </Button>
            </Box>
          </form>
        </div>
      </Modal>
    </Box>
  )
}

export default View
