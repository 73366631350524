import React, { useState } from 'react'
import { Col, Row } from 'react-awesome-styled-grid'
import { useQuery } from 'react-query'

import { Select, Box, Title } from '@superlogica/design-system'
import Autocomplete from 'components/Autocomplete'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { getClientesParams } from 'services/clientes/clientes'
import { getAllCondominios } from 'services/condominios/condominios'

const View = ({
  queryDistribuidoras,
  queryCooperativas,
  register,
  setValue
}) => {
  const [queryCliente, setQueryCliente] = useState('')
  const [queryCondo, setQueryCondo] = useState('')
  const [tipoContrato, setTipoContrato] = useState('GD PF')

  const { data: clientes = [] } = useQuery(
    ['clientes', queryCliente],
    () => getClientesParams('nome_completo', queryCliente),
    {
      retry: false
    }
  )

  const { data: condominios = [] } = useQuery(
    ['condominios', queryCondo],
    () => getAllCondominios(queryCondo),
    {
      retry: false
    }
  )

  //valida a lista -> passar no itens
  const derivado = clientes.filter((cliente) => {
    return cliente.nome_completo != null
  })

  function populateOptions(data2) {
    return data2.map((item2) => ({
      name: item2.nome,
      value: item2.id
    }))
  }

  // const optionsCondominios = populateOptions(queryCondominios)
  const optionsCooperativas = populateOptions(queryCooperativas)
  const optionsDistribuidoras = populateOptions(queryDistribuidoras)

  const tiposContrato = [
    { value: 'GD PJ', name: 'GD PJ' },
    { value: 'ML PJ', name: 'ML PJ' },
  ]

  function onclicked(item) {
    setValue('id_cliente', item.id)
  }

  function onClicked2(item) {
    var teste = '' + item.id + ''
    setValue('id_condominio', teste)
  }

  function handleQuery(value) {
    setQueryCliente(value)
  }

  function handleQuery2(value) {
    setQueryCondo(value)
  }

  function handleChangeTipo(e) {
    setTipoContrato(e.target.value)
  }

  return (
    <>
      <Box>
        <Title as="h4" size="display04" className="mb-xxxs">
          Dados do cliente
        </Title>
        <SeparationLine />
      </Box>
      <Box>
        <Row>
          <Col md={12}>
            <Autocomplete
              label="Selecione um cliente"
              items={derivado}
              onOptionClicked={(selectedItem) => onclicked(selectedItem)}
              getItems={handleQuery}
              fields={{ id: 'cpf', name: 'nome_completo' }}
            />
            <input type="hidden" {...register('id_cliente')} />
          </Col>
        </Row>
      </Box>

      <Box>
        <Row>
          <Col md={12}>
            <Autocomplete
              label="Selecione um condominio"
              items={condominios}
              onOptionClicked={(selectedItem) => onClicked2(selectedItem)}
              getItems={handleQuery2}
              fields={{ id: 'id', name: 'nome' }}
            />
            <input type="hidden" {...register('id_condominio')} />
          </Col>
        </Row>
      </Box>
      <Box>
        <Select
          size="sm"
          label="Selecione uma Distribuidora"
          name="distribuidoras"
          {...register('id_distribuidora')}
          options={optionsDistribuidoras}
        />
      </Box>
      <Box>
        <Select
          size="sm"
          label="Selecione uma Cooperativa"
          name="cooperativas"
          {...register('id_cooperativa')}
          options={optionsCooperativas}
        />
      </Box>
      <Box>
        <Select
          size="sm"
          label="Selecione o Tipo do Contrato"
          name="tipoContrato"
          value={tipoContrato}
          {...register('tipoContrato')}
          onChange={handleChangeTipo}
          options={tiposContrato}
        />
      </Box>
    </>
  )
}

export default View
