import View from './View'

const Container = ({ uuId }) => {

  return (
    <View
      title="Visualizar ultima conta"
      idContrato={uuId}
    />
  )
}

export default Container
