import React, { useMemo } from 'react'

import View from './View'

const Container = ({ data }) => {
  const textData = useMemo(() => data, [data])

  return <View title="Single de Clientes" textData={textData} />
}

export default Container
