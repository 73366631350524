import React, { useState } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Title, Button, Modal, showToast } from '@superlogica/design-system';
import Autocomplete from 'components/Autocomplete';
import Input from 'components/Input';
import { getAllAdministradoras } from 'services/administradoras/administradoras';
import { cadastrarCondominio } from 'services/condominios/condominios';
import * as yup from 'yup';

const View = () => {
  const schemaCond = yup
    .object({
      id_administradora: yup
        .string('Caracteres inválidos!')
        .required('O campo é obrigatório.'),
      cnpj: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      vencimento_boleto: yup
        .number()
        .typeError('Apenas numeros')
        .required('O campo é obrigatório.')
        .max(31, 'Máximo até dia 31. De 01 á 31')
        .min(1, 'Mínimo dia 1. De 01 á 31'),
      nome: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      id_condominio_erp: yup
        .number()
        .typeError('Apenas numeros')
        .required('O campo é obrigatório.')
        .positive('Apenas Números Positivos')
        .integer('Apenas Números')
    })
    .required('O campo é obrigatório.')

  const [query, setQuery] = useState('')

  const { data: administradoras = [] } = useQuery(
    ['administradoras', query],
    () => getAllAdministradoras(query),
    {
      retry: false
    }
  )

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);

    const handleFileChange = (e) => {
      setSelectedFile(e.target.files[0]); // Atualiza o estado
    };

    const handleFileChangeAta = (e) => {
      setSelectedFile2(e.target.files[0]); // Atualiza o estado
    };
      
  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schemaCond)
  });


  const openModalCondo = () => setIsOpen(true);
  const closeModalCondo = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  function refreshCondominio() {
    queryClient.invalidateQueries(['condominiosLista']);
  }

  const cadastrarCondominioMutation = useMutation(cadastrarCondominio);

  function cadastrar(data) {

    data.rg_sindico = selectedFile;
    data.ata_condominio = selectedFile2;

    cadastrarCondominioMutation.mutate(data, {
      onSuccess: () => {
        refreshCondominio();
        showToast({
          type: 'success',
          message: 'Condomínio cadastrado com sucesso'
        });
        closeModalCondo();
        reset({});
        setSelectedFile(null); // Limpar o arquivo selecionado
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        });
      }
    });
  }

  function onOptionClicked(item) {
    setValue('id_administradora', item.id)
  }

  function handleQuery(value) {
    setQuery(value)
  }

  return (
   <>
      <Box paddingSize="none" className="mb-md">
        <Button onClick={openModalCondo} className="mr-xxxs">
          Novo Condomínio
        </Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModalCondo}
          width={800}
          label="Testando modal"
        >
          <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
            <form onSubmit={handleSubmit(cadastrar)}>
              <Box
                paddingType="square"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ borderBottom: 'none' }}
              >
                <Title size="body01" weight="bold" color="neutral800">
                  Novo Condomínio
                </Title>
              </Box>
              <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
                <Row>
                  <Col md={12}>
                    <Autocomplete
                      className="mb-sm"
                      label="Escolha a administradora"
                      items={administradoras}
                      onOptionClicked={(selectedItem) => onOptionClicked(selectedItem)}
                      getItems={handleQuery}
                      fields={{ id: 'id', name: 'licenca' }}
                    />
                  </Col>
                  <input type="hidden" {...register('id_administradora')} />
                </Row>
                <Input
                  label="Informe o Nome Condomínio"
                  className="mb-sm"
                  {...register('nome', {
                    required: 'a nome é obrigatório',
                    maxLength: { value: 40, message: 'valor maximo 25' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe o cnpj"
                  className="mb-sm"
                  mask="cnpj"
                  {...register('cnpj', {
                    required: 'O cnpj é de preenchimento obrigatório',
                    maxLength: { value: 18, message: 'valor maximo 18' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu Vencimento do Boleto do Condomínio"
                  className="mb-sm"
                  {...register('vencimento_boleto', {
                    required: 'O Vencimento do Boleto do Condomínio é obrigatório',
                    maxLength: { value: 2, message: 'valor maximo 2' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe o ID do ERP do condomínio"
                  className="mb-sm"
                  {...register('id_condominio_erp', {
                    required: 'A ID é obrigatório',
                    maxLength: { value: 4, message: 'valor maximo 4' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Quantidade de Unidades"
                  className="mb-sm"
                  {...register('qtd_unidades', {
                    maxLength: { value: 4, message: 'valor maximo 4' }
                  })}
                  errors={errors}
                />
                 <Input
                  label="Email"
                  className="mb-sm"
                  {...register('email', {
                    required: 'O email é obrigatório'
                  })}
                  errors={errors}
                />

                 <Input
                  label="Razão social"
                  className="mb-sm"
                  {...register('razao_social', {
                    required: 'A razão social é obrigatória',
                    maxLength: { value: 40, message: 'valor maximo 40' }
                  })}
                  errors={errors}
                />
                  
                  <Input
                  label="RG do sindico"
                  type="file"
                  accept="*" 
                  onChange={handleFileChange}
                  className="mb-sm"
                  {...register('rg_sindico',{
                    onChange: (e) => handleFileChange(e)
                  })}
                />

                  <Input
                  label="Ata do condominio"
                  type="file"
                  accept="*" 
                  onChange={handleFileChangeAta}
                  className="mb-sm"
                  {...register('ata_condominio',{
                    onChange: (e) => handleFileChangeAta(e)
                  })}
                />
              </Box>
              <Box
                paddingType="squish"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ display: 'flex', borderTop: 'none' }}
              >
                <Button
                  className="mr-auto"
                  color="primary"
                  appearance="ghost"
                  onClick={closeModalCondo}
                >
                  Cancelar
                </Button>

                <Button className="ml-auto">Salvar</Button>
              </Box>
            </form>
          </div>
        </Modal>
      </Box>
    </>
  )
}

export default View
