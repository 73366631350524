import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { Button, Box, Title, showToast } from '@superlogica/design-system'
import { Bin1 } from '@superlogica/design-system/Icons/superlogica'
import Modal from '@superlogica/design-system/Modal'
import { excluirAdministradora } from 'services/administradoras/administradoras'

const View = ({ admData }) => {
  const [isOpenExcluir, setIsOpenExcluir] = useState(false)

  function openModalExcluir() {
    setIsOpenExcluir(true)
  }

  function closeModalExcluir() {
    setIsOpenExcluir(false)
  }
  const excluirAdmMutation = useMutation(excluirAdministradora)
  const queryClient = useQueryClient()
  let history = useHistory()

  function refreshAdmList() {
    queryClient.invalidateQueries(['administradoras'])
  }

  function deleteAdm(id) {
    excluirAdmMutation.mutate(id, {
      onSuccess: () => {
        refreshAdmList()
        showToast({
          type: 'success',
          message: 'Agência excluida com sucesso'
        })
        history.push('/all-administradoras')
      }
    })
  }

  return (
    <>
      <Button
        onClick={openModalExcluir}
        appearance="solid"
        size="sm"
        className="mr-xxs"
        leftIcon={<Bin1 />}
      >
        Excluir Agência
      </Button>

      <Modal
        isOpen={isOpenExcluir}
        height="80vh"
        onRequestClose={openModalExcluir}
        width="20vw"
        label="Testando modal"
      >
        <Box
          paddingType="square"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ borderBottom: 'none' }}
        >
          <Title size="display03" weight="bold" color="neutral800" align="center">
            Tem certeza que deseja excluir essa Agência?
          </Title>
        </Box>
        <Box className="flex align-center">
          <Button
            appearance="solid"
            color="primary"
            size="md"
            className="mr-xxs"
            onClick={() => {
              deleteAdm(admData.id)
            }}
          >
            Excluir
          </Button>
          <Button appearance="outline" color="primary" size="md" onClick={closeModalExcluir}>
            Cancelar
          </Button>
        </Box>
        <Box
          paddingType="squish"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ display: 'flex', borderTop: 'none' }}
        ></Box>
      </Modal>
    </>
  )
}

export default View
