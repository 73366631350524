import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Title, Button, Modal, showToast } from '@superlogica/design-system'
import Input from 'components/Input'
import { cadastrarAdministradora } from 'services/administradoras/administradoras'
import * as yup from 'yup'

const View = ({ refreshAdministradora }) => {
  const schemaAdm = yup
    .object({
      razao_social: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      cnpj: yup
        .string('Caracteres inválidos!')
        .required('O campo é obrigatório.')
        .length(18, 'Máximo de 18 caracteres'),
      numero_agencia: yup
        .number('Apenas Números')
        .positive()
        .integer()
        .required('O campo é obrigatório.'),
      licenca: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      app_token: yup
        .string('Caracteres inválidos!'),
        // .required('O campo é obrigatório.')
        // .length(36, 'Máximo de 36 caracteres')
      access_token: yup
        .string('Caracteres inválidos!'),
        // .required('O campo é obrigatório.')
        // .length(36, 'Máximo de 36 caracteres'),
      email: yup.string().email('E-mail inválido.').required('O campo é obrigatório')
    })
    .required('O campo é obrigatório.')

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schemaAdm)
  })

  const openModalAdmin = () => setIsOpen(true)
  const closeModalAdmin = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)

  const cadastrarAdministradoraMutation = useMutation(cadastrarAdministradora)

  function cadastrar(data) {
    if(data.access_token === ""){
      data.access_token = '000'
    }

    if(data.app_token === ""){
      data.app_token = '000'
    }

    cadastrarAdministradoraMutation.mutate(data, {
      onSuccess: () => {
        refreshAdministradora()
        showToast({
          type: 'success',
          message: 'Agência cadastrada com sucesso'
        })
        closeModalAdmin()
        reset({})
      },
      onError: (data) => {
        let erro = data.response.data.errors
        console.log('data', erro)
        erro.forEach(function (name) {
          console.log('nome', name)
        })
      }
    })
  }

  return (
    <>
      <Box paddingSize="none" className="mb-md">
        <Button onClick={openModalAdmin} className="mr-xxxs">
          Nova Agência
        </Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModalAdmin}
          width={800}
          label="Testando modal"
        >
          <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
            <form onSubmit={handleSubmit(cadastrar)}>
              <Box
                paddingType="square"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ borderBottom: 'none' }}
              >
                <Title size="body01" weight="bold" color="neutral800">
                  Nova Administradora
                </Title>
              </Box>
              <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
                <Input
                  label="Informe a razão social"
                  className="mb-sm"
                  {...register('razao_social', {
                    required: 'a razão social é obrigatória',
                    maxLength: { value: 40, message: 'valor maximo 40' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu e-mail"
                  className="mb-sm"
                  {...register('email', {
                    required: 'O email é obrigatório',
                    maxLength: { value: 40, message: 'valor maximo 30' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe o cnpj"
                  className="mb-sm"
                  mask="cnpj"
                  {...register('cnpj', {
                    required: 'O cnpj é de preenchimento obrigatório',
                    maxLength: { value: 18, message: 'valor maximo 18' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu telefone"
                  className="mb-sm"
                  mask="phone"
                  {...register('telefone', {
                    required: 'O número do telefone é obrigatório',
                    maxLength: { value: 15, message: 'valor maximo 15' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe número da agência"
                  className="mb-sm"
                  {...register('numero_agencia', {
                    required: 'Número da agência é obrigatório',
                    maxLength: { value: 10, message: 'valor maximo 10' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe sua licença"
                  className="mb-sm"
                  {...register('licenca', {
                    required: 'A licença é obrigatória',
                    maxLength: { value: 20, message: 'valor maximo 10' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu app_token"
                  className="mb-sm"
                  {...register('app_token', {
                    required: true,
                    maxLength: { value: 36, message: 'valor maximo 36' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Informe seu access_token"
                  className="mb-sm"
                  {...register('access_token', {
                    required: true,
                    maxLength: { value: 36, message: 'valor maximo 36' }
                  })}
                  errors={errors}
                />
                <Input
                  label="Quantidade de Condomínios"
                  className="mb-sm"
                  {...register('qtd_condominios', {
                    required: true,
                    maxLength: { value: 36, message: 'valor maximo 36' }
                  })}
                  errors={errors}
                />
              </Box>
              <Box
                paddingType="squish"
                paddingSize="sm"
                border="xs"
                borderColor="neutral100"
                style={{ display: 'flex', borderTop: 'none' }}
              >
                <Button
                  className="mr-auto"
                  color="primary"
                  appearance="ghost"
                  onClick={closeModalAdmin}
                >
                  Cancelar
                </Button>

                <Button className="ml-auto">Salvar</Button>
              </Box>
            </form>
          </div>
        </Modal>
      </Box>
    </>
  )
}

export default View
