import React from 'react'

import View from './View'

const Container = ({
  queryDistribuidoras,
  queryCondominios,
  queryCooperativas,
  queryClientes,
  register
}) => {
  return (
    <View
      title="Cadastro Dados do Contrato"
      queryDistribuidoras={queryDistribuidoras}
      queryCondominios={queryCondominios}
      queryCooperativas={queryCooperativas}
      queryClientes={queryClientes}
      register={register}
    />
  )
}

export default Container
