import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { Text, Box, Input, Table } from '@superlogica/design-system'
import { Search } from '@superlogica/design-system/Icons/superlogica'
import Pagination from 'components/Pagination/Pagination'
import { getClientes } from 'services/clientes/clientes'
import { BoxTitleTable, BoxTabTable } from 'styles/global-style'
import { useDebouncedCallback } from 'use-debounce'
import mask from 'utils/mask'
import obfuscate from 'utils/obfuscate'

const ListaClientes = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [sort, setSort] = useState({ direction: 'none', accessor: '' })

  const { data, isLoading, isError, refetch } = useQuery(
    ['clientesLista', searchQuery, currentPage, sort],
    () => getClientes(searchQuery, currentPage, sort)
  )

  const clients = data?.data ?? []
  const itemsPerPage = data?.itensPorPagina
  const totalItems = data?.totalItens

  const derivadoClientes = clients.filter((cliente) => {
    return cliente.nome_completo !== null
  })

  const setSearchValue = useDebouncedCallback((value) => {
    setSearchQuery(value)
  }, 300)

  function sortTable(column) {
    const sortDirections = {
      none: 'ASC',
      ASC: 'DESC',
      DESC: 'none'
    }

    setSort({ direction: sortDirections[column.sortDirection], accessor: column.id })
  }

  const columnsCliente = useMemo(
    () => [
      {
        hideSort: true,
        accessor: 'icon',
        align: 'center',
        width: '3%',
        Cell: ({ row }) => {
          const clienteId = row.original.id
          return (
            <Link to={`single-cliente/${clienteId}`}>
              <Search height="16" width="16" />
            </Link>
          )
        }
      },
      {
        Header: 'Cliente',
        accessor: 'nome_completo',
        align: 'left',
        width: '20%',
        sortByOnClick: (column) => sortTable(column) ,
        sortDirection: sort.accessor === 'nome_completo' ? sort.direction : 'none'
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
        align: 'left',
        Cell: ({ row }) => obfuscate.cpf(row.original.cpf),
        sortByOnClick: (column) => sortTable(column),
        sortDirection: sort.accessor === 'cpf' ? sort.direction : 'none'
      },
      {
        Header: 'Última Fatura',
        accessor: 'valor_ultima_conta',
        align: 'left',
        Cell: ({ row }) => {
          let atual = row.original.valor_ultima_conta ?? '0'
          let formatada = atual
            .toLocaleString('pt-br', { style: 'currency', currency: 'R$' })
            .replace('.', ',')
          return <Text>{`R$ ${formatada}`}</Text>
        }
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        align: 'left'
      },
      {
        Header: 'Telefone',
        accessor: 'telefone',
        align: 'left',
        Cell: ({ row }) => mask.phone(row.original.telefone)
      }
    ],
    [sort]
  )

  const tableData = useMemo(() => derivadoClientes, [derivadoClientes])

  return (
    <>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xs"
        radius="md"
        className="flex align-center"
      >
        <BoxTitleTable paddingSize="none">
          <Text className="mr-xxxs">Clientes</Text>
          <Text color="neutral400" size="body02">
            {`Exibindo ${tableData.length} itens de ${derivadoClientes.length}`}
          </Text>
        </BoxTitleTable>

        <Box paddingSize="none" className="ml-auto flex align-center">
          <Input
            name="search"
            className="ml-xxxs"
            type="text"
            label="Buscar por nome..."
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            rightIcon={<Search />}
          />
        </Box>
      </BoxTabTable>
      <Box border borderColor="neutral300" bgColor="neutral0" paddingSize="none" radius="xs">
        <Table
          loading={isLoading}
          error={isError}
          data={tableData}
          columns={columnsCliente}
          tableName="clientes"
          retry={refetch}
          manualSort={true}
        />
      </Box>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xxs"
        radius="md"
        className="flex align-center"
      >
        <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </BoxTabTable>
    </>
  )
}

export default ListaClientes
