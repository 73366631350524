import { useQueryClient } from 'react-query'

import View from './View'

const Container = () => {


  //const { data: clientes = [] } = useQuery('selectClientesFatura', getClientes)

  const queryClient = useQueryClient()

  function refreshFatura() {
    queryClient.invalidateQueries(['faturas'])
  }

  return (
    <View title="Display Nova Faturas" refreshFatura={refreshFatura} />
  )
}

export default Container
