import { HeaderTopbar, BoxContainer } from 'styles/global-style'

import MenuUser from './MenuUser/Container'

const Header = () => {
  return (
    <HeaderTopbar bgColor="neutral0" paddingSize="none">
      <BoxContainer paddingSize="sm">
        <MenuUser />
      </BoxContainer>
    </HeaderTopbar>
  )
}

export default Header
