import { useQueryClient } from 'react-query'

import View from './View'

const Container = ({ data, uuId }) => {
  const idCliente = data?.id_cliente?.id
  const idContrato = uuId

  const queryClient = useQueryClient()

  function refreshSingleFatura() {
    queryClient.invalidateQueries(['contratoSingleLista'])
  }

  return (
    <View
      title="Display Nova Faturas"
      data={data}
      idContrato={idContrato}
      idCliente={idCliente}
      refreshSingleFatura={refreshSingleFatura}
    />
  )
}

export default Container
