import React from 'react'

import { Box, Title, Text } from '@superlogica/design-system'
import { colors } from '@superlogica/foundation/tokens'

export const BoxCounter = (props) => {
  return (
    <Box  bgColor="neutral0" paddingSize="sm" radius="xs">
      <Title as="h3" style={{ color: colors[props.color] }} size="display04" align="center">
        {props.valor}
      </Title>
      <Text color="neutral700" size="subtitle01" align="center">
        {props.categoria}
      </Text>
    </Box>
  )
}
