let config = ''

const dev = {
  baseUrl: 'http://127.0.0.1:8080/'
}

const prod = {
  baseUrl: 'https://api.energia.superlogica.com/backoffice/v1/'
}

const staging = {
  baseUrl: 'https://api.energia.superlogica.com/staging/backoffice/v1/'
}

if (process.env.REACT_APP_STAGE === 'master') {
  config = prod
} else if (process.env.REACT_APP_STAGE === 'staging') {
  config = staging
} else {
  config = dev
}

export default config
