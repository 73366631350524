import { api } from 'services/api'

export const alterarCliente = async (data) => {
  const url = `clientes/${data?.id}`

  const response = await api.put(`${url}`, { data })

  console.log(response)
  return response.data
}
