/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'

import { Text } from '@superlogica/design-system'
import { format } from 'date-fns'

import View from './View'

const Container = ({ uuId, historicos }) => {
  const columns = useMemo(() => [
    {
      Header: 'Data',
      accessor: 'contratosHistoricos.data',
      align: 'left',
      Cell: ({ row }) => {
        let date = new Date(historicos[row.index]?.data)
        let dataFormatada = format(date, 'dd/MM/yyyy')
        return <Text>{dataFormatada}</Text>
      }
    },
    {
      Header: 'Descrição',
      accessor: 'contratosHistoricos.descricao',
      align: 'left',
      Cell: ({ row }) => {
        return <Text>{historicos[row.index]?.descricao}</Text>
      }
    }
  ])

  const tableSingleHistorico = useMemo(() => historicos, [historicos])

  return (
    <View title="Lista Historico Single" historicos={tableSingleHistorico} columns={columns} />
  )
}

export default Container
