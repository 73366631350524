import { api } from 'services/api'

export const getCount = async (param) => {
  let url = 'contratos/count'

  const response = await api.get(`${url}/${param}`)

  return response.data.data
}

export const getCountFatura = async () => {
  const url = 'faturas/count/StatusCobrancas'
  const response = await api.get(url)

  return response.data.data
}
