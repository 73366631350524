import React from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { getResources } from 'services/contratos/contratos'

import View from './View'

const Container = () => {
  const { data: distribuidoras = [] } = useQuery('distribuidoras', () =>
    getResources('distribuidoras')
  )

  const { data: condominios = [] } = useQuery('condominios', () => getResources('condominios/listAllCondominios'))

  const { data: cooperativas = [] } = useQuery('cooperativas', () =>
    getResources('cooperativas')
  )

  const { data: clientes = [] } = useQuery('selectClientes', () => getResources('clientes'))

  const queryClient = useQueryClient()

  function refreshContrato() {
    queryClient.invalidateQueries(['contratos'])
    queryClient.invalidateQueries(['getCountContratos'])
  }

  return (
    <View
      title="Display Novo COntrato"
      queryDistribuidoras={distribuidoras}
      queryCondominios={condominios}
      queryCooperativas={cooperativas}
      queryClientes={clientes}
      refreshContrato={refreshContrato}
    />
  )
}
export default Container
