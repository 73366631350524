import View from './View'

const Container = ({ uuId}) => {

  return (
    <View
      title="Editar Status"
      uuId={uuId}
    />
  )
}

export default Container
