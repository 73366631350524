import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useMutation } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Modal,
  showToast,
  Title,
  Select,
  Datepicker
} from '@superlogica/design-system'
import Autocomplete from 'components/Autocomplete'
import Input from 'components/Input'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { format } from 'date-fns'
import { getClientesParams } from 'services/clientes/clientes'
import { cadastrarFatura } from 'services/faturas/fatura/cadastrarFatura'
import * as yup from 'yup'

const View = ({ refreshFatura }) => {
  const [queryCliente, setQueryCliente] = useState('')
  const [disable, setDisable] = useState(false) 

  const schemaFatura = yup
    .object({
      valor: yup.number('Apenas numeros').min(7.5).required(),
      TUSD: yup.number('Apenas numeros').required(),
      TE: yup.number('Apenas numeros').required(),
      id_cliente: yup.string().required('O campo é obrigatório.'),
      vencimento: yup.date().required('O campo é obrigatório.'),
      consumo: yup.string().required('O campo é obrigatório.'),
      desconto: yup.number('Apenas numeros').required(),
    })
    .required('O campo é obrigatório.')

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schemaFatura)
  })
  const openModalFatura = () => setIsOpen(true)
  const closeModalFatura = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)

  const gerarContratoMutation = useMutation(cadastrarFatura)

  const [mesRef, setMesRef] = useState('01')

  const optionsMesRef = [
    { value: '01', name: '01' },
    { value: '02', name: '02' },
    { value: '03', name: '03' },
    { value: '04', name: '04' },
    { value: '05', name: '05' },
    { value: '06', name: '06' },
    { value: '07', name: '07' },
    { value: '08', name: '08' },
    { value: '09', name: '09' },
    { value: '10', name: '10' },
    { value: '11', name: '11' },
    { value: '12', name: '12' },
  ]


  function gerarFatura(data) {
    let dateVencimento = format(data.vencimento, 'dd-MM-yyyy')

    data.vencimento = dateVencimento

    gerarContratoMutation.mutate(data, {
      onSuccess: () => {
        setDisable(true)
        refreshFatura()
        showToast({
          type: 'success',
          message: 'Fatura gerada com sucesso'
        })
        closeModalFatura()
        reset({})
        setDisable(false)
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        })
      }
    })
  }
 
  const [optionsContratos, setOptionsContratos] = useState([])

  const { data: clientes = [] } = useQuery(
    ['clientes', queryCliente],
    () => getClientesParams('nome_completo', queryCliente),
    {
      retry: false
    }
  )
  useEffect(() => {
    setValue('id_contrato', optionsContratos?.[0]?.value)
  }, [optionsContratos, setValue])

  function populateContrato(idCliente) {
    const clienteEncontrado = clientes.find((cliente) => cliente.id === idCliente)

    if (!clienteEncontrado) {
      return []
    }

    const contratosCliente = clienteEncontrado.contratos.map((contrato) => ({
      name: contrato.endereco+' - '+contrato.numeroInstalacao,
      value: contrato.uuId
    }))

    setOptionsContratos(contratosCliente)
  }

  const [vencimento, setVencimento] = useState(new Date())

  //valida a lista -> passar no itens
  const derivado = clientes.filter((cliente) => {
    return cliente.nome_completo != null
  })
 
  const getVencimento = (dateVencimento) => {
    setVencimento(dateVencimento)
  }

  useEffect(() => {
    setValue('vencimento', vencimento)
  }, [setValue, vencimento])


  function onOptionClicked(item) {
    setValue('id_cliente', item.id)
    populateContrato(item.id)
  }


  function handleChange(e) {
    setMesRef(e.target.value)
  }

  function handleQuery(value) {
    setQueryCliente(value)
  }

  function click() {
    const result = Object.values({errors});
    const result2 =  Object.values(result[0]);
    console.log(result2.length)
    if(result2.length < 1){
      setDisable(true)
      setTimeout(() => {
        setDisable(false);
      }, 8000);
    }
  }
  return (
    <Box paddingSize="none" className="mb-md">
      <Button onClick={openModalFatura}>Nova fatura</Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModalFatura}
        width={600}
        label="Testando modal"
      >
        <form onSubmit={handleSubmit(gerarFatura)}>
          <Box
            paddingType="square"
            paddingSize="sm"
            border="xs"
            borderColor="neutral100"
            style={{ borderBottom: 'none' }}
          >
            <Title size="body01" weight="bold" color="neutral800">
              Nova Fatura
            </Title>
          </Box>
          <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
            <Box>
              <Row>
                <Col xs={12} md={12} className="mb-sm">
                  <Autocomplete
                    label="Selecione um cliente"
                    items={derivado}
                    onOptionClicked={(selectedItem) => onOptionClicked(selectedItem)}
                    getItems={handleQuery}
                    fields={{ id: 'cpf', name: 'nome_completo' }}
                  />
                  <input type="hidden" {...register('id_cliente')} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} >
                  {optionsContratos.length > 0 ? (
                    <Select
                      size="sm"
                      label="Contratos"
                      options={optionsContratos}
                      {...register('id_contrato')}
                      errors={errors}
                    />
                  ) : (
                    'Cliente não possui contratos'
                  )}
                </Col>
              </Row>
            </Box>
            <Box>
              <Title as="h4" size="display04" className="mb-xxxs">
                Dados da cobrança
              </Title>
              <SeparationLine />
            </Box>
            <Box>
              <Row>
              <Col xs={12} md={6} className="mb-sm">
                  <Datepicker
                    name="vencimento"
                    label="Data do vencimento"
                    vencimento={vencimento}
                    selected={vencimento}
                    {...register('vencimento')}
                    onChange={(e) => {
                      getVencimento(e)
                    }}
                    dateFormat="dd-MM-yyyy"
                  />
                </Col>
                <Col xs={12} md={6} className="">
                <Select
                    size="sm"
                    label="Mes Referencia"
                    className="mb-sm"
                    name="mes_referencia"
                    value={mesRef}
                    {...register('mes_referencia')}
                    onChange={handleChange}
                    options={optionsMesRef}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-sm" >
                <Input
                    label="Valor da fatura"
                    name="valor"
                    {...register('valor', {
                      required: 'valor é obrigatório',
                      maxLength: { value: 10, message: 'valor máximo 10' }
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
              <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="TUSD em R$"
                    name="TUSD"
                    {...register('TUSD', {
                      required: 'TUSD é obrigatório',
                      maxLength: { value: 10, message: 'valor máximo 10' }
                    })}
                    errors={errors}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="TE em R$"
                    name="TE"
                    {...register('TE', {
                      required: 'TE é obrigatório',
                      maxLength: { value: 10, message: 'valor máximo 10' }
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="Consumo"
                    name="consumo"
                    type="number"
                    {...register('consumo', {
                      required: 'consumo é obrigatório',
                      maxLength: { value: 10, message: 'valor máximo 10' }
                    })}
                    errors={errors}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="Desconto"
                    name="desconto"
                    type="text"
                    {...register('desconto', {
                      required: 'desconto é obrigatório',
                      maxLength: { value: 10, message: 'valor máximo 10' }
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
              <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="TUSD em Kwh"
                    name="tusd_kwh"
                    {...register('tusd_kwh', {
                      required: 'tusd_kwh é obrigatório',
                      maxLength: { value: 10, message: 'valor máximo 10' }
                    })}
                    errors={errors}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-sm">
                  <Input
                    label="TE em Kwh"
                    name="te_kwh"
                    {...register('te_kwh', {
                      required: 'te_kwh é obrigatório',
                      maxLength: { value: 10, message: 'valor máximo 10' }
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Box>
          </Box>
          <Box
            paddingType="squish"
            paddingSize="sm"
            border="xs"
            borderColor="neutral100"
            style={{ display: 'flex', borderTop: 'none' }}
          >
            <Button
              className="mr-auto"
              color="primary"
              appearance="ghost"
              onClick={closeModalFatura}
            >
              Cancelar
            </Button>

            <Button className="ml-auto"  
            onClick={click}
            style={{
              backgroundColor: 'blue',
              color: 'white',
              opacity: disable ? 0.5 : 1,
              borderRadius: '4px',
              alignSelf: 'center',
              padding: '10px'
            }}
            >
            {disable && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {disable && <span>Carregando</span>}
          {!disable && <span>Salvar</span>} </Button>
          </Box>
        </form>
      </Modal>
    </Box>
  )
}

export default View