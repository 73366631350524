import React from 'react'

import View from './View.jsx'

const Container = ({ admData , quantidade, rebateId}) => {

  return <View title="Lista Clientes" admData={admData} quantidade={quantidade} rebateId={rebateId}/>
}

export default Container
