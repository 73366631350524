import React, { forwardRef } from 'react'

import { Input as InputSDL } from '@superlogica/design-system'
import { string, object, func } from 'prop-types'

import masks from '../../utils/mask'

const Input = forwardRef(
  ({ name, mask, errors, onChange: customOnChange, afterOnChange, ...rest }, ref) => {
    const applyMask = (event) => {
      const { value } = event.target

      if (mask && masks[mask]) {
        event.target.value = masks[mask](value)
      }
    }

    return (
      <InputSDL
        ref={ref}
        name={name}
        status={errors?.[name] && 'danger'}
        message={errors?.[name]?.message}
        onChange={(event) => {
          applyMask(event)
          customOnChange?.(event)
          afterOnChange?.(event)
        }}
        {...rest}
      />
    )
  }
)

Input.displayName = 'Input'

Input.defaultProps = {
  marginBottom: 'sm',
  floatingMessage: true
}

Input.propTypers = {
  name: string,
  mask: string,
  onChange: func,
  errors: object
}

export default Input
