import { useTranslation } from 'react-i18next'

const LanguageSwitch = () => {
  const { i18n } = useTranslation()

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  return (
    <>
      <button onClick={() => changeLanguage('pt-BR')}>PT</button>
      <button onClick={() => changeLanguage('en')}>EN</button>
    </>
  )
}

export default LanguageSwitch
