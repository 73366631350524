import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Box } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { getSingleClientes } from 'services/clientes/cliente/singleCliente'
import { getContratosLista } from 'services/contratos/getContratosLista'
import { BoxContainer } from 'styles/global-style'

import CadastrarFatura from './CadastrarFatura/Container'
import DadosCliente from './DadosCliente/Container'
import ListaContratos from './ListaContratos/Container'
import ListaFaturas from './ListaFaturas/Container'
import NovoContrato from './NovoContrato/Container'

const SingleCliente = () => {
  const { id } = useParams()

  const { data: cliente = [] } = useQuery(['clientes', id], () => getSingleClientes(id), {
    retry: false
  })

  const { data = [] } = useQuery(['getContratos', id], () => getContratosLista(id), {
    retry: false
  })

  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="none">
        <DadosCliente id={id} data={cliente} />
      </BoxContainer>

      <BoxContainer paddingSize="lg">
        <Box paddingSize="none" className="mb-md flex">
          <NovoContrato data={data} uuId={id} clientes={cliente} />
          <CadastrarFatura data={data} uuId={id} clientes={cliente} />
        </Box>
        <Box paddingSize="none" className="mb-md">
          <ListaContratos data={data} />
        </Box>
        <Box paddingSize="none" className="mb-md">
          <ListaFaturas id={id} />
        </Box>
      </BoxContainer>
    </>
  )
}

export default SingleCliente
