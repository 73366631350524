import {  useState } from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { useQuery } from 'react-query'

import { Title } from '@superlogica/design-system'
import { Button } from '@superlogica/design-system'
import { BoxCounter } from 'components/BoxCounter/BoxCounter'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import Exportar from 'pages/Exportar faturas/components/ReactCSV'
import { getCountFatura } from 'services/getCount'
import { BoxContainer } from 'styles/global-style'

import ImportarFaturas from './ImportarFaturas/Container'
import ListaFaturas from './ListaFaturas/Container'
import NovaFatura from './NovaFatura/Container'


const AllFaturas = () => {
  const { data: dataFatura = [] } = useQuery(['getCountFatura'], () => getCountFatura())
  const [inputValue, setInputValue] = useState('')

  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="lg">
        <Title color="neutral800" size="display03" className="mb-sm">
          Faturas
        </Title>
        <div style={{ display: 'flex'}} >
       <NovaFatura />
        <ImportarFaturas />
        <Exportar />
       </div>
        <Row className="mb-xl">
        <Col xs={12} md={3}>
        <Button  style={{ backgroundColor: '#fff', width:'100%', border: '0px solid #828584'}} onClick={(e) => {
               setInputValue('Fatura Paga') 
            }}   className="mr-xxxs">
             <BoxCounter valor={dataFatura.Pagas} categoria="Faturas Pagas" color="green700" />
            </Button>
        </Col>
        <Col xs={12} md={3}>
        <Button  style={{ backgroundColor: '#fff', width:'100%', border: '0px solid #828584'}} onClick={(e) => {
               setInputValue('Fatura enviada') 
            }}   className="mr-xxxs">
            <BoxCounter
            valor={dataFatura.Enviadas}
            categoria="Faturas Enviadas"
            color="neutral800"
          />
            </Button>
        </Col>
        <Col xs={12} md={3}>
        <Button  style={{ backgroundColor: '#fff', width:'100%', border: '0px solid #828584'}} onClick={(e) => {
               setInputValue('Fatura Atrasada') 
            }}   className="mr-xxxs">
           <BoxCounter
            valor={dataFatura.Atrasadas}
            categoria="Faturas Atrasadas"
            color="red700"
          />
            </Button>
        </Col>
        <Col xs={12} md={3}>
        <Button  style={{ backgroundColor: '#fff', width:'100%', border: '0px solid #828584'}} onClick={(e) => {
               setInputValue('Cancelada') 
            }}   className="mr-xxxs">
          <BoxCounter
            valor={dataFatura.Canceladas}
            categoria="Faturas Canceladas"
            color="yellow700"
          />
            </Button>
        </Col>
      </Row>
        <ListaFaturas status={inputValue} />
      </BoxContainer>
    </>
  )
}

export default AllFaturas
