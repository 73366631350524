/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, showToast, Datepicker, Select } from '@superlogica/design-system'
import Input from 'components/Input'
import { format } from 'date-fns'
import { alterarCliente } from 'services/clientes/cliente/alterarCliente'
import * as yup from 'yup'

const View = ({ textData, closeModal }) => {
  const schemaCliente = yup
    .object({
      nome_completo: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      email: yup.string().email('E-mail inválido.').required('campo é obrigatório'),
      telefone: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      cpf: yup
        .string('Caracteres inválidos!')
        .required('O campo é obrigatório.')
        .length(14, 'Máximo de 14 caracteres'),
      rg: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      valor_ultima_conta: yup
        .string('Caracteres inválidos!')
        .required('O campo é obrigatório.'),
      nacionalidade: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      data_nascimento: yup.date().required('O campo é obrigatório.'),
      profissao: yup.string('Caracteres inválidos!').required('O campo é obrigatório.'),
      estado_civil: yup.string('Caracteres inválidos!').required('O campo é obrigatório.')
    })
    .required('O campo é obrigatório.')
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schemaCliente),
    defaultValues: textData
  })

  const queryClient = useQueryClient()

  function refreshClienteSingle() {
    queryClient.invalidateQueries(['clientes'])
  }

  const optionsTipoCliente = [
    { value: 'titular', name: 'Titular' },
    { value: 'nao-titular-erp', name: 'Não Titular ERP' },
    { value: 'sindico', name: 'Síndico' }
  ]
console.log(textData)
  const [estadoCivil, setEstadoCivil] = useState(textData.estado_civil)
  const [nascimento, setStartDate] = useState(new Date(textData.data_nascimento))
  const [tipoCliente, setTipoCliente] = useState(textData.tipo)

  const getDateInicial = (dateInicial) => {
    setStartDate(dateInicial)
  }

  useEffect(() => {
    setValue('data_nascimento', nascimento)
  }, [nascimento])

  const optionsEstadoCivil = [
    { value: 'Solteiro(a)', name: 'Solteiro(a)' },
    { value: 'Casado(a)', name: 'Casado(a)' },
    { value: 'Viúvo(a)', name: 'Viúvo(a)' },
    { value: 'Separado(a)', name: 'Separado(a)' },
    { value: 'Divorciado(a)', name: 'Divorciado(a)' },
    { value: 'União Estável', name: 'União Estável' }
  ]

  function handleChange(e) {
    setEstadoCivil(e.target.value)
  }


  function handleChangeTipoCliente(e) {
    setTipoCliente(e.target.value)

  }

  const editClienteMutation = useMutation(alterarCliente)

  function submitCliente(data) {
    let dateNascimento = format(data.data_nascimento, 'dd/MM/yyyy')
    data.data_nascimento = dateNascimento
    console.log('dateNascimento', dateNascimento)
    editClienteMutation.mutate(data, {
      onSuccess: () => {
        refreshClienteSingle()
        showToast({
          type: 'success',
          message: 'Cliente atualizado com sucesso'
        })
        closeModal()
        reset({})
      },
      onError: (err) => {
        console.log(err.response.data)
        showToast({
          type: 'error',
          message: 'Cliente não atualizado'
        })
      }
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitCliente)}>
        <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
          {/* {editClienteMutation.isError ? (
          <div>An error occurred: {editClienteMutation.error.message}</div>
        ) : null} */}
          <input type="hidden" value={textData.id} {...register('id')} />
          <Input
            label="Informe seu nome"
            className="mb-sm"
            type="text"
            {...register('nome_completo', {
              required: 'O nome completo é obrigatório',
              maxLength: {
                value: 100,
                message: 'Nome excede o número de caracteres permitidos. Tamanho máximo 100'
              }
            })}
            errors={errors}
          />
          <Input
            label="Informe seu e-mail"
            className="mb-sm"
            type="text"
            defaultValue={textData.email}
            {...register('email', {
              required: 'O email é obrigatório',
              maxLength: {
                value: 50,
                message: 'Email excede o número de caracteres permitidos. Tamanho máximo 50 '
              }
            })}
            errors={errors}
          />
          <Input
            label="Informe seu cpf"
            className="mb-sm"
            type="text"
            mask="cpf"
            {...register('cpf', {
              required: 'O cpf é de preenchimento obrigatório',
              maxLength: { value: 15, message: 'valor maximo 15' }
            })}
            errors={errors}
            maxlength={11}
          />
          <Input
            label="Orgão emissor"
            className="mb-sm"
            type="text"
            {...register('orgaoEmissor', {
              required: 'O orgão emissor é de preenchimento obrigatório'
            })}
            errors={errors}
            maxlength={11}
           />
          <Input
            label="Informe seu telefone"
            className="mb-sm"
            type="telefone"
            mask="phone"
            defaultValue={textData.telefone}
            {...register('telefone', {
              required: 'O número do telefone é obrigatório',
              maxLength: { value: 15, message: 'valor maximo 15' }
            })}
            errors={errors}
          />
          <Input
            label="Informe valor ultima conta"
            className="mb-sm"
            type="text"
            mask="money"
            defaultValue={textData.valor_ultima_conta}
            {...register('valor_ultima_conta', {
              required: 'Valor da última conta é obrigatório',
              maxLength: { value: 10, message: 'valor maximo 10' }
            })}
            errors={errors}
          />
          <Input
            label="Informe seu RG"
            className="mb-sm"
            type="text"
            mask="rg"
            defaultValue={textData.rg}
            {...register('rg', {
              required: 'O rg é obrigatório',
              maxLength: { value: 12, message: 'valor maximo 12' }
            })}
            errors={errors}
          />
          <Input
            label="Informe sua nacionalidade"
            className="mb-sm"
            type="text"
            defaultValue={textData.nacionalidade}
            {...register('nacionalidade', {
              required: true,
              maxLength: { value: 20, message: 'valor maximo 20' }
            })}
            errors={errors}
          />
          <Input
            label="Informe sua profissão"
            className="mb-sm"
            type="text"
            defaultValue={textData.profissao}
            {...register('profissao', {
              required: true,
              maxLength: { value: 25, message: 'valor maximo 25' }
            })}
            errors={errors}
          />
             <Select
                size="sm"
                label="Tipo Cliente"
                className="mb-sm"
                name="tipo"
                value={tipoCliente}
                {...register('tipo')}
                onChange={handleChangeTipoCliente}
                options={optionsTipoCliente}
              />
          <Row>
            <Col xs={12} md={6} className="mb-sm">
              <Datepicker
                name="data_nascimento"
                label="Data de nascimento"
                startDate={nascimento}
                selected={nascimento}
                {...register('data_nascimento')}
                onChange={(e) => {
                  getDateInicial(e)
                }}
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col xs={12} md={6} className="mb-sm">
              <Select
                size="sm"
                label="Estado civil"
                className=""
                name="estado_civil"
                value={estadoCivil}
                {...register('estado_civil')}
                onChange={handleChange}
                options={optionsEstadoCivil}
              />
            </Col>
          </Row>
          {textData.cpf_titular != null && (
              <Input
              label="Cpf do titular"
              className="mb-sm"
              type="text"
              mask="cpf"
              {...register('cpf_titular', {
                required: 'O cpf do titular é de preenchimento obrigatório',
                maxLength: { value: 15, message: 'valor maximo 15' }
              })}
              errors={errors}
              maxlength={11}
            />
           )}

          
        </Box>
        <Box
          paddingType="squish"
          paddingSize="sm"
          border="xs"
          borderColor="neutral100"
          style={{ display: 'flex', borderTop: 'none' }}
        >
          <Button
            className="mr-auto"
            color="primary"
            appearance="outline"
            onClick={closeModal}
          >
            Cancelar
          </Button>
          <Button className="ml-auto">Salvar</Button>
        </Box>
      </form>
    </>
  )
}

export default View
