import { Row, Col } from 'react-awesome-styled-grid'

import { Input, Box, Select } from '@superlogica/design-system'

const View = ({ register }) => {
  const pagamentos = [
    { value: 'Cartao', name: 'Cartão' },
    { value: 'Boleto', name: 'Boleto' },
    { value: 'Boletao', name: 'Boletão' }
  ]

  return (
    <>
      <Box>
      <Row >
        <Col xs={12} md={6} style={{ marginBottom: 16 }}>
          <Input label="Vencimento da Cobrança" {...register('vencimento_boleto')} />
        </Col>
        <Col xs={12} md={6} className="mb-sm">
          <Select
            size="sm"
            label="Método de pagamento"
            {...register('meio_pagamento')}
            options={pagamentos}
          />
        </Col>
      </Row>
      </Box>
      
    </>
  )
}

export default View
