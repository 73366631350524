/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import {
  Box,
  Datepicker,
  Input,
  Title,
  Button,
  Modal,
  showToast
} from '@superlogica/design-system'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { format } from 'date-fns'
import { getSingleClientes } from 'services/clientes/cliente/singleCliente'
import { getSingleContratos } from 'services/contratos/contrato/getSingleContratos'
import { cadastrarFatura } from 'services/faturas/fatura/cadastrarFatura'

const View = ({ idCliente, idContrato, refreshSingleFatura }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [vencimento, setVencimento] = useState(new Date())

  const { register, handleSubmit, setValue, reset } = useForm()
  const openModalFatura = () => setIsOpen(true)
  const closeModalFatura = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false)

  const { data: cliente = [] } = useQuery('getSingle', () => getSingleClientes(idCliente), {
    retry: false
  })

  const { data: contrato = [] } = useQuery(
    'getContratoSingle',
    () => getSingleContratos(idContrato),
    { retry: false }
  )

  // console.log('Cadastrar fatura cliente', cliente)
  // console.log('Cadastrar fatura contrato', contrato)

  const singleFaturaMutation = useMutation(cadastrarFatura)
  function singleFatura(data) {
    let dateInicio = format(data.leitura_inicio, 'dd/MM/yyyy')
    let dateFinal = format(data.leitura_fim, 'dd/MM/yyyy')
    let dateVencimento = format(data.vencimento, 'dd/MM/yyyy')

    data.leitura_inicio = dateInicio
    data.leitura_fim = dateFinal
    data.vencimento = dateVencimento

    singleFaturaMutation.mutate(data, {
      onSuccess: () => {
        refreshSingleFatura()
        showToast({
          type: 'success',
          message: 'Fatura gerada com sucesso'
        })
        closeModalFatura()
        reset({})
      }
    })
  }

  const getDateInicial = (dateInicial) => {
    setStartDate(dateInicial)
  }

  useEffect(() => {
    setValue('leitura_inicio', startDate)
  }, [startDate])

  const getDateFinal = (dateFinal) => {
    setEndDate(dateFinal)
  }

  useEffect(() => {
    setValue('leitura_fim', endDate)
  }, [endDate])

  const getVencimento = (dateVencimento) => {
    setVencimento(dateVencimento)
  }

  useEffect(() => {
    setValue('vencimento', vencimento)
  }, [vencimento])

  return (
    <>
      <Box paddingSize="none" className="mb-md">
        <Button onClick={openModalFatura}>Nova fatura</Button>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModalFatura}
          width={600}
          label="Testando modal"
        >
          <form onSubmit={handleSubmit(singleFatura)}>
            <input type="hidden" value={contrato?.uuId} {...register('id_contrato')} />
            <Box
              paddingType="square"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ borderBottom: 'none' }}
            >
              <Title size="body01" weight="bold" color="neutral800">
                Nova Fatura
              </Title>
            </Box>
            <Box paddingType="square" paddingSize="sm" border="xs" borderColor="neutral100">
              <Box>
                <Input type="text" className="mb-sm" label={cliente?.nome_completo} disabled />
                <Input type="text" className="mb-sm" label={contrato?.endereco} disabled />
              </Box>
              <Box>
                <Title as="h4" size="display04" className="mb-xxxs">
                  Dados da cobrança
                </Title>
                <SeparationLine />
              </Box>
              <Box>
                <Row>
                  <Col xs={12} md={4} className="mb-sm">
                    <Datepicker
                      name="data-inicio"
                      label="Data inicial"
                      startDate={startDate}
                      selected={startDate}
                      {...register('leitura_inicio')}
                      onChange={(e) => {
                        getDateInicial(e)
                      }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                  <Col xs={12} md={4} className="mb-sm">
                    <Datepicker
                      name="data-final"
                      label="Data Final"
                      endDate={endDate}
                      selected={endDate}
                      {...register('leitura_fim')}
                      onChange={(e) => {
                        getDateFinal(e)
                      }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                  <Col xs={12} md={4} className="mb-sm">
                    <Datepicker
                      name="vencimento"
                      label="Data do vencimento"
                      vencimento={vencimento}
                      selected={vencimento}
                      {...register('vencimento')}
                      onChange={(e) => {
                        getVencimento(e)
                      }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4} className="mb-sm">
                    <Input label="Valor" name="valor" type="text" {...register('valor')} />
                  </Col>
                </Row>
              </Box>
            </Box>
            <Box
              paddingType="squish"
              paddingSize="sm"
              border="xs"
              borderColor="neutral100"
              style={{ display: 'flex', borderTop: 'none' }}
            >
              <Button
                className="mr-auto"
                color="primary"
                appearance="ghost"
                onClick={closeModalFatura}
              >
                Cancelar
              </Button>

              <Button className="ml-auto">Salvar</Button>
            </Box>
          </form>
        </Modal>
      </Box>
    </>
  )
}

export default View
