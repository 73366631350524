import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'

import { Toastr } from '@superlogica/design-system'
import { Router } from 'navigation'

import { Main } from './components/layouts'

import 'styles/main-style.css'

const App = () => (
  <>
    <BrowserRouter>
      <Main>
        <Router />
        <Toastr />
        {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools />}
      </Main>
    </BrowserRouter>
  </>
)

export default App
