import styled from 'styled-components'

const OptionsBox = styled.div`
  max-height: 150px;
  width: 90%;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #ffffff;
  border: 1px solid #c1cad6;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  position: absolute;
  margin-top: 40px;
  z-index: 9999;
  overflow-y: auto;
  ${({ isOpen }) => `
   display:  ${isOpen ? 'block' : 'none'};
  `}
`

const Option = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;

  &:hover {
    background-color: #f3f4f7;
    cursor: pointer;
  }
`

export { OptionsBox, Option }
