import { Text, Box } from '@superlogica/design-system'
import { spacing, borderRadius } from '@superlogica/foundation/tokens'
import styled from 'styled-components'

export const BottonNavigation = styled('div')`
  display: inline;

  Button {
    margin-right: 10px;
  }
`
export const SidebarContainer = styled.div`
  width: 80px;
  background: #323b49;
  color: #677679;
  position: fixed;
  z-index: 1000;
  min-height: 100%;

  Button {
    margin-bottom: 16px;
    width: 100%;
  }
`
export const ItemMenu = styled('li')`
  display: block;
`

export const LinkMenu = styled('Link')`
  text-align: center;
`
export const BoxMenu = styled('div')`
  text-align: center;
  background: #323b49;
  padding: 16px 0;
  height: 80px;
  color: #fff;
`
export const BoxMenuLogo = styled('div')`
  text-align: center;
  background: #7acbee;
  padding: 16px 0;
  height: 64px;
  color: #fff;
`
export const HeaderTopbar = styled(Box)`
  height: 64px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 16%);
`
export const TextUser = styled(Text)`
  padding: 0 ${spacing.horizontal.xxxs}px;
`
export const BoxUser = styled(Box)`
  float: right;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  border-radius: ${borderRadius.pill};

  Button {
    padding: 0 ${spacing.horizontal.xxs}px;
  }
`
export const BoxIcon = styled(Box)`
  border-radius: ${borderRadius.pill};
  width: ${spacing.horizontal.md}px;
  height: ${spacing.vertical.md}px;
  text-align: center;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  margin-left: -12px;
  padding: 0;
`
export const BoxContainer = styled(Box)`
  margin-left: 80px;
`
export const BoxTitleTable = styled(Box)`
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`
export const BoxTabTable = styled(Box)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`
export const BoxClienteIcon = styled(Box)`
  width: 64px;
  height: 64px;
  border-radius: ${borderRadius.pill};
`
