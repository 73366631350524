import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { Text } from '@superlogica/design-system'
import { SendEmail1 } from '@superlogica/design-system-icons/web/erp'
import { Search } from '@superlogica/design-system/Icons/superlogica'
import { format } from 'date-fns'
import { getFaturas } from 'services/faturas/faturas'
import { useDebouncedCallback } from 'use-debounce/lib'

import StatusCobranca from './components/StatusCobranca'
import View from './View'

const Container = (status) => {
  const [searchFatura, setSearchFatura] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  let statusFatura = status['status']

  const {
    data = [],
    isLoading,
    isError,
    refetch
  } = useQuery(['faturas', currentPage,searchFatura, statusFatura], () => getFaturas(currentPage,searchFatura, statusFatura))

  const setSingleFatura = useDebouncedCallback((value) => {
    setSearchFatura(value)
  }, 300)


  const faturas = data?.data ?? []
  const itemsPerPage = data?.itensPorPagina
  const totalItems = data?.totalItens
  const faturas2 = faturas

  function WhatsAppLink(props) {
    const { phone, message } = props
    const whatsappUrl = `https://wa.me/${phone}?text=${message}`
    return (
      <Link to={{ pathname: whatsappUrl }} target="_blank">
        {props.children}
      </Link>
    )
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'icon',
      align: 'center',
      width: '3%',
      Cell: ({ row }) => {
        const faturaId = faturas2[row.index].uuId
        return (
          <Link to={`single-fatura/${faturaId}`}>
            <Search height="16" width="16" />
          </Link>
        )
      }
    },
    {
      Header: 'Nome',
      accessor: 'nome_completo',
      align: 'left',
      Cell: ({ row }) => {
        return (
          faturas2[row.index].id_contrato.id_cliente.tipo === "sindico" &&  faturas2[row.index].id_contrato.tipoContrato !== "GD PF"?
         <Text> {faturas2[row.index].id_contrato.id_condominio.nome} </Text>
         :  <Text> {faturas2[row.index].id_contrato.id_cliente.nome_completo} </Text>
        )
      }
    },
    {
      Header: 'Distribuidora',
      accessor: 'distribuidora',
      align: 'left',
      Cell: ({ row }) => {
        return (
         <Text> {faturas2[row.index].id_contrato.id_distribuidora.nome} </Text>
        )
      }
    },
    {
      Header: 'Vencimento',
      accessor: 'vencimento',
      align: 'left',
      Cell: ({ row }) => {
        let formato = faturas2[row.index]?.vencimento.replace(/.000000Z/g, '')
        let date = new Date(formato )
        let dataFormatada = format(date, 'dd/MM/yyyy')
        return <Text>{dataFormatada}</Text>
      }
    },
    {
      Header: 'Liquidação',
      accessor: 'liquidação',
      align: 'left',
      Cell: ({ row }) => {
        let dataFormatada1 = 'Não liquidada'
        if(faturas2[row.index]?.dataLiquidacao !== null){
          let formato = faturas2[row.index]?.dataLiquidacao.replace(/.00+00:00/g, '')
          let date1 = new Date(formato )
          dataFormatada1 = format(date1, 'dd/MM/yyyy')
        }
        return <Text>{dataFormatada1}</Text>
      }
    },
    {
      Header: 'Status',
      accessor: 'status_cobranca',
      align: 'left',
      Cell: ({ row }) => <StatusCobranca fatura={row.original} />
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      align: 'left',
      Cell: ({ row }) => {
        let atual = faturas2[row.index]?.valor
        let formatada = atual
          .toLocaleString('pt-br', { style: 'currency', currency: 'R$' })
          .replace('.', ',')
        return <Text>{`R$ ${formatada}`}</Text>
      }
    },
    {
      hideSort: true,
      accessor: 'whatsapp',
      align: 'center',
      width: '3%',
      Cell: ({ row }) => {
        let formato = faturas2[row.index]?.vencimento.replace(/.000000Z/g, '')
        let date = new Date(formato )
        const nome = faturas2[row.index].id_contrato.id_cliente.nome_completo
        const telefone = faturas2[row.index].id_contrato.id_cliente.telefone 
        const status = faturas2[row.index].status_cobranca
        const vencimento = format(date, 'dd/MM/yyyy')
        let mensagem = `Ol%C3%A1%20${nome},%20tudo%20bem?`
        if (status === "Fatura Atrasada") {
          mensagem = `${mensagem}%0AVimos%20que%20ainda%20n%C3%A3o%20pagou%20sua%20conta%20de%20energia%20com%20o%20vencimento%20${vencimento}.%20Precisa%20de%20alguma%20ajuda%3F
        `
        }

        return telefone ? (
          <WhatsAppLink phone={`55${telefone}`} message={mensagem}>
            <SendEmail1 height="16" width="16" />
          </WhatsAppLink>
        ) : (
          ''
        )
      }
    }
  ])

  const tableDataFatura = useMemo(() => faturas2, [faturas2])

  return (
    <View
      title="Display COntador Faturas"
      isError={isError}
      isLoading={isLoading}
      tableDataFatura={tableDataFatura}
      columns={columns}
      setSingleFatura={setSingleFatura}
      refetch={refetch}
      totalItems={totalItems}
      itemsPerPage={itemsPerPage}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  )
}

export default Container
