/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { Link } from 'react-router-dom'

import { Box, Button, Text, showToast } from '@superlogica/design-system'
import {
  ArrowRight1,
  BusinessContractHandshakeSign,
  SendEmail2
} from '@superlogica/design-system/Icons/superlogica'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { format } from 'date-fns'
import { reenviarFaturas } from 'services/faturas/faturas'
import { consultar } from 'services/faturas/faturas'
import { BoxClienteIcon } from 'styles/global-style'

import EditarStatusFatura  from '../EditarStatusFatura/Container'


const View = ({ data, uuId }) => {
  let formato = data?.vencimento.replace(/.000000Z/g, '')
  let date = new Date(formato )
  let dataFormatada = format(date, 'dd/MM/yyyy')
  let dataFormatada1 = 'Não liquidada'
        if(data?.dataLiquidacao !== null){
          let formato = data?.dataLiquidacao.replace(/.00+00:00/g, '')
          let date1 = new Date(formato )
          dataFormatada1 = format(date1, 'dd/MM/yyyy')
        }

  let value = data?.valor

  let valorFormatado = value
    .toLocaleString('pt-br', { style: 'currency', currency: 'R$' })
    .replace('.', ',')

  async function handleClick() {
    try {
      const data = await reenviarFaturas(uuId)
      showToast({
        type: 'success',
        message: 'Enviado com sucesso'
      })
    } catch (error) {
      showToast({
        type: 'error',
        message: 'Erro ao reenviar'
      })
    }
  }
  
  const consultarFaturaMutation = useMutation(consultar)

  function handleClick2() {
    consultarFaturaMutation.mutate(uuId, {
      onSuccess: (data) => {
        showToast({
          type: 'success',
          message: data.message
        })
      },
      onError: (err) => {
        showToast({
          type: 'error',
          message: err.response.data.message
        })
      }
    })
  }


  return (
    <>
      <Box
        border
        borderColor="neutral100"
        bgColor="neutral0"
        paddingSize="lg"
        paddingType="squish"
        radius="xs"
      >
        <Box paddingSize="none" className="flex align-center mb-xs">
          <Text color="primary" className="mr-xxxxs" size="label02">
            <Link to={`../all-faturas`}>Fatura</Link>
          </Text>
          <ArrowRight1 className="mr-xxxxs" width="10" height="10" fill="neutral600" />
          <Text size="label02">{data?.pedido_numero}</Text>
        </Box>

        <Box paddingSize="none" className="flex">
          <BoxClienteIcon
            paddingSize="none"
            bgColor="neutral50"
            radius="md"
            paddingType="square"
            className="flex align-center mr-xs"
          >
            <BusinessContractHandshakeSign
              width="23"
              height="23"
              fill="neutral600"
              className="mr-auto ml-auto"
            />
          </BoxClienteIcon>
          <Box paddingSize="none">
            <Box paddingSize="none" className="flex align-center mt-xxxs mb-xs">
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Cliente
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.id_contrato?.id_cliente?.nome_completo}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  CPF
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.id_contrato?.id_cliente?.cpf}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Cod. Consumidor
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.id_contrato?.codigo_do_consumidor}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Distribuidora
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.id_contrato?.id_distribuidora?.nome}
                </Text>
              </Box>
            </Box>
            <SeparationLine />
            <Box paddingSize="none" className="flex align-center mt-xxxs mb-xs">
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Endereço
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.id_contrato?.endereco}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Cidade
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.id_contrato?.cidade}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  E-mail
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.id_contrato?.id_cliente?.email}
                </Text>
              </Box>
            </Box>
            <SeparationLine />
            <Box paddingSize="none" className="flex align-center mt-xxxs mb-xs">
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Data Vencimento
                </Text>
                <Text color="neutral600" size="body02">
                  {dataFormatada}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Data Liquidação
                </Text>
                <Text color="neutral600" size="body02">
                  {dataFormatada1}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Status Pagamento
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.status_cobranca}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Valor
                </Text>
                <Text color="neutral600" size="body02">
                  {`R$ ${valorFormatado}`}
                </Text>
              </Box>
              <Box paddingSize="none" className="flex align-center mr-xs">
                <Text color="neutral600" size="body02" className="mr-xxxs" weight="bold">
                  Numero do Boleto
                </Text>
                <Text color="neutral600" size="body02">
                  {data?.nosso_numero}
                </Text>
              </Box>
            </Box>
            <SeparationLine />
          </Box>
        </Box>
        <Box>
        {
          data?.status_cobranca !== "Fatura Paga" ?
          <>
          <Button
            appearance="solid"
            size="sm"
            className="mr-xxs"
            onClick={handleClick}
            leftIcon={<SendEmail2 />}
          >
            Reenviar Fatura
          </Button>
        </> : ""
        }
        <EditarStatusFatura uuId={uuId} data={data}>

        </EditarStatusFatura>
        <Button
             appearance="solid"
             size="sm"
             className="ml-xxs"
            onClick={(e) => {
              e.preventDefault()
              window.location.href = data['link_boleto']
            }}
          >
            Visualizar fatura
          </Button>
          <Button
             appearance="solid"
             size="sm"
             className="ml-xxs"
             onClick={handleClick2}
          >
            Consultar status
          </Button>
         </Box>
      </Box>
    </>
  )
}

export default View
