import { api } from 'services/api'

export const enviarParceiro = async (idContrato) => {
  let url = `send/contratos/${idContrato}`

  const response = await api.post(url)

  return response.data.data
}

export const getStatus = async (idContrato) => {
  let url = `get/statusContrato/${idContrato}`

  const response = await api.get(url)

  return response.data
}

