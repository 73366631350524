import 'i18n'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { datadogRum } from '@datadog/browser-rum'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloakClient from 'navigation/Auth/Keycloak'

import App from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_STAGE === 'master' || process.env.REACT_APP_STAGE === 'staging') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_ID,
    clientToken: process.env.REACT_APP_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_STAGE,
    sampleRate: 100,
    premiumSampleRate: 0,
    trackInteractions: true,
    defaultPrivacyLevel: process.env.REACT_APP_DATADOG_PRIVACY
  })
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false
    }
  }
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <ReactKeycloakProvider
        authClient={keycloakClient}
        LoadingComponent={<div>Carregando...</div>}
      >
        <App />
      </ReactKeycloakProvider>
    </React.StrictMode>
  </QueryClientProvider>,
  document.getElementById('root')
)

reportWebVitals()
