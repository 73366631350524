import React from 'react'

import View from './View'

const Container = ({
  queryDistribuidoras,
  queryCondominios,
  queryCooperativas,
  register,
  setValue
}) => {
  return (
    <View
      title="Cadastro Dados do Contrato"
      queryDistribuidoras={queryDistribuidoras}
      queryCondominios={queryCondominios}
      queryCooperativas={queryCooperativas}
      register={register}
      setValue={setValue}
    />
  )
}

export default Container
