import { api } from 'services/api'

export const cadastrarContrato = async (data) => {
  const url = 'contratos'
  const response = await api.post(url, { data })

  return response.data
}

export const cadastrarContratoPj = async (data) => {
  const url = 'contratos/novosContratos'
  const response = await api.post(url, { data })

  return response.data
}
