import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { useKeycloak } from '@react-keycloak/web'
import { Select, Box, Title, Input } from '@superlogica/design-system'
import SeparationLine from 'components/SeparationLine/SeparationLine'
import { getSingleClientes } from 'services/clientes/cliente/singleCliente'

const View = ({
  queryDistribuidoras,
  queryCondominios,
  queryCooperativas,
  queryClientes,
  register
}) => {
  const { keyclock } = useKeycloak()

  console.log('Keyclock', keyclock)

  const { data: cliente = [] } = useQuery(['getSingle', queryClientes?.id], () =>
    getSingleClientes(queryClientes?.id, keyclock.token)
  )

  const [tipoContrato, setTipoContrato] = useState('GD PF')

  function populateOptions(data2) {
    return data2.map((item2) => ({
      name: item2.nome,
      value: item2.id
    }))
  }

  const optionsCondominios = populateOptions(queryCondominios)
  const optionsCooperativas = populateOptions(queryCooperativas)
  const optionsDistribuidoras = populateOptions(queryDistribuidoras)
  const tiposContrato = [
    { value: 'GD PF', name: 'GD PF' },
    { value: 'GD PJ', name: 'GD PJ' },
    { value: 'ML PJ', name: 'ML PJ' },
    { value: 'GD PF - Funcionário', name: 'GD PF - Funcionário' }
  ]


  function handleChangeTipo(e) {
    setTipoContrato(e.target.value)
  }




  return (
    <>
      <Box>
        <Title as="h4" size="display04" className="mb-xxxs">
          Dados do cliente
        </Title>
        <SeparationLine />
      </Box>
      <Box>
        <Input label={cliente?.nome_completo} type="text" disabled />
        <input type="hidden" value={queryClientes?.id} {...register('id_cliente')} />
      </Box>
      <Box>
        <Select
          size="sm"
          name="condominios"
          label="Selecione um Condomínio"
          {...register('id_condominio')}
          options={optionsCondominios}
        />
      </Box>
      <Box>
        <Select
          size="sm"
          label="Selecione uma Distribuidora"
          name="distribuidoras"
          {...register('id_distribuidora')}
          options={optionsDistribuidoras}
        />
      </Box>
      <Box>
        <Select
          size="sm"
          label="Selecione uma Cooperativa"
          name="cooperativas"
          {...register('id_cooperativa')}
          options={optionsCooperativas}
        />
      </Box>
      <Box>
        <Select
          size="sm"
          label="Selecione o Tipo do Contrato"
          name="tipoContrato"
          value={tipoContrato}
          {...register('tipoContrato')}
          onChange={handleChangeTipo}
          options={tiposContrato}
        />
      </Box>
    </>
  )
}

export default View
