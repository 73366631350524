import MenuSidebar from './MenuSidebar/Container'

const Sidebar = () => {
  return (
    <>
      <MenuSidebar />
    </>
  )
}

export default Sidebar
