import React, { useState } from 'react'
import { Row, Col } from 'react-awesome-styled-grid'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Modal, showToast, Title } from '@superlogica/design-system'
import Input from 'components/Input'
import { cadastrarContratoPj } from 'services/contratos/contrato/cadastrarContrato'
import { getResources } from 'services/contratos/contratos'
import { useDebouncedCallback } from 'use-debounce/lib'
import './styles.css';
import * as yup from 'yup'

import DadosCobranca from '../Cadastrar/DadosCobranca/Container'
import DadosContrato from '../Cadastrar/DadosContrato/Container'
import DadosUnidade from '../Cadastrar/DadosUnidade/Container'

const View = ({refreshContrato}) => {
  const schemaContrato = yup
    .object({
      hash_unidade: yup.string().required('O campo é obrigatório.')
    })
    .required('O campo é obrigatório.')

  const { data: distribuidoras = [] } = useQuery('distribuidoras', () => getResources('distribuidoras'))
  const { data: cooperativas = [] } = useQuery('cooperativas', () =>  getResources('cooperativas')  )

  const [isOpen, setIsOpen] = useState(false)
  const [dataInstalacao, setDataInstalacao] = useState([])
  const openModalContrato = () => setIsOpen(true)
  const closeModalContrato = () => setIsOpen(false)
  const [step, setStep] = useState(1)

  const { register, handleSubmit ,reset, setValue, errors } = useForm({
    resolver: yupResolver(schemaContrato)
  })

  const setInfosData = useDebouncedCallback((value) => {
    setDataInstalacao(value)
  }, 300)


  const [fields, setFields] = useState([]);

  const handleAddField = (event) => {
    event.preventDefault();
    setFields([...fields, '']);
  };

  const handleFieldChange = (index, value) => {
    const updatedFields = [...fields];
    updatedFields[index] = value;
    setFields(updatedFields);
    setInfosData(updatedFields)
  };
  
  const handleRemoveField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const gerarContratoMutation = useMutation(cadastrarContratoPj)
  function gerarContratos(data) {
    const item = {
     data: data,
     instalacoes: dataInstalacao 
    }
    gerarContratoMutation.mutate(item, {
      onSuccess: () => {
        showToast({
          type: 'success',
          message: 'Contratos gerados com sucesso'
        })
        closeModalContrato()
        reset({}) 
        setTimeout(function(){
          window.location.reload();
     }, 2000); 
      }
    })
  }
  const renderStepOne = () => (
    <Box style={{width:"100%"}} paddingType="square" paddingSize="sm">
        <DadosContrato
          queryDistribuidoras={distribuidoras}
          queryCooperativas={cooperativas}
          register={register}
          setValue={setValue}
          errors={errors}
        />
        <DadosCobranca register={register} errors={errors} />
        <Box
          paddingType="squish"
          paddingSize="sm"
          style={{ display: 'flex', borderTop: 'none' }}
         >
      <Button  className="ml-auto" type="button" onClick={handleNext}>
        Próximo
      </Button>
      </Box>
  </Box>
  );

  const renderStepTwo = () => (
    <Box style={{width:"100%"}} paddingType="square" paddingSize="sm">
      <Box style={{width:"100%"}} >
        <DadosUnidade register={register} errors={errors} />
        <Row  style={{paddingRight: "15px", paddingLeft:"15px"}} className="row-integracao">
          <Col xs={12} md={6} className="mb-sm">
            <Input
              label="Código do consumidor"
              type="text"
              {...register('codigo_do_consumidor')}
            />
          </Col>          
          <Col xs={12} md={6} className="mb-sm">
            <Input label="Numero de instalação" type="text"   value={fields[0] || ''}
              onChange={(e) => handleFieldChange(0, e.target.value)}/>
          </Col>
        </Row>
        {fields.slice(1).map((field, index) => (
        <div  style={{paddingRight: "15px", display: 'flex',  paddingLeft:"15px", paddingBottom:"20px"}}  key={index}>
          <Input style={{flex: "60%", paddingRight:"5px"}} label="Numero de instalação" type="text"
              value={field}
              onChange={(e) => handleFieldChange(index + 1, e.target.value)}
            />
             <Button  onClick={() => handleRemoveField(index)}>
             Remover
            </Button> 
          </div>
        ))}
      </Box>   
      <Box  paddingType="squish" paddingSize="sm"  style={{ display: 'flex', borderTop: 'none' }}>   
        <Button  className="mr-auto"  color="primary" appearance="ghost" onClick={handlePrevious}>
          Anterior
        </Button>
        <Button  className="mr-sm"  onClick={handleAddField}>
          Adicionar instalação
        </Button> 
        <Button className="btn btn-primary" type="submit">
          Salvar
        </Button>
      </Box>
    </Box>
  );

  const renderStepIndicator = () => (
    <div className="step-indicator">
       <div className={`step ${step === 1 ? 'active' : ''}`}>1</div>
       <span className="step-description">Dados do cliente</span>
      <div className="line"></div>
      <div className={`step ${step === 2 ? 'active' : ''}`}>2</div>
      <span className="step-description">Dados do contrato</span>
    </div>
  );

  return (
    <Box paddingSize="none" className="mb-md">
      <Button onClick={openModalContrato} className="mr-xxxs">
        Novo contrato
      </Button>
      <Modal  isOpen={isOpen}  onRequestClose={closeModalContrato}   width={800} label="Testando modal" >
      <Box paddingType="square" border="xxs" borderColor="neutral100" style={{ borderBottom: 'none' }}>
        <Title size="body01" weight="bold" color="neutral800">
          Novo Contrato PJ
        </Title>
      </Box>
      {renderStepIndicator()}
      <form onSubmit={handleSubmit(gerarContratos)}>
          {step === 1 && renderStepOne()}
          {step === 2 && renderStepTwo()}
        </form>
      </Modal>
    </Box>
  )
}

export default View
