import { Title } from '@superlogica/design-system'
import Header from 'components/layouts/Header'
import Sidebar from 'components/layouts/Sidebar'
import { BoxContainer } from 'styles/global-style'

import CadastrarAdministradoras from './CadastrarAdministradoras/Container'
import ListarAdministradoras from './ListarAdministradoras/Container'

const AllAdministradoras = () => {
  return (
    <>
      <Sidebar />
      <Header />
      <BoxContainer paddingSize="lg">
        <Title color="neutral800" size="display03" className="mb-sm">
          Agências
        </Title>
        <CadastrarAdministradoras />
        <ListarAdministradoras />
      </BoxContainer>
    </>
  )
}

export default AllAdministradoras
