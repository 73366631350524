import { useKeycloak } from '@react-keycloak/web'
import { Dropdown, Button } from '@superlogica/design-system'
import { SingleNeutral } from '@superlogica/design-system/Icons/superlogica'
import { BoxUser, BoxIcon } from 'styles/global-style'

const View = () => {
  const { keycloak } = useKeycloak()

  const logout = () => keycloak.logout()
  return (
    <>
      <BoxUser bgColor="neutral100" paddingType="squish">
        <BoxIcon paddingSize="xxs" bgColor="neutral500" paddingType="squish">
          <SingleNeutral width="16" height="16" fill="neutral0" className="icon-user" />
        </BoxIcon>
        <Dropdown>
          <Dropdown.Button>
            <Button fluid color="neutral500" appearance="ghost">
              Username
            </Button>
          </Dropdown.Button>
          <Dropdown.List direction="bottom">
            <Dropdown.Item>
              <a href="#logout" onClick={logout}>
                Sair
              </a>
            </Dropdown.Item>
          </Dropdown.List>
        </Dropdown>
      </BoxUser>
    </>
  )
}

export default View
