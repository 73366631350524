import React from 'react'

import { Box, Table, Text } from '@superlogica/design-system'
import { BoxTabTable, BoxTitleTable } from 'styles/global-style'
const View = ({ tableDataContrato, columns }) => {
  return (
    <>
      <BoxTabTable
        border
        borderColor="neutral300"
        bgColor="neutral0"
        paddingSize="xs"
        radius="md"
        className="flex align-center"
      >
        <BoxTitleTable paddingSize="none">
          <Text className="mr-xxxs">Contratos</Text>
          <Text color="neutral400" size="body02">
            {`${tableDataContrato.length} itens`}
          </Text>
        </BoxTitleTable>
      </BoxTabTable>
      <Box border borderColor="neutral300" bgColor="neutral0" paddingSize="none" radius="xs">
        <Table
          data={tableDataContrato}
          columns={columns}
          tableName="contrato-lista"
          emptyText="Não há contratos aqui!"
        />
      </Box>
    </>
  )
}

export default View
