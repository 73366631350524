import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { Text } from '@superlogica/design-system'
import { SendEmail1 } from '@superlogica/design-system-icons/web/erp'
import { Search } from '@superlogica/design-system/Icons/superlogica'
import { format } from 'date-fns'
import StatusContrato from 'pages/Contratos/components/StatusContrato'
import { getAllAdministradoras } from 'services/administradoras/administradoras.js'
import { getStatus } from 'services/contratos/contrato/atualizarStatus'
import { getContratosPj } from 'services/contratos/getContratos'
import { useDebouncedCallback } from 'use-debounce/lib'
import obfuscate from 'utils/obfuscate'

import View from './View'
const Container = (vari) => {
  const [searchContrato, setSearchContrato] = useState()
  const [searchContrato3, setSearchContrato3] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [sort, setSort] = useState({ direction: 'none', accessor: '' })
  const [allchecked, setAllChecked] = useState([]);

  function handleChange(e) {
    if (e.target.checked) {
      setAllChecked(current => [...current, e.target.value]);
      } else {
        setAllChecked(current => current.filter((item) => item !== e.target.value) );
  }
}

  let statusId = vari['vari']
  const {
    data = [],
    isError,
    isLoading,
    refetch
  } = useQuery(
    ['contratos', searchContrato, statusId, searchContrato3, currentPage, sort],
    () => getContratosPj(searchContrato, statusId, searchContrato3, currentPage, sort)
  )

  const contratos = data?.data ?? []
  const itemsPerPage = data?.itensPorPagina
  const totalItems = data?.totalItens

  const { data: adms = [] } = useQuery(['adminLista'], () => getAllAdministradoras())
  const { data: status = [] } = useQuery(['editarStatus'], () => getStatus())

  const contratos2 = contratos
  const setSearchContratoValue = useDebouncedCallback((value) => {
    setSearchContrato(value)
  }, 300)

  const setSearchContratoValue3 = useDebouncedCallback((value) => {
    setSearchContrato3(value)
  }, 300)

  function sortTable(column) {
    const sortDirections = {
      none: 'ASC',
      ASC: 'DESC',
      DESC: 'none'
    }

    setSort({ direction: sortDirections[column.sortDirection], accessor: column.id })
  }

  function WhatsAppLink(props) {
    const { phone, message } = props
    const whatsappUrl = `https://wa.me/${phone}?text=${message}`
    return (
      <Link to={{ pathname: whatsappUrl }} target="_blank">
        {props.children}
      </Link>
    )
  }

  const columnsContrato = useMemo(
    () => [
      {
        // Header:() => {
        //   return (
        //     <Link to={``}>
        //       <Search height="16" width="16" />
        //     </Link>
        //   )
        // },
        hideSort: true,
        accessor: 'check',
        align: 'center',
        width: '3%',
        Cell: ({ row }) => {
          const contratoId = contratos2[row.index].uuId
          return (
            <input type = "checkbox" onChange = {handleChange}  value = {contratoId}/>         
          )
        }
      },
      {
        hideSort: true,
        accessor: 'icon',
        align: 'center',
        width: '3%',
        Cell: ({ row }) => {
          const contratoId = contratos2[row.index].uuId
          return (
            <Link to={`single-contrato/${contratoId}`}>
              <Search height="16" width="16" />
            </Link>
          )
        }
      },
      {
        Header: 'Cliente', // texto q será exibido no cabeçalho da tabela
        accessor: 'id_cliente.nome_completo', // id das células dessa coluna
        align: 'left', //alinha o texto dentro da célula da tabela
        sortByOnClick: (column) => sortTable(column),
        sortDirection: sort.accessor === 'id_cliente.nome_completo' ? sort.direction : 'none'
      },
      {
        Header: 'CPF',
        accessor: 'id_cliente.cpf',
        align: 'left',
        Cell: ({ row }) => {
          return row.original.id_cliente?.cpf
            ? obfuscate.cpf(row.original.id_cliente.cpf)
            : '000'
        },
        sortByOnClick: (column) => sortTable(column),
        sortDirection: sort.accessor === 'id_cliente.cpf' ? sort.direction : 'none'
      },
      {
        Header: 'Condominio',
        accessor: 'id_condominio.nome',
        align: 'left',
        sortByOnClick: (column) => sortTable(column),
        sortDirection: sort.accessor === 'id_condominio.nome' ? sort.direction : 'none'
      },
      {
        Header: 'Licença',
        accessor: 'id_condominio.id_administradora.licenca',
        align: 'left'
      },
      {
        Header: 'Data de criação ',
        accessor: 'data_assinatura_contrato',
        align: 'left',
        Cell: ({ row }) => {
          let date = new Date(contratos2[row.index]?.data_assinatura_contrato)
          let dataFormatada = format(date, 'dd/MM/yyyy hh:mm:ss')
          return <Text>{dataFormatada}</Text>
        },
        sortByOnClick: (column) => sortTable(column),
        sortDirection: sort.accessor === 'data_assinatura_contrato' ? sort.direction : 'none'
      },
      {
        Header: 'Status',
        accessor: 'id_contratoStatus.nome',
        align: 'left',
        Cell: ({ row }) => <StatusContrato contrato={row.original} />
      },
      {
        hideSort: true,
        accessor: 'whatsapp',
        align: 'center',
        width: '3%',
        Cell: ({ row }) => {
          const nome = row.original.id_cliente?.nome_completo
          const telefone = row.original.id_cliente?.telefone
          const contaEnergia = row.original.ultima_conta_concessionaria
          let mensagem = `Ol%C3%A1%20${nome},%20tudo%20bem?`
          if (!contaEnergia) {
            mensagem = `${mensagem}%0APara%20que%20possamos%20finalizar%20sua%20ades%C3%A3o%20e%20garantir%20que%20voc%C3%AA%20comece%20a%20economizar%20dinheiro,%20precisamos%20que%20voc%C3%AA%20nos%20envie%20uma%20c%C3%B3pia%20da%20sua%20conta%20de%20energia%20mais%20recente.%20N%C3%A3o%20perca%20a%20oportunidade%20de%20ter%20uma%20economia%20significativa%20na%20sua%20conta%20de%20luz!%20Por%20favor,%20envie-nos%20a%20sua%20conta%20assim%20que%20poss%C3%ADvel.%20Se%20tiver%20alguma%20d%C3%BAvida,%20n%C3%A3o%20hesite%20em%20entrar%20em%20contato.%20Obrigado!
          `
          }

          return telefone ? (
            <WhatsAppLink phone={`55${telefone}`} message={mensagem}>
              <SendEmail1 height="16" width="16" />
            </WhatsAppLink>
          ) : (
            ''
          )
        }
      }
    ],
    [contratos2, sort]
  )

  const tableContrato = useMemo(() => contratos2, [contratos2])

  return (
    <View
      title="Display Lista Contratos"
      refetch={refetch}
      isError={isError}
      isLoading={isLoading}
      setSearchContratoValue={setSearchContratoValue}
      setSearchContratoValue3={setSearchContratoValue3}
      columns={columnsContrato}
      tableData={tableContrato}
      adms={adms}
      status={status}
      manualSort={true}
      totalItems={totalItems}
      itemsPerPage={itemsPerPage}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      allchecked={allchecked}
    />
  )
}
export default Container
