import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import common_en from './translations/en/common.json'
import common_pt from './translations/pt/common.json'

const resources = {
  'pt-BR': { common: common_pt },
  en: { common: common_en }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'pt-BR',
    interpolation: {
      escapeValue: false
    }
  })

export const lang = [
  {
    value: 'pt-BR',
    name: 'Português'
  },
  {
    value: 'en',
    name: 'Inglês'
  }
]

export default i18n
