import View from './View'

const Container = ({ textData, closeModalExcluir }) => {

  return (
    <View title="Excluir Cliente" textData={textData} closeModalExcluir={closeModalExcluir} />
  )
}

export default Container
